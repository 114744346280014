import React, { useState } from 'react'
import MessageModal from '../MessageModal/MessageModal'

const Activity = () => {
    const [showMessageModal, setShowMessageModal] = useState(false)
    const [reload, setReload] = useState(false)

    const handleShowMessageModal = () => setShowMessageModal(true)
    const handleCloseCreateModal = () => setShowMessageModal(false)
    const handleReload = () => setReload(!reload)


    return (
        <div>
            {showMessageModal ? <MessageModal handleReload={handleReload} handleCloseCreateModal={handleCloseCreateModal} project={null} /> : null}
            <div className="card-header p-0 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                <div className="d-flex py-2 project-tab flex-wrap w-sm-100">
                    <button onClick={handleShowMessageModal} type="button" className="btn btn-dark w-sm-100">
                        <i className="icofont-plus-circle me-2 fs-6" />Send Message
                    </button>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-md-12 text-center">
                    <img src='https://d3ki9tyy5l5ruj.cloudfront.net/obj/99be55921651dcc82a69e3502803bfb19f95217f/people_celebrating.svg' height={250} />
                    <h3>Hooray! You're up-to-date with your team's work.</h3>
                    <p>Check back later for updates on the work you're a collaborator on.</p>
                </div>
            </div>
        </div>
    )
}

export default Activity