export const objectToFormData = (obj, filesName) => {
    const formData = new FormData();
    Object.keys(obj).forEach(key => {
        if (key === filesName) {
            obj[key].forEach(file => {
                formData.append(`${filesName}`, file);
            });
        } else {
            formData.append(key, obj[key]);
        }
    });
    return formData;
}