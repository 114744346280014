import AppRoutes from './routes/appRoutes'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import "./app.css"
import { Toaster } from 'react-hot-toast';

function App() {
  return <>
    <div id="mytask-layout" className="theme-indigo">
      <Toaster
        position="bottom-center"
        reverseOrder={false}
      />
      <ToastContainer />
      <AppRoutes />
    </div>
  </>

}

export default App;
