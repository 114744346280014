import { useState, useEffect } from "react"
import CreateProject from "../CreateProject/CreateProject"
import CreateTask from "../CreateTask/CreateTask"


const CreateModal = ({ handleCloseCreateModal, intialCreate = "" }) => {
    const [create, setCreate] = useState(intialCreate)

    return <>

        <div className="modal fade show" style={{ display: "block", zIndex: "1000!important;" }} aria-modal="true">
            <div className="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title  fw-bold" >Create {create}</h5>
                        <button type="button" className="btn-close" onClick={handleCloseCreateModal} />
                    </div>
                    {

                        create === "Project" ? <CreateProject handleCloseCreateModal={handleCloseCreateModal} /> : create === "Task" ? <CreateTask handleCloseCreateModal={handleCloseCreateModal} project={null} taskCreated={false} setTaskCreated={() => { }} />
                            : <>

                                <div className="modal-body">
                                    <div className="members_list">

                                        <div style={{ display: 'flex', justifyContent: 'space-evenly', padding: '50px' }}>
                                            <div onClick={() => { setCreate("Project") }} className="card" style={{ height: '10rem', width: '15rem', cursor: 'pointer', margin: '10px' }}>
                                                <div className="card-body" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <h5 className="card-title" style={{ fontSize: "1.6rem" }}> <i class="icofont-briefcase"></i> Project</h5>

                                                </div>
                                            </div>
                                            <div onClick={() => { setCreate("Task") }} className="card" style={{ height: '10rem', width: '15rem', cursor: 'pointer', margin: '10px' }}>
                                                <div className="card-body" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <h5 className="card-title" style={{ fontSize: "1.6rem" }}><i class="icofont-check-circled"></i> Task</h5>

                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </>
                    }
                </div>
            </div>
        </div>

    </>
}
export default CreateModal