import { useEffect, useState } from "react";
import SVG from "../../../assets/images/login-img.svg"
import { getEmployeeSignupInvitationAPI, employeeAccountSignupAPI } from "../../../services/apiService"
import { ErrorAlert, SuccessAlert, LoadingAlert } from "../../../utils/alerts"
import { RotatingLines } from 'react-loader-spinner'


const Signup = () => {
    const search = window.location.search ? window.location.search : "?month=''"
    const invitationCode = search.split("=")[1];
    const [loading, setLoading] = useState(false)
    const [invitationData, setInvitationData] = useState({})
    const [activeSection, setActiveSection] = useState("nameInputSection")
    const [username, setUsername] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [mobileNumber, setMobileNumber] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [confirmEmail, setConfirmEmail] = useState("")
    const [errors, setErrors] = useState({})

    console.log(invitationCode)
    useEffect(() => {
        if (invitationCode) {
            getEmployeeSignupInvitation()
        }
    }, [])
    const getEmployeeSignupInvitation = () => {

        setLoading(true)
        getEmployeeSignupInvitationAPI(invitationCode).then(res => {
            console.log(res.data.data)
            let data = res.data.data
            setInvitationData(data)
            if (data.is_already_have_an_account) {
                // push to login page
                ErrorAlert("Account already created!")
            } else {
                if (data?.employee) {
                    setFirstName(data.employee.first_name)
                    setLastName(data.employee.last_name)
                    setMobileNumber(data.employee.mobile_number)
                }
                setEmail(data.invitation.email)
            }
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                // The request was made and the server responded with a status code that falls out of the range of 2xx
                ErrorAlert(error.response.data.message);
                console.error(error.response.status);
                console.error(error.response.headers);
                if (error.response.status === 401) {
                    ErrorAlert("Please login first!")
                }
            } else if (error.request) {
                // The request was made but no response was received
                ErrorAlert("No response recieved from server.");
            } else if (error.message === "Network Error") {
                // Handle network error here
                ErrorAlert("Network Error: Please check your internet connection");
            } else {
                // Something happened in setting up the request that triggered an Error
                ErrorAlert(error.message);
            }
        })
    }

    const createEmployeeAccount = () => {

        const reqData = {
            "username": username,
            "first_name": firstName,
            "last_name": lastName,
            "email": email,
            "mobile_number": mobileNumber,
            "password": password,
            "confirm_password": confirmPassword,
        }
        if (password.trim() !== confirmPassword.trim()) {
            ErrorAlert("Confirm password not matched.")
            return false
        }
        console.log(reqData)
        setLoading(true)
        employeeAccountSignupAPI(reqData).then(res => {
            SuccessAlert(res.data.message)
            setLoading(false)
            setConfirmEmail(true)
        }).catch(error => {
            if (error.response) {
                // The request was made and the server responded with a status code that falls out of the range of 2xx
                ErrorAlert(error.response.data.message);
                console.error(error.response.status);
                console.error(error.response.headers);
                if (error.response.status === 401) {
                    ErrorAlert("Please login first!")
                }
            } else if (error.request) {
                // The request was made but no response was received
                ErrorAlert("No response recieved from server.");
            } else if (error.message === "Network Error") {
                // Handle network error here
                ErrorAlert("Network Error: Please check your internet connection");
            } else {
                // Something happened in setting up the request that triggered an Error
                ErrorAlert(error.message);
            }
            setLoading(false)
        })
    }

    const goToNextSection = () => {

        if (activeSection === "nameInputSection") {
            if (!username) {
                setErrors({ ...errors, username: { error: "*Please fill the username" } })
                return false
            } else if (username.length < 3) {
                setErrors({ ...errors, username: { error: "*Username must be equal or greater than 3 characters" } })
                return false
            } else {
                let allErrors = errors
                delete allErrors.username
                setErrors(allErrors)
            }

            if (!firstName) {
                setErrors({ ...errors, firstName: { error: "*Please fill the first name" } })
                return false
            } else if (firstName.length <= 2) {
                setErrors({ ...errors, firstName: { error: "*First name must be greater than 2 characters" } })
                return false
            } else {
                let allErrors = errors
                delete allErrors.firstName
                setErrors(allErrors)
            }


            if (!lastName) {
                setErrors({ ...errors, lastName: { error: "*Please fill the last name" } })
                return false
            } else if (lastName.length <= 2) {
                setErrors({ ...errors, lastName: { error: "*Last name must be greater than 2 characters" } })
                return false
            } else {
                let allErrors = errors
                delete allErrors.lastName
                setErrors(allErrors)
            }

            setActiveSection("contactInputSection")


        } else if (activeSection === "contactInputSection") {
            let regex = /^(\+\d{1,3}[- ]?)?\d{10}$/
            if (!mobileNumber) {
                setErrors({ ...errors, mobileNumber: { error: "*Please fill the mobile number" } })
                return false
            } else if (!regex.test(mobileNumber)) {
                setErrors({ ...errors, mobileNumber: { error: "*Invalid phone number. Please enter a valid number" } })
                return false
            } else {
                let allErrors = errors
                delete allErrors.mobileNumber
                setErrors(allErrors)
            }
            setActiveSection("passwordInputSection")
        } else if (activeSection === "passwordInputSection") {
            let regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
            if (!password) {
                setErrors({ ...errors, password: { error: "*Please fill the password" } })
                return false
            } else if (!regex.test(password)) {
                setErrors({ ...errors, password: { error: "*Password must contains minimum eight characters, at least one letter , one number and one special character" } })
                return false
            } else {
                let allErrors = errors
                delete allErrors.password
                setErrors(allErrors)
            }

            if (password !== confirmPassword) {
                setErrors({ ...errors, confirmPassword: { error: "*Confirm password not matched" } })
                return false
            }
            else {
                let allErrors = errors
                delete allErrors.confirmPassword
                setErrors(allErrors)
            }

            createEmployeeAccount()
        }
    }

    return <>
        {/* main body area */}

        <div className="main p-2 py-3">
            {/* Body: Body */}
            <div className="body d-flex p-0">
                <div className="container-xxl">
                    <div className="row g-0" >
                        <div className="col-lg-6 d-none d-lg-flex justify-content-center align-items-center rounded-lg auth-h100">
                            <div style={{ maxWidth: '25rem' }}>
                                <div className="text-center mb-5">
                                    <svg width="4rem" fill="currentColor" className="bi bi-clipboard-check" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                    </svg>
                                </div>
                                <div className="mb-5">
                                    <h2 className="color-900 text-center">Let's Simplified Project Management.</h2>
                                </div>
                                {/* Image block */}
                                <div className>
                                    <img src={SVG} alt="login-img" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 d-flex justify-content-center align-items-center border-0 rounded-lg auth-h100">
                            <div className="w-100 card border-0 bg-dark text-light" style={{ maxWidth: '32rem' }}>
                                {/* Form */}
                                {
                                    !loading ? confirmEmail ? <>
                                        <form className="row g-1 p-3 p-md-4">

                                            <div className="col-12 text-center mb-5  mt-5">

                                                <i className="icofont-send-mail" style={{ fontSize: "60px" }}></i>
                                                <p>An email send to your provided email address.<br /> Please check your inbox for activate your account. If you not found email in inbox please check your spam folder.</p>

                                            </div>
                                        </form>

                                    </> : <>
                                        <form className="row g-1 p-3 p-md-4" >

                                            <div className="col-12 text-center mb-1 mb-lg-2">
                                                <h1>Employee Signup</h1>
                                                <span>Keep track of your projects and tasks with ease.</span>
                                            </div>

                                            {
                                                activeSection === "nameInputSection" ?
                                                    <>
                                                        {/* nameInputSection */}
                                                        <div className="col-12">
                                                            <div className="mb-2">
                                                                <label className="form-label">Username</label>
                                                                <input type="text" className="form-control form-control-lg" placeholder="johndoe" value={username} onChange={(e) => setUsername(e.target.value)} required />
                                                                {errors?.username ? <div className="text-secondary">{errors.username.error}</div> : null}
                                                            </div>
                                                        </div>

                                                        <div className="col-6">
                                                            <div className="mb-2">
                                                                <label className="form-label">Full name</label>
                                                                <input type="text" className="form-control form-control-lg" placeholder="John" disabled={invitationData?.employee?.first_name} value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
                                                                {errors?.firstName ? <div className="text-secondary">{errors.firstName.error}</div> : null}
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="mb-2">
                                                                <label className="form-label">&nbsp;</label>
                                                                <input type="text" className="form-control form-control-lg" placeholder="Doe" disabled={invitationData?.employee?.last_name} value={lastName} onChange={(e) => setLastName(e.target.value)} required />
                                                                {errors?.lastName ? <div className="text-secondary">{errors.lastName.error}</div> : null}
                                                            </div>
                                                        </div>
                                                        <div className="col-12 text-center mt-4">
                                                            <button className="btn btn-lg btn-block btn-light lift text-uppercase" type="button" onClick={goToNextSection}>Next</button>
                                                        </div>
                                                        {/* / nameInputSection */}
                                                    </>
                                                    : activeSection === "contactInputSection" ? <>
                                                        {/* contactInputSection */}
                                                        <div className="col-12">
                                                            <div className="mb-2">
                                                                <label className="form-label">Email address</label>
                                                                <input type="email" className="form-control form-control-lg" placeholder="name@example.com" disabled={true} value={email} onChange={(e) => setEmail(e.target.value)} required />
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="mb-2">
                                                                <label className="form-label">Mobile Number</label>
                                                                <input type="text" className="form-control form-control-lg" placeholder="+923000000000" disabled={invitationData?.employee?.mobile_number} value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} required />
                                                                {errors?.mobileNumber ? <div className="text-secondary">{errors.mobileNumber.error}</div> : null}
                                                            </div>
                                                        </div>
                                                        <div className="col-12 text-center mt-4">
                                                            <button className="btn btn-lg btn-block btn-light lift text-uppercase" type="button" onClick={goToNextSection}>Next</button>
                                                        </div>
                                                        {/* / contactInputSection */}
                                                    </>
                                                        : activeSection === "passwordInputSection" ? <>
                                                            {/* passwordInputSection */}
                                                            <div className="col-12">
                                                                <div className="mb-2">
                                                                    <label className="form-label">Password</label>
                                                                    <input type="password" className="form-control form-control-lg" placeholder="8+ characters required" onChange={(e) => setPassword(e.target.value)} required autoComplete="new-password" name="password" value={password} />
                                                                    {errors?.password ? <div className="text-secondary">{errors.password.error}</div> : null}
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="mb-2">
                                                                    <label className="form-label">Confirm password</label>
                                                                    <input type="password" className="form-control form-control-lg" placeholder="8+ characters required" onChange={(e) => setConfirmPassword(e.target.value)} required autoComplete="new-password" name="confirmPassword" value={confirmPassword} />
                                                                    {errors?.confirmPassword ? <div className="text-secondary">{errors.confirmPassword.error}</div> : null}
                                                                </div>
                                                            </div>
                                                            <div className="col-12 text-center mt-4">
                                                                <button className="btn btn-lg btn-block btn-light lift text-uppercase" type="button" onClick={goToNextSection}>SIGN UP</button>
                                                            </div>
                                                            {/* / passwordInputSection */}
                                                        </> : <></>



                                            }





                                            {/* passwordInputSection */}
                                            {/* / passwordInputSection */}



                                        </form>
                                    </>
                                        : <>
                                            <form className="row g-1 p-3 p-md-4">

                                                <div className="col-12 text-center mb-5  mt-5">

                                                    <RotatingLines
                                                        strokeColor="white"
                                                        strokeWidth="5"
                                                        animationDuration="0.75"
                                                        width="96"
                                                        visible={true}
                                                    />

                                                </div>
                                            </form>

                                        </>
                                }


                                {/* End Form */}
                            </div>
                        </div>
                    </div> {/* End Row */}
                </div>
            </div>
        </div>



    </>
}
export default Signup