import { useState, useEffect } from "react"
import { createProjectTaskAPI, getAllProjectsAPI } from "../../services/apiService"
import { ErrorAlert, SuccessAlert } from "../../utils/alerts"
import { RotatingLines } from 'react-loader-spinner'
import { useFormik } from "formik"
import { useSelector } from "react-redux"
import logout from "../../utils/logout"


const CreateTask = ({ handleCloseCreateModal, project, setTaskCreated, taskCreated, startDate }) => {


    const profileData = useSelector((state) => state.user)?.user
    
    const [allProjects, setAllProjects] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!project) {
            getAllProjects()
        }
    }, [project])

    const getAllProjects = () => {
        setLoading(true)
        getAllProjectsAPI().then(res => {
            setAllProjects(res.data.data)
            setLoading(false)
            console.log(res.data.data)
        }).catch(error => {
            if (error.response) {
                let err = `Unable to fetch projects. error(${error.response.data.message})`
                ErrorAlert(err)
                if (error.response.status === 401) {
                    logout();
                }
            } else {
                ErrorAlert(error.message)
            }
        })
    }

    const validate = values => {
        const errors = {};

        let taskProject = JSON.parse(values.project)
        console.log(taskProject)
        if (!taskProject?.title) {
            errors.project = '*Project required';
        }

        if (!values.title) {
            errors.title = '*Task title required';
        } else if (values.title.length < 5) {
            errors.title = '*Task title must be greater than 5 characters'
        }

        if (!values.priority) {
            errors.priority = '*Task priority required';
        }

        if (!values.start_date) {
            errors.start_date = '*Task start date required';
        }

        const startDate = new Date(values.start_date);
        const endDate = new Date(values.end_date);

        if (endDate < startDate) {
            errors.end_date = "End date must be equal to or greater than start date";
        }

        if (!values.assign_to) {
            errors.assign_to = '*Assigne required';
        }

        return errors
    }

    const taskForm = useFormik({
        initialValues: {
            project: project ? JSON.stringify(project) : '{}',
            title: '',
            description: '',
            priority: '',
            start_date: startDate,
            end_date: '',
            assign_to: ''
        },
        validate,
        onSubmit: values => {
            let project = JSON.parse(values.project)
            let start_date = values.start_date
            let end_date = values.end_date
            let reqData = {
                ...values,
                project: project.id,
                start_date: changeDateToIsoFormat(start_date),
                end_date: end_date ? changeDateToIsoFormat(end_date) : null
            }
            console.log(reqData)
            createProjectTask(reqData)
        },
    })

    const createProjectTask = (data) => {
        createProjectTaskAPI(data).then(res => {
            SuccessAlert(res.data.message)
            setTaskCreated(!taskCreated)
            handleCloseCreateModal()
        }).catch(error => {
            if (error.response) {
                ErrorAlert(error.response.data.message)
                if (error.response.status === 401) {
                    logout();
                }
            } else {
                ErrorAlert(error.message)
            }
        })
    }

    const changeDateToIsoFormat = (date) => {
        let isoDate = new Date(date).toISOString().split('T')[0]
        isoDate = new Date(isoDate + 'Z').toISOString()
        console.log(isoDate)
        return isoDate
    }

    useEffect(() => {
        JSON.parse(taskForm.values.project)?.added_employees && 
        JSON.parse(taskForm.values.project)?.added_employees.forEach(emp => {
            if (profileData.role === "employee" && profileData.id === emp.user__id) {
                taskForm.setFieldValue('assign_to', emp.id);
                console.log('employee id set=======', emp.id);
            }
        });
    }, []); 
    
      
    return <>
        <div className="modal-body">

            {
                loading ? <>
                    <div className="col-12 text-center mb-5  mt-5">

                        <RotatingLines
                            strokeColor="#484C7F"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="96"
                            visible={true}
                        />

                    </div>
                </> : null
            }
            {
                !loading && !project ? <div className="mb-3">
                    <label className="form-label">*Project</label>
                    <select
                        className="form-select text-capitalize"
                        name="project"
                        onChange={taskForm.handleChange}
                    // onFocus={() => taskForm.setFieldValue("assign_to", '')}

                    >
                        <option selected value="{}">Select Project</option>
                        {
                            allProjects.map(p => {
                                return <option className="text-capitalize" value={JSON.stringify(p)}>{p.title}</option>
                            })
                        }

                    </select>
                    {taskForm.errors.project ? <div className="text-secondary">{taskForm.errors.project}</div> : null}
                </div> : null
            }
            {
                !loading ? <>
                    <div className="mb-3">
                        <label htmlFor="title" className="form-label">*Task Title</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Explain what the Task Title"
                            name="title"
                            onChange={taskForm.handleChange}
                            value={taskForm.values.title}
                        />
                        {taskForm.errors.title ? <div className="text-secondary">{taskForm.errors.title}</div> : null}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="description" className="form-label">Description</label>
                        <textarea
                            className="form-control"
                            rows={3}
                            placeholder="Add any extra details about the task"
                            name="description"
                            onChange={taskForm.handleChange}
                            value={taskForm.values.description}
                        />
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Task Priority</label>
                        <select
                            className="form-select text-capitalize"
                            name="priority"
                            onChange={taskForm.handleChange}

                        >
                            <option selected value="">Select Priority</option>
                            <option value="low">Low</option>
                            <option value="medium">Medium</option>
                            <option value="high">High</option>
                        </select>
                        {taskForm.errors.priority ? <div className="text-secondary">{taskForm.errors.priority}</div> : null}
                    </div>

                    <div className="row g-3 mb-3">
                        <div className="col">
                            <label htmlFor="datepickerded" className="form-label">Task Start Date</label>
                            <input
                                type="date"
                                className="form-control"
                                id="datepickerded"
                                name="start_date"
                                onChange={taskForm.handleChange}
                                value={taskForm.values.start_date}
                            />
                            {taskForm.errors.start_date ? <div className="text-secondary">{taskForm.errors.start_date}</div> : null}
                        </div>
                        <div className="col">
                            <label htmlFor="datepickerdedone" className="form-label">Task End Date</label>
                            <input
                                type="date"
                                className="form-control"
                                name="end_date"
                                min={taskForm.values.start_date}
                                onChange={taskForm.handleChange}
                                value={taskForm.values.end_date}
                            />
                            {taskForm.errors.end_date ? <div className="text-secondary">{taskForm.errors.end_date}</div> : null}
                        </div>
                    </div>
                    {
                        profileData.role!=="employee" ? <div className="mb-3">
                        <label className="form-label text-capitalize">Assign To</label>
                        <select
                            className="form-select text-capitalize"
                            name="assign_to"
                            onChange={taskForm.handleChange}
                        >
                            <option className="text-capitalize" selected value="">Select Assigne</option>
                            {
                                JSON.parse(taskForm.values.project)?.added_employees ? JSON.parse(taskForm.values.project)?.added_employees.map(emp => {
                                    return <option className="text-capitalize" value={emp.id}>{emp.first_name}  {emp.last_name}</option>
                                }) : null
                            }

                        </select>
                        {taskForm.errors.assign_to ? <div className="text-secondary">{taskForm.errors.assign_to}</div> : null}
                    </div>
                    : null
                    }
                    
                </> : null
            }

        </div>
        {
            !loading ? <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={handleCloseCreateModal}>Cancel</button>
                <button type="button" className="btn btn-primary" onClick={taskForm.handleSubmit}>Create</button>
            </div> : null
        }
    </>
}
export default CreateTask