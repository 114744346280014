import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useState, useEffect } from "react"
import { createTaskDocumentationAPI, getEmployeeAllProjectsAPI, getEmployeeAllTasksAPI } from "../../services/apiService"
import { ErrorAlert, SuccessAlert } from "../../utils/alerts"
import { RotatingLines } from 'react-loader-spinner'
import { useFormik } from "formik"
import { Editor } from 'react-draft-wysiwyg';
import { EditorState , convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import logout from "../../utils/logout";

const CreateDocumentationModal = ({ toggleCreateModal, reload, setReload}) => {

    const [documentation, setDocumentation] = useState(EditorState.createEmpty());

    const onEditorStateChange = (newEditorState) => {
        setDocumentation(newEditorState);
    };

    const [allProjects, setAllProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getAllTasks()
    }, [])

    const getAllTasks = () => {
        setLoading(true)
        getEmployeeAllProjectsAPI().then(res => {
            setAllProjects(res.data.data);
            setLoading(false)
            console.log(res.data.data, 'here will be all tasks')
        }).catch(error => {
            if (error.response) {
                let err = `Unable to fetch Projects. error(${error.response.data.message})`
                ErrorAlert(err)
                if (error.response.status === 401) {
                    logout();
                }
            } else {
                ErrorAlert(error.message)
            }
        })
    }

    const validate = values => {
        const errors = {};

        if (!values.task) {
            errors.task = '*Task required';
        } 

        if (! draftToHtml(convertToRaw(documentation.getCurrentContent()))) {
            errors.documentation = '*Documentation required';
        }

        return errors
    }

    const docForm = useFormik({
        initialValues: {
            task: '',
        },
        validate,
        onSubmit: values => {
            createTaskDocumentation({...values, documentation: draftToHtml(convertToRaw(documentation.getCurrentContent())) });
        },
    })

    const createTaskDocumentation = (data) => {
        createTaskDocumentationAPI(data).then(res => {
            SuccessAlert("Task documentation created successfully!")
            setReload(!reload);
            toggleCreateModal()
        }).catch(error => {
            if (error.response) {
                ErrorAlert(error.response.data.message)
                if (error.response.status === 401) {
                    logout();
                }
            } else {
                ErrorAlert(error.message)
            }
        })
    }

    

    return (
      <>
        <div
          className="modal fade show"
          style={{ display: 'block', zIndex: '1000!important;' }}
          aria-modal="true"
        >
          <div className="modal-dialog modal-dialog-updated modal-dialog-centered modal-md modal-dialog-scrollable">
            <div className="modal-content" style={{ height: '80vh!important' }}>
              <div className="modal-header">
                <h5 className="modal-title  fw-bold">
                  Create Task Documentation
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={toggleCreateModal}
                />
              </div>
              <div className="modal-body">
                {loading ? (
                  <>
                    <div className="col-12 text-center mb-5  mt-5">
                      <RotatingLines
                        strokeColor="#484C7F"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="96"
                        visible={true}
                      />
                    </div>
                  </>
                ) : null}
                {!loading ? (
                  <div className="mb-3">
                    <label className="form-label">*Project</label>
                    <select
                      className="form-select text-capitalize"
                      name="projects"
                      onChange={(e) => {
                        setSelectedProject(
                          allProjects.find((p) => p.id == e.target.value),
                        );
                      }}
                    >
                      <option value="">Select Project</option>
                      {allProjects.map((p) => {
                        return (
                          <option className="text-capitalize" value={p.id}>
                            {p.title}
                          </option>
                        );
                      })}
                    </select>
                    
                  </div>
                ) : null}
                {!loading ? (
                  <div className="mb-3">
                    <label className="form-label">*Tasks</label>
                    <select
                      className="form-select text-capitalize"
                      name="task"
                      onChange={docForm.handleChange}
                    >
                      <option value="">Select Task</option>
                      {selectedProject?.tasks.map((t) => {
                        return (
                          <option className="text-capitalize" value={t.id}>
                            {t.title}
                          </option>
                        );
                      })}
                    </select>
                    {docForm.errors.task ? (
                      <div className="text-secondary">
                        {docForm.errors.task}
                      </div>
                    ) : null}
                  </div>
                ) : null}
                {!loading ? (
                  <>
                    <div className="mb-3">
                      <label htmlFor="description" className="form-label">
                        *Documentation
                      </label>
                      <Editor
                        editorState={documentation}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={onEditorStateChange}
                      />
                      {docForm.errors.documentation ? (
                        <div className="text-secondary">
                          {docForm.errors.documentation}
                        </div>
                      ) : null}
                    </div>
                  </>
                ) : null}
              </div>
              {!loading ? (
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={toggleCreateModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={docForm.handleSubmit}
                  >
                    Create
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </>
    );
}
export default CreateDocumentationModal;