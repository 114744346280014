import { useEffect, useState } from "react"
import { getCheckInCheckOutsAPI, getProjectEmployeeTasksAPI } from "../../services/apiService"
import { ErrorAlert } from "../../utils/alerts"
import { RotatingLines } from 'react-loader-spinner'
import CheckIn from "./CheckIn"
import logout from "../../utils/logout"


const CheckInTable = ({reload, setReload}) => {
    const PAGE_SIZE = 2

    const [allCheckIns, setAllCheckIns] = useState([])
    const [checkIns, setCheckIns] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [loading, setLoading] = useState(false)
    

    useEffect(() => {
        getCheckInAndCheckOuts()
    }, [reload])

    const getCheckInAndCheckOuts = () => {
        setLoading(true);
        getCheckInCheckOutsAPI().then(res => {
            console.log(res.data.data);
            handleRenderCheckIns(res.data.data);
        }).catch(error => {
            if (error.response) {
                ErrorAlert(error.response.data.message)
                if (error.response.status === 401) {
                    logout();
                }
            } else {
                ErrorAlert(error.message)
            }
        }).finally(()=>{
          setLoading(false);
        })
      }
    

    const handleRenderCheckIns = (checkins) => {
        setAllCheckIns(checkins)
        setCheckIns(checkins.slice(0, PAGE_SIZE))
        setCurrentPage(1)
        setTotalPages(Math.ceil(checkins.length / PAGE_SIZE))
    }


    const handleNextPage = () => {
        if (currentPage !== totalPages) {
            let lastIdx = (currentPage + 1) * PAGE_SIZE
            let startIdx = lastIdx - PAGE_SIZE
            let visibleTasks = allCheckIns.slice(startIdx, lastIdx)
            setCheckIns(visibleTasks)
            setCurrentPage(currentPage + 1)
        }
    }

    const handlePrevPage = () => {
        if (currentPage !== 1) {
            let lastIdx = (currentPage - 1) * PAGE_SIZE
            let startIdx = lastIdx - PAGE_SIZE
            let visibleTasks = allCheckIns.slice(startIdx, lastIdx)
            setCheckIns(visibleTasks)
            setCurrentPage(currentPage - 1)
        }
    }


    return <>

        {
            loading ? (
                <div className="col-12 text-center mb-5  mt-5">

                    <RotatingLines
                        strokeColor="#484C7F"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="96"
                        visible={true}
                    />

                </div>
            ) : null
        }
         {
            !loading && checkIns.length > 0 ? 
                <div className="col-sm-12">
                    <div className="card mb-3">
                        <div className="card-body">
                            <div id="myProjectTable_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <table id="myProjectTable" className="table table-hover align-middle mb-0 nowrap dataTable no-footer dtr-inline" style={{ width: '100%' }} role="grid" aria-describedby="myProjectTable_info">
                                            <thead>
                                                <tr role="row">
                                                    <th className="sorting" tabIndex={0} aria-controls="myProjectTable" rowSpan={1} colSpan={1}  >Date</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="myProjectTable" rowSpan={1} colSpan={1}  >Employee</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="myProjectTable" rowSpan={1} colSpan={1}  >CheckIn Time</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="myProjectTable" rowSpan={1} colSpan={1}  >CheckOut Time</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="myProjectTable" rowSpan={1} colSpan={1} >Total Time Spend</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="myProjectTable" rowSpan={1} colSpan={1}  >CheckIn Location</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="myProjectTable" rowSpan={1} colSpan={1}  >CheckOut Location</th>
                                                   
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    checkIns.map(checkin => {
                                                        return <CheckIn key={checkin.id} checkin={checkin}  />

                                                    })
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="row" style={{ marginTop: "20px" }}>
                                    <div className="col-sm-12 col-md-5">
                                        <div className="dataTables_info" id="myProjectTable_info" role="status" aria-live="polite">Showing 1 to 6 of 6 entries</div>
                                    </div>
                                    <div className="col-sm-12 col-md-7">
                                        <div className="dataTables_paginate paging_simple_numbers" id="myProjectTable_paginate">
                                            <ul className="pagination" style={{ float: "right" }}>
                                                <li className="paginate_button page-item previous " onClick={(e) => { e.preventDefault(); handlePrevPage(); }} >
                                                    <a href="#" className="page-link">Previous</a>
                                                </li>
                                                <li className="paginate_button page-item active">
                                                    <a href="" className="page-link text-light" onClick={(e) => { e.preventDefault(); }}>{currentPage}</a>
                                                </li>
                                                <li className="paginate_button page-item next " onClick={(e) => { e.preventDefault(); handleNextPage(); }}>
                                                    <a href="" className="page-link">Next</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            : null
         }

    </>
}
export default CheckInTable;