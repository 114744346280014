export default function logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('two_factor');
        localStorage.removeItem('user_email');
        localStorage.removeItem('user');
        
        try{
            deleteTokenCookie();
        }catch(err){
            console.log(err);
        }
        window.location.href = '/login'
    }

function deleteCookie(cookieName) {
    document.cookie = cookieName + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
}

function deleteTokenCookie() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].trim();
        if (cookie.startsWith('token=')) {
            deleteCookie('token');
        }
    }
}