
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { ErrorAlert } from '../../../utils/alerts';
import { employeeDashboardStatsAPI } from '../../../services/apiService';
import LatestProjects from './LatestProjects';
import LatestTasks from './LatestTasks';
import moment from "moment";
import documentImage from "../../../assets/images/documents.svg"
import { RotatingLines } from 'react-loader-spinner';
import logout from '../../../utils/logout';

function Dashboard() {

    const [dateTime, setDateTime] = useState(new Date())
    const [greeting, setGreeting] = useState('')
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({});
    const profileData = useSelector((state) => state.user)?.user


    const options = {
        weekday: 'long',
        month: 'long',
        day: 'numeric'
    };

    useEffect(() => {
        getEmployeeDashboardStats();
        const date = new Date()
        setDateTime(date)
        const hour = date.getHours();
        if (hour >= 5 && hour < 12) {
            setGreeting('Good morning')
        } else if (hour >= 12 && hour < 17) {
            setGreeting('Good afternoon')
        } else {
            setGreeting('Good evening')
        }

    }, [])


    const getEmployeeDashboardStats = () => {
        setLoading(true)
        employeeDashboardStatsAPI().then(res => {
            console.log(res.data.data)
            setData(res.data.data)
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            if (error.response) {
                ErrorAlert(error.response.data.message)
                if (error.response.status === 401) {
                    logout();
                }
            } else {
                ErrorAlert(error.message)
            }
        })
    }

    return (
        <div className='container-xxl'>
            <div className='row clearfix g-3'>
                <div className='col-md-12 text-center m-4'>
                    <h5>{dateTime.toLocaleDateString(undefined, options)}</h5>
                    <h2>{greeting}, {profileData.username}</h2>
                </div>
                {
                    ! loading ? <>
                        <div className="col-md-12">

                            <div className="row g-3 mb-3 row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 row-cols-xl-3 row-cols-xxl-3">
                                <div className="col">
                                    <div className="card bg-primary">
                                    <div className="card-body text-white d-flex align-items-center">
                                        <i className="icofont-data fs-3" />
                                        <div className="d-flex flex-column ms-3">
                                        <h6 className="mb-0">Total Projects</h6>
                                        <span className="text-white">{data?.projects?.total_projects}</span>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card bg-primary">
                                    <div className="card-body text-white d-flex align-items-center">
                                        <i className="fs-3 bi bi-journal-check " />
                                        <div className="d-flex flex-column ms-3">
                                        <h6 className="mb-0">Active Projects</h6>
                                        <span className="text-white">{data?.projects?.active_projects}</span>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                               
                                <div className="col">
                                    <div className="card bg-primary">
                                    <div className="card-body text-white d-flex align-items-center">
                                        <i className="icofont-tasks fs-3" />
                                        <div className="d-flex flex-column ms-3">
                                        <h6 className="mb-0">Completed Projects</h6>
                                        <span className="text-white">{data?.projects?.completed_projects}</span>
                                        </div>
                                    </div>
                                    </div>
                                </div>             
                                </div>
                            </div>
                            <LatestProjects projects = {data?.projects?.latest_projects}/>

                            <div className='col-md-12'>
                            <div className="row g-3 mb-3 row-deck">
                                <div className="col-md-12 col-lg-3 col-xl-3 col-xxl-3">
                                    <div className="card ">
                                    <div className="card-body">
                                        <div className="d-flex align-items-center">
                                        <div style={{ backgroundColor: "#ffe28c !important" }} className=" lg  rounded-1 no-thumbnail bg-lightyellow color-defult"><i className="bi bi-journal-check fs-4" /></div>
                                        <div className="flex-fill ms-4">
                                            <div className>Total Task</div>
                                            <h5 className="mb-0 ">{data?.tasks?.total_tasks}</h5>
                                        </div>
                                    
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-3 col-xl-3 col-xxl-3">
                                    <div className="card ">
                                    <div className="card-body">
                                        <div className="d-flex align-items-center">
                                        <div className=" lg  rounded-1 no-thumbnail bg-lightblue color-defult"><i className="bi bi-list-check fs-4" /></div>
                                        <div className="flex-fill ms-4">
                                            <div className>Completed Task</div>
                                            <h5 className="mb-0 ">{data?.tasks?.completed_tasks}</h5>
                                        </div>
                                        
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-3 col-xl-3 col-xxl-3">
                                    <div className="card ">
                                    <div className="card-body">
                                        <div className="d-flex align-items-center">
                                        <div className=" lg  rounded-1 no-thumbnail bg-lightgreen color-defult"><i className="bi bi-clipboard-data fs-4" /></div>
                                        <div className="flex-fill ms-4">
                                            <div className>In-Progress Task</div>
                                            <h5 className="mb-0 ">{data?.tasks?.in_progress_tasks}</h5>
                                        </div>
                                        
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-3 col-xl-3 col-xxl-3">
                                    <div className="card ">
                                    <div className="card-body">
                                        <div className="d-flex align-items-center">
                                        <div className=" lg  rounded-1 no-thumbnail bg-lightgreen color-defult"><i className="bi bi-clipboard-data fs-4" /></div>
                                        <div className="flex-fill ms-4">
                                            <div className>Pending Task</div>
                                            <h5 className="mb-0 ">{data?.tasks?.pending_tasks}</h5>
                                        </div>
                                        
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>

                            </div>
                            <LatestTasks tasks = {data?.tasks?.latest_tasks}/>
                            <div className='col-md-12'>
                                <div className='row'>
                                <div className='col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
                                <div className="row align-item-center row-deck g-3 mb-3">
                                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                                    <div className="card">
                                        <div className="card-header py-3">
                                        <h6 className="mb-0 fw-bold ">Check In & Check Out Time</h6>
                                        </div>
                                        <div className="card-body">
                                        <div className="timesheet-info d-flex align-items-center justify-content-between flex-wrap">
                                            
                                            <div className="intime d-flex align-items-center m-2">
                                            <i className="icofont-finger-print fs-4 color-light-success" />
                                            <span className="fw-bold ms-1">{data?.cehckin_checkout?.today_check_in ? `Check In Time : ${moment(data.cehckin_checkout.today_check_in).format('h:mm:s A')}` : "No check in for today."}</span>
                                            
                                            </div>
                                            
                                        </div>
                                        <div className="timesheet-info d-flex align-items-center justify-content-between flex-wrap">
                                            
                                            <div className="intime d-flex align-items-center m-2">
                                            <i className="icofont-finger-print fs-4 color-light-success" />
                                            <span className="fw-bold ms-1">{data?.cehckin_checkout?.today_check_out ? `Check Out Time : ${moment(data.cehckin_checkout.today_check_out).format('h:mm:s A')}` : "No check out for today."}</span>
                                        
                                            </div>
                                            
                                        </div>
                                        </div>
                                        
                                    </div> 
                                    </div>
                                
                                </div>
                                </div>
                                <div className="col-md-6 col-lg-6 col-xl-6">
                                    <div className="card bg-primary">
                                        <div className="card-body row">
                                        <div className="col">
                                            <span className="avatar lg bg-white rounded-circle text-center d-flex align-items-center justify-content-center"><i className="icofont-file-text fs-5" /></span>
                                            <h1 className="mt-3 mb-0 fw-bold text-white">{data?.documentations?.total_documentations}</h1>
                                            <span className="text-white">Documentations</span>
                                        </div>
                                        <div className="col">
                                            <img style={{ height: "130px"}} className="img-fluid" src={documentImage} alt="documents" />
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            
                            </div>
                    </> : <div className="col-12 text-center mb-5  mt-5">
                            <RotatingLines
                                strokeColor="#484C7F"
                                strokeWidth="5"
                                animationDuration="0.75"
                                width="96"
                                visible={true}
                            />

                        </div>
                }
            </div>
        </div>
    )
}

export default Dashboard