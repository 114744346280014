import { useEffect, useState } from "react"
import { getMessagesAPI, getProjectMessagesAPI } from "../../../services/apiService"
import { ErrorAlert } from "../../../utils/alerts"
import moment from 'moment'
import { API_URL_PREFIX } from "../../../constants/apiUrl"
import logout from "../../../utils/logout"


const InboxMessages = () => {
    const [messages, setMessages] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getMessages()
    }, [])

    const getMessages = () => {
        setLoading(true)
        getMessagesAPI().then(res => {
            console.log(res.data.data)
            setMessages(res.data.data)
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            if (error.response) {
                ErrorAlert(error.response.data.message)
                if (error.response.status === 401) {
                    logout();
                }
            } else {
                ErrorAlert(error.message)
            }
        })
    }


    return <>
        <div className="row"><div className="col-sm-12"><table id="myProjectTable" className="table table-hover align-middle mb-0 nowrap dataTable no-footer dtr-inline" style={{ width: '100%' }} role="grid" aria-describedby="myProjectTable_info">

            <tbody>
                <tr style={{ background: 'rgb(72 76 127)', fontWeight: 500, borderRadius: '6px' }} role="row" >

                    <td style={{ borderRadius: "10px 0px 0px 10px" }} className="text-light">
                        Message Title
                    </td>
                    <td className="text-light">
                        Recipients
                    </td>
                    <td className="text-light">
                        Projects & Portfolios
                    </td>

                    <td style={{ borderRadius: "0px 10px 10px 0px" }} className="text-light">
                        Date
                    </td>

                </tr>
                {
                    messages && messages.map(msg => {
                        return <tr key={msg.id} role="row" className="odd">

                            <td className="capitalize-sentence">
                                {msg.body}
                            </td>
                            <td>
                                <div className="d-flex">
                                    <div className="avatar-list avatar-list-stacked px-3">
                                        {
                                            msg.recipients.length > 0 ? msg.recipients.map(res => {
                                                return <img className="avatar rounded-circle" src={`${API_URL_PREFIX}/media/${res.image}`} alt="" />
                                            }) : "None"
                                        }
                                    </div>
                                </div>
                            </td>
                            <td className="capitalize-sentence">
                                {msg.project}
                            </td>

                            <td >
                                {moment(msg.created_on).format('D MMMM YYYY, hh:mm A')}
                            </td>

                        </tr>
                    })
                }
            </tbody>
        </table></div></div>

    </>
}
export default InboxMessages