
import { useEffect, useState } from "react"
import AddEmployeeToProjectModal from "../../../components/AddEmployeeToProjectModal/AddEmployeeToProjectModal"
import Task from "../Tasks/Tasks"
import { useParams } from "react-router-dom"
import { deleteProjectAPI, getProjectAPI, updateProjectAPI } from "../../../services/apiService"
import { ErrorAlert, SuccessAlert } from "../../../utils/alerts"
import { API_URL_PREFIX, API_WS_PREFIX } from "../../../constants/apiUrl"
import Tasks from "../Tasks/Tasks"
import Messages from "../Messages/Messages"
import Overview from "../Overview/Overview"
import Calendar from "../Calendar/Calendar"
import Board from "../Board/Board"
import toast from 'react-hot-toast';
import DeleteItem from "../../../components/DeleteItem/DeleteItem"
import { useHistory } from 'react-router-dom'
import AssignRoleModal from "../../../components/AssignRoleModal/AssignRoleModal"
import logout from "../../../utils/logout"
const Project = ({setReload}) => {

    let { projectId } = useParams()
    const [project, setProject] = useState({})
    const history = useHistory()
    const [showAddEmpToProjectModal, setShowAddEmpToProjectModal] = useState(false)
    const [selectedEmployee, setSelectedEmployee] = useState({})
    const [showAssignRoleModal, setShowAssignRoleModal] = useState(false)
   
    
    const handleOpenEmpToProjectModal = () => setShowAddEmpToProjectModal(true)
    const handleCloseEmpToProjectModal = () => setShowAddEmpToProjectModal(false)
    const handleOpenAssignRoleModal = () => setShowAssignRoleModal(true)
    const handleCloseAssignRoleModal = () => setShowAssignRoleModal(false)
   

    const [showDeleteItemModal, setShowDeleteItemModal] = useState(false)

    const handleOpenDeleteItemModal = () => setShowDeleteItemModal(true)
    const handleCloseDeleteItemModal = () => setShowDeleteItemModal(false)

    const [currentTab, setCurrentTab] = useState("OverviewTab")


    useEffect(() => {
        getProject()
    }, [projectId, showAddEmpToProjectModal])


    const myPromise = new Promise((resolve) => setTimeout(() => resolve(""), 3000));

    useEffect(() => {

        try{
            const ws = new WebSocket(`${API_WS_PREFIX}/sync_project/${projectId}`)


        try {
            ws.onopen = (msg) => {
                console.log('ws connection created!')
            }

            ws.onmessage = (msg) => {

                toast.promise(
                    myPromise
                    ,
                    {
                        loading: 'Syncing...',
                        success: <b>Sync successfully.</b>,
                        error: <b>Could not sync.</b>,
                    }
                );

                const data = JSON.parse(msg.data)["payload"]
                setProject(data)
                console.log('me called', data)

            }

            ws.onclose = (msg) => {
                console.log('ws connection closed')
            }

        } catch (err) {
            console.log(err)
        }

        }catch{}



    }, [])


    const getProject = () => {
        getProjectAPI(projectId).then(res => {
            setProject(res.data.data)
            console.log(res.data.data)
        }).catch(error => {
            if (error.response) {
                let err = `Unable to fetch projects. error(${error.response.data.message})`
                ErrorAlert(err)
                if (error.response.status === 401) {
                    logout();
                }
            } else {
                ErrorAlert(error.message)
            }
        })
    }

    const updateProject = (data) => {

        updateProjectAPI(project.id, data).then(res => {
            console.log(res.data)
            SuccessAlert(res.data.message)
        }).catch(error => {
            if (error.response) {
                let err = `Unable to update project. error(${error.response.data.message})`
                ErrorAlert(err)
                if (error.response.status === 401) {
                    logout();
                }
            } else {
                ErrorAlert(error.message)
            }
        })
    }

    const deleteProject = () => {

        deleteProjectAPI(project.id).then(res => {
            console.log(res.data)
            SuccessAlert('Project deleted successfully!')
            setReload(Math.random());
            history.push('/admin/dashboard/');
        }).catch(error => {
            if (error.response) {
                let err = `Unable to delete project. error(${error.response.data.message})`
                ErrorAlert(err)
                if (error.response.status === 401) {
                    logout();
                }
            } else {
                ErrorAlert(error.message)
            }
        })
    }

    const openSelectRoleOnAddEmployee = (emp)=>{
        setSelectedEmployee({
            "employee": {
            'email' : emp.employee__email, 
            'first_name' : emp.employee__first_name,
            'last_name' : emp.employee__last_name,
            'id' : emp.employee__id,
            'image' : emp.employee__image,
            },
            "role" : {
                "role" : ""
            }
        });
        setShowAssignRoleModal(true);
    }

    const handleReload = ()=>{
        setReload(Math.random());
    }

    return <>

        <div>
            {showAddEmpToProjectModal ? <AddEmployeeToProjectModal openSelectRoleOnAddEmployee={openSelectRoleOnAddEmployee} handleCloseEmpToProjectModal={handleCloseEmpToProjectModal} handleReload={() => { }} employees={project?.all_employees} alreadyAddedEmployees={project?.added_employees} project={project} /> : <></>}
            {showDeleteItemModal ? <DeleteItem item={"Project"} deleteItem={()=> deleteProject()} cancelDeleteItem={handleCloseDeleteItemModal} /> : null}
            {showAssignRoleModal && selectedEmployee ? <AssignRoleModal project={project} handleCloseAssignRoleModal={handleCloseAssignRoleModal} selectedEmployee={selectedEmployee} handleReload={handleReload} /> : null}


            <div className="header">
                <nav className="navbar py-4">
                    <div className="container-xxl">

                        <div className="h-right d-flex align-items-center mr-5 mr-lg-0 order-1">
                            <div className="d-flex">

                                <div className="avatar-list avatar-list-stacked px-3">
                                    {
                                        project?.added_employees && project.added_employees.map(employee => {
                                            return <img key={employee.id} className="avatar rounded-circle" src={API_URL_PREFIX + "/media/" + employee.image} alt="" />
                                        })
                                    }


                                    <span className="avatar rounded-circle text-center pointer" onClick={handleOpenEmpToProjectModal}><i className="icofont-ui-add" /></span>
                                </div>
                            </div>

                        </div>

                        <div className="order-0 col-lg-8 col-md-8 col-sm-12 col-12 mb-3 mb-md-0 ">
                            <div className="input-group flex-nowrap input-group-lg">
                                <h3 className="text-capitalize"><i class="icofont-briefcase "></i> {project?.title}</h3>
                                <div >
                                    <select className="form-select ml-3 text-capitalize" style={{ marginLeft: "10px" }} onChange={(e) => {
                                        if (e.target.value !== "") {
                                            updateProject({ "status": e.target.value })
                                        }
                                    }}>
                                        <option selected={project?.status === null} value="">Set Status</option>
                                        {
                                            project?.all_statuses && project.all_statuses.map(status => {
                                                return <option className="text-capitalize" key={status[0]} selected={project.status?.status === status[0]} >{status[1]}</option>
                                            })
                                        }

                                    </select>
                                </div>
                                <div onClick={handleOpenDeleteItemModal} style={{marginTop: '8px', marginLeft: '30px', cursor:'pointer'}}>
                                    <i style={{fontSize:"24px"}} class="icofont-bin"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
            <ul className="nav nav-tabs tab-card" role="tablist">
                <li className="nav-item"><a className={currentTab === "OverviewTab" ? "nav-link active" : "nav-link"} style={{ cursor: "pointer" }} onClick={() => { setCurrentTab("OverviewTab") }}>Overview</a></li>
                <li className="nav-item"><a className={currentTab === "ListTab" ? "nav-link active" : "nav-link"} style={{ cursor: "pointer" }} onClick={() => { setCurrentTab("ListTab") }}>List</a></li>
                <li className="nav-item"><a className={currentTab === "BoardTab" ? "nav-link active" : "nav-link"} style={{ cursor: "pointer" }} onClick={() => { setCurrentTab("BoardTab") }}>Board</a></li>
                <li className="nav-item"><a className={currentTab === "TimelineTab" ? "nav-link active" : "nav-link"} style={{ cursor: "pointer" }} onClick={() => { setCurrentTab("TimelineTab") }}>Timeline</a></li>
                <li className="nav-item"><a className={currentTab === "CalendarTab" ? "nav-link active" : "nav-link"} style={{ cursor: "pointer" }} onClick={() => { setCurrentTab("CalendarTab") }}>Calendar</a></li>
                <li className="nav-item"><a className={currentTab === "MessagesTab" ? "nav-link active" : "nav-link"} style={{ cursor: "pointer" }} onClick={() => { setCurrentTab("MessagesTab") }}>Messages</a></li>
                <li className="nav-item"><a className={currentTab === "AttachmentsTab" ? "nav-link active" : "nav-link"} style={{ cursor: "pointer" }} onClick={() => { setCurrentTab("AttachmentsTab") }}>Attachments</a></li>
            </ul>
            <div className="tab-content mt-2">
                <div className="tab-pane fade show active" id="nav-Preview6" role="tabpanel">

                </div>
                <div className="tab-pane fade" id="nav-HTML6" role="tabpanel">

                </div>
            </div>
        </div>

        {currentTab === "OverviewTab" ? <Overview project={project} /> : null}
        {currentTab === "ListTab" ? <Tasks project={project} /> : null}
        {currentTab === "BoardTab" ? <Board project={project} /> : null}
        {currentTab === "CalendarTab" ? <Calendar project={project} /> : null}
        {currentTab === "MessagesTab" ? <Messages project={project} /> : null}


    </>
}
export default Project