import { useEffect } from "react"
import { getUserProfileDataAPI } from "../../../services/apiService"
import { ErrorAlert, SuccessAlert } from "../../../utils/alerts"
import { useDispatch } from "react-redux"
import { setUser } from "../../../redux/actions/appActions"
import { useHistory } from "react-router-dom"
import logout from "../../../utils/logout"

const LoginWithOfficehassle = () => {

    const dispatch = useDispatch()
    const history = useHistory()
    
    useEffect(() => {
        getUserProfileData()
    }, [])

    const getUserProfileData = () => {
        getUserProfileDataAPI().then(res => {
            const resp = res.data
            localStorage.setItem("two_factor", "false")
            dispatch(setUser(resp))
            SuccessAlert("Login successfully!")
            history.push("/admin/")

        }).catch(error => {
            if (error.response) {
                ErrorAlert(error.response.data.message)
                if (error.response.status === 401) {
                   logout();
                }
            } else {
                ErrorAlert(error.message)
            }
        })
    }

    return <></>
}
export default LoginWithOfficehassle