import CreateTask from "../CreateTask/CreateTask"

const TaskModal = ({ handleCloseCreateModal, project, setTaskCreated, taskCreated, startDate }) => {
    return <>
        <div className="modal fade show" style={{ display: "block", zIndex: "1000!important;" }} aria-modal="true">
            <div className="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title  fw-bold" >Create Task</h5>
                        <button type="button" className="btn-close" onClick={handleCloseCreateModal} />
                    </div>
                    <CreateTask handleCloseCreateModal={handleCloseCreateModal} project={project} setTaskCreated={setTaskCreated} taskCreated={taskCreated} startDate={startDate}/>
                </div>
            </div>
        </div>

    </>
}
export default TaskModal