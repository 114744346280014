
import { useEffect, useState } from "react"
import Activity from "../../../components/admin/Activity/Activity"
import InboxMessages from "../../../components/admin/InboxMessages/InboxMessages"

const Inbox = () => {

    const [currentTab, setCurrentTab] = useState("Activity")

    return <>

        <div>
            <ul className="nav nav-tabs tab-card" role="tablist">
                <li className="nav-item"><a className={currentTab === "Activity" ? "nav-link active" : "nav-link"} style={{ cursor: "pointer" }} onClick={() => { setCurrentTab("Activity") }}>Activity</a></li>
                <li className="nav-item"><a className={currentTab === "Messages" ? "nav-link active" : "nav-link"} style={{ cursor: "pointer" }} onClick={() => { setCurrentTab("Messages") }}>Messages I've sent</a></li>
            </ul>
            <div className="tab-content mt-2">
                <div className="tab-pane fade show active" id="nav-Preview6" role="tabpanel">

                </div>
                <div className="tab-pane fade" id="nav-HTML6" role="tabpanel">

                </div>
            </div>
        </div>
        {currentTab === "Activity" ? <Activity /> : null}
        {currentTab === "Messages" ? <InboxMessages /> : null}
    </>
}
export default Inbox