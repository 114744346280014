
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction"
import { getProjectAllTasksAPI } from '../../../services/apiService'
import { ErrorAlert } from '../../../utils/alerts'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { RotatingLines } from 'react-loader-spinner'
import UpdateTaskModal from '../../../components/admin/UpdateTaskModal/UpdateTaskModal'
import TaskModal from '../../../components/TaskModal/TaskModal'
import logout from '../../../utils/logout'


const Calendar = ({ project }) => {

    const [allEvents, setAllEvents] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedDate, setSelectedDate] = useState('')

    const [itemSelectedForUpdate, setItemSelectedForUpdate] = useState({})
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [showUpdateItemModal, setShowUpdateItemModal] = useState(false)
    const [taskUpdated, setTaskUpdated] = useState(false)


    const handleOpenCreateModal = () => setShowCreateModal(true)
    const handleCloseCreateModal = () => setShowCreateModal(false)

    const handleOpenUpdateItemModal = () => setShowUpdateItemModal(true)
    const handleCloseUpdateItemModal = () => setShowUpdateItemModal(false)

    const handleTaskUpdated = () => { setTaskUpdated(!taskUpdated) }

    useEffect(() => {
        if (project?.id) {
            getProjectAllTasks(project.id)
        }
    }, [project, taskUpdated])



    const getProjectAllTasks = (projectId) => {
        setLoading(true)
        getProjectAllTasksAPI(projectId).then(res => {
            let allProjects = res.data.data
            setAllEvents(
                allProjects.length > 0
                    ? allProjects.map(task => { return { ...task, taskId: task.id, date: moment.utc(task.start_date).local().format('YYYY-MM-DD') } })
                    : [])

            setLoading(false)
        }).catch(error => {
            if (error.response) {
                let err = `Unable to fetch project tasks. error(${error.response.data.message})`
                ErrorAlert(err)
                if (error.response.status === 401) {
                    logout();
                }
            } else {
                ErrorAlert(error.message)
            }
        })
    }


    const handleDateClick = (arg) => {
        console.log(arg.dateStr)
        setSelectedDate(arg.dateStr)
        handleOpenCreateModal()
    }

    function renderEventContent(eventInfo) {
        console.log(eventInfo.event.extendedProps)
        return (
            <>
                <b onClick={() => {
                    handleOpenUpdateItemModal()
                    setItemSelectedForUpdate({ ...eventInfo.event._def, ...eventInfo.event.extendedProps, id: eventInfo.event.extendedProps.taskId })
                }} style={{ color: "white", cursor: "pointer" }}>{eventInfo.event._def.title}</b>
            </>
        )
    }
    return <>

        {showUpdateItemModal ?
            <UpdateTaskModal
                handleCloseUpdateItemModal={handleCloseUpdateItemModal}
                itemSelectedForUpdate={itemSelectedForUpdate}
                setItemSelectedForUpdate={setItemSelectedForUpdate}
                handleTaskUpdated={handleTaskUpdated} />
            : null
        }
        {showCreateModal ?
            <TaskModal
                handleCloseCreateModal={handleCloseCreateModal}
                setTaskCreated={setTaskUpdated}
                taskCreated={taskUpdated}
                project={project} 
                startDate={selectedDate}
                /> : null
        }


        {
            loading ?
                <div className="col-12 text-center mb-5  mt-5">

                    <RotatingLines
                        strokeColor="#484C7F"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="96"
                        visible={true}
                    />

                </div>
                : <FullCalendar
                    plugins={[dayGridPlugin, interactionPlugin]}
                    initialView="dayGridMonth"
                    weekends={true}
                    events={allEvents}
                    dateClick={handleDateClick}
                    eventContent={renderEventContent}
                />
        }


    </>
}

export default Calendar