import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { API_URL_PREFIX } from '../../../constants/apiUrl';
import { useFormik } from 'formik';
import { ErrorAlert, SuccessAlert } from '../../../utils/alerts';
import { getUserProfileDataAPI } from '../../../services/apiService';
import countries from '../../../utils/countriesList';
import axios from 'axios';
import { getToken } from '../../../utils/getToken';
import { Link } from 'react-router-dom';
import ProfilePng from "../../../assets/images/profile.png"
import { useDispatch } from "react-redux";
import { setUser } from "../../../redux/actions/appActions";
import logout from '../../../utils/logout';

const EditProfile = () => {
  const user = useSelector((state) => state.user)?.user;
  let dispatch = useDispatch();
  
  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    about: '',
    address: '',
    city: '',
    country: '',
    phoneNumber: '',
    postalCode: '',
  });

  const [changeImage, setChangeImage] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const validate = (values) => {
    const errors = {};
    //validation for first name
    if (!values.firstName) {
      errors.firstName = '*Please fill the first name';
    } else if (values.firstName.trim().length < 3) {
      errors.firstName = '*First name must be contain upto 3 characters';
    } else if (!isNaN(values.firstName)) {
      errors.firstName = '*Only character allowed';
    }
    //validation for last name
    if (!values.lastName) {
      errors.lastName = '*Please fill the last name';
    } else if (values.lastName.trim().length < 3) {
      errors.lastName = '*Last name must be contain upto 3 characters';
    } else if (!isNaN(values.lastName)) {
      errors.lastName = '*Only character allowed';
    }

    //validation for mobile number

    if (!values.phoneNumber) {
      errors.phoneNumber = '*Please fill the phone number';
    } else if (!/^(\+\d{1,3}[- ]?)?\d{10}$/.test(values.phoneNumber)) {
      errors.phoneNumber =
        '*Please enter a valid phone number e.g. +923001234567';
    }

    //validate address
    if (!values.address || values.address.trim().length <= 0) {
      errors.address = '*Please fill address';
    } else if (!isNaN(values.address)) {
      errors.address = '*Only character allowed';
    }

    //validate city
    if (!values.city || values.city.trim().length <= 0) {
      errors.city = '*Please fill city';
    } else if (!isNaN(values.city)) {
      errors.city = '*Only character allowed';
    }
    //validate the country

    if (!values.country || values.country.trim().length <= 0) {
      errors.country = '*Please fill country';
    } else if (!isNaN(values.country)) {
      errors.country = '*Only character allowed';
    }

    //validate postal code

    if (!values.postalCode || values.postalCode.trim().length <= 0) {
      errors.postalCode = '*Please fill the postal code';
    } else if (isNaN(values.postalCode)) {
      errors.postalCode = '*Only digits allowed';
    }

    //validate the about

    if (!values.about || values.about.trim().length <= 0) {
      errors.about = '*Please fill about';
    } else if (!isNaN(values.about)) {
      errors.about = '*Only character allowed';
    }

    return errors;
  };

  const userForm = useFormik({
    initialValues,
    validate,
    onSubmit: (values) => {
      console.log(values);
      updateProfile(values);
    },
  });

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    getUserProfileDataAPI()
      .then((res) => {
        const data = res.data;
        userForm.setValues({
          firstName: data.first_name,
          lastName: data.last_name,
          about: data.about,
          address: data.address,
          city: data.city,
          country: data.country,
          phoneNumber: data.phone_no,
          postalCode: data.postal_code,
        });
        console.log(data);
      })
      .catch((error) => {
        if (error.response) {
          let err = `Unable to fetch projects. error(${error.response.data.message})`;
          ErrorAlert(err);
          if (error.response.status === 401) {
            logout();
          }
        } else {
          ErrorAlert(error.message);
        }
      });
  };

  const updateImage = (e) => {
    setChangeImage(e.target.files[0]);
  };

  const updateProfile = async (profileData) => {
    setShowLoading(true);

    let form_data = new FormData();
    form_data.append('first_name', profileData.firstName);
    form_data.append('last_name', profileData.lastName);
    form_data.append('address', profileData.address);
    form_data.append('city', profileData.city);
    form_data.append('country', profileData.country);
    form_data.append('phone_no', profileData.phoneNumber);
    form_data.append('postal_code', profileData.postalCode);
    form_data.append('about', profileData.about);

    if (changeImage) {
      form_data.append('photo', changeImage, changeImage.name);
    }

    console.log(changeImage);

    const JWTToken = getToken();
    const url = `${API_URL_PREFIX}/api/user/profile/`;

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + JWTToken,
      },
      onUploadProgress: (p) => {
        setLoaded(p.loaded);
      },
    };
    try {
      const response = await axios.put(url, form_data, config);

      if (response.status === 200) {
        SuccessAlert('Profile updated successfully');
        dispatch(setUser(response.data));
      } else if (response.status === 400) {
        ErrorAlert('Error occurs while updating profile.');
      } else if (response.status === 401) {
        localStorage.clear();
        window.location.href = '/';
      }
      setShowLoading(false);
      setLoaded(0);
    } catch (err) {
      Object.keys(err.response.data).forEach((key) => {
        ErrorAlert(`${err.response.data[key]}`);
      });
      setShowLoading(false);
      setLoaded(0);
    }
  };

  return (
    <div className="body d-flex py-lg-3 py-md-2">
      <div className="container-xxl">
        <div className="row clearfix">
          <div className="col-md-12">
            <div className="card border-0 mb-4 no-bg">
              <div className="card-header py-3 px-0 d-flex align-items-center  justify-content-between border-bottom">
                <h3 className=" fw-bold flex-fill mb-0">Profile</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-3">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <div className="card teacher-card  mb-3">
              <div className="card-body d-flex teacher-fulldeatil">
                <div className="profile-teacher pe-xl-4 pe-md-2 pe-sm-4 pe-4 text-center w220">
                  <img
                    alt="..."
                    className="avatar xl rounded-circle img-thumbnail shadow-sm"
                    src={
                      changeImage
                        ? URL.createObjectURL(changeImage)
                        : API_URL_PREFIX + user.photo
                    }
                    onError={({ currentTarget }) => {
                      console.log('on error');
                      currentTarget.onerror = null;
                      currentTarget.src = ProfilePng;
                    }}
                  />
                  <div>
                    <p className="mt-2 small">
                      <b>{user.username} </b>
                    </p>
                    <p style={{ lineHeight: 0 }} className="small">
                      <span className="text-capitalize">({user.role})</span>{' '}
                    </p>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '20px',
                    }}
                  >
                    <input
                      name="image"
                      accept="image/*"
                      id="file-upload"
                      type="file"
                      onChange={(e) => {
                        updateImage(e);
                      }}
                    />
                  </div>
                </div>
                <div className="teacher-info border-start ps-xl-4 ps-md-4 ps-sm-4 ps-4 w-100">
                  <div className="row g-3 mb-3">
                    <div className="mb-3 col">
                      <label htmlFor="firstName" className="form-label">
                        *First Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First Name"
                        name="firstName"
                        onChange={userForm.handleChange}
                        defaultValue={userForm.values.firstName}
                        value={userForm.values.firstName}
                      />
                      {userForm.errors.firstName ? (
                        <div className="text-secondary">
                          {userForm.errors.firstName}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-3 col">
                      <label htmlFor="lastName" className="form-label">
                        *Last Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                        name="lastName"
                        onChange={userForm.handleChange}
                        defaultValue={userForm.values.lastName}
                        value={userForm.values.lastName}
                      />
                      {userForm.errors.lastName ? (
                        <div className="text-secondary">
                          {userForm.errors.lastName}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row g-3 mb-3">
                    <div className="mb-3 col">
                      <label htmlFor="about" className="form-label">
                        About
                      </label>
                      <textarea
                        className="form-control"
                        rows={3}
                        placeholder="About"
                        name="about"
                        onChange={userForm.handleChange}
                        defaultValue={userForm.values.about}
                        value={userForm.values.about}
                      />
                      {userForm.errors.about ? (
                        <div className="text-secondary">
                          {userForm.errors.about}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row g-3 mb-3">
                    <div className="mb-3 col">
                      <label htmlFor="phoneNumber" className="form-label">
                        *Phone Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone Number"
                        name="phoneNumber"
                        onChange={userForm.handleChange}
                        defaultValue={userForm.values.phoneNumber}
                        value={userForm.values.phoneNumber}
                      />
                      {userForm.errors.phoneNumber ? (
                        <div className="text-secondary">
                          {userForm.errors.phoneNumber}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-3 col">
                      <label htmlFor="email" className="form-label">
                        *Email
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        name="email"
                        defaultValue={user.email}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="row g-3 mb-3">
                    <div className="mb-3 col">
                      <label htmlFor="address" className="form-label">
                        *Complete Address
                      </label>
                      <textarea
                        className="form-control"
                        rows={3}
                        placeholder="Complete Address"
                        name="address"
                        onChange={userForm.handleChange}
                        defaultValue={userForm.values.address}
                        value={userForm.values.address}
                      />
                      {userForm.errors.address ? (
                        <div className="text-secondary">
                          {userForm.errors.address}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="row g-3 mb-3">
                    <div className="mb-3 col">
                      <label htmlFor="city" className="form-label">
                        *City
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="City"
                        name="city"
                        onChange={userForm.handleChange}
                        defaultValue={userForm.values.city}
                        value={userForm.values.city}
                      />
                      {userForm.errors.city ? (
                        <div className="text-secondary">
                          {userForm.errors.city}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-3 col">
                      <label htmlFor="postalCode" className="form-label">
                        *Postal Code
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Postal Code"
                        name="postalCode"
                        onChange={userForm.handleChange}
                        defaultValue={userForm.values.postalCode}
                        value={userForm.values.postalCode}
                      />
                      {userForm.errors.postalCode ? (
                        <div className="text-secondary">
                          {userForm.errors.postalCode}
                        </div>
                      ) : null}
                    </div>

                    <div className="mb-3 col">
                      <label htmlFor="postalCode" className="form-label">
                        *Country
                      </label>
                      <select
                        className="form-select text-capitalize"
                        name="country"
                        onChange={userForm.handleChange}
                        value={userForm.values.country}
                      >
                        {countries.map((country) => (
                          <option key={country} value={country}>
                            {country}
                          </option>
                        ))}
                      </select>
                      {userForm.errors.country ? (
                        <div className="text-secondary">
                          {userForm.errors.country}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div>
                    <Link className='text-primary' to="/admin/changePassword/">Change Password ? </Link>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <button
                      className="btn btn-primary"
                      disabled={showLoading}
                      onClick={() => {
                        userForm.handleSubmit();
                      }}
                    >
                      Update{' '}
                      {/* <i
                        style={{ cursor: 'pointer' }}
                        class="icofont-pencil-alt-5"
                      ></i> */}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
