
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import { ErrorAlert, SuccessAlert } from '../../utils/alerts'
import { useState } from 'react'
import { uploadProjectResourceAPI } from '../../services/apiService'
import { objectToFormData } from '../../utils/objectToFormData'
import { RotatingLines } from 'react-loader-spinner'
import logout from '../../utils/logout'

const UploadProjectResourcesModal = ({ project, handleCloseUploadResourcesModal, handleReload }) => {

    const [loading, setLoading] = useState(false)

    // specify upload params and url for your files
    const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }

    // called every time a file's `status` changes
    const handleChangeStatus = ({ meta, file }, status) => { console.log(status, meta, file) }

    // receives array of files that are done uploading when submit button is clicked
    const handleSubmit = (files) => {
        console.log(files)
        let resources = files.map(f => f.file)
        let data = {
            "project": project.id,
            "resources": resources
        }
        console.log(data)
        uploadProjectResource(data)
    }


    const uploadProjectResource = (data) => {
        setLoading(true)
        let formData = objectToFormData(data, 'resources')
        uploadProjectResourceAPI(formData).then(res => {
            console.log(res)
            handleCloseUploadResourcesModal()
            handleReload()
            SuccessAlert(res.data.message)
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            if (error.response) {
                ErrorAlert(error.response.data.message)
                if (error.response.status === 401) {
                    logout();
                }
            } else {
                ErrorAlert(error.message)
            }
        })
    }


    return <>

        <div className="modal fade show" style={{ display: "block", zIndex: "1000!important;" }} aria-modal="true">
            <div className="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title  fw-bold" >Upload Resources</h5>
                        <button type="button" className="btn-close" onClick={handleCloseUploadResourcesModal} />
                    </div>
                    {
                        loading ? <>
                            <div className="modal-body">
                                <div className="mb-3" style={{ display: "flex", justifyContent: "center" }}>
                                    <RotatingLines
                                        strokeColor="#484C7F"
                                        strokeWidth="5"
                                        animationDuration="0.75"
                                        width="96"
                                        visible={true}
                                    />
                                </div>
                            </div>

                        </> : <>
                            <div className="modal-body">
                                <div className="mb-3" style={{ display: "flex", justifyContent: "center" }}>
                                    <Dropzone
                                        getUploadParams={getUploadParams}
                                        onChangeStatus={handleChangeStatus}
                                        onSubmit={handleSubmit}
                                        accept="*"
                                    />
                                </div>
                            </div>
                        </>

                    }

                </div>
            </div>
        </div>
    </>
}
export default UploadProjectResourcesModal