import { useEffect, useState } from "react"
import TaskModal from "../../../components/TaskModal/TaskModal"
import TasksList from "../../../components/TasksList/TasksList"


const Tasks = ({ project }) => {

    const [filter, setFilter] = useState("all")
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [taskCreated, setTaskCreated] = useState(false)
    const handleOpenCreateModal = () => setShowCreateModal(true)
    const handleCloseCreateModal = () => setShowCreateModal(false)

    useEffect(() => { }, [project])
    return <>
        {showCreateModal ? <TaskModal handleCloseCreateModal={handleCloseCreateModal} setTaskCreated={setTaskCreated} taskCreated={taskCreated} project={project} /> : <></>}
        <div className="card-header p-0 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">

            <div className="d-flex py-2 project-tab flex-wrap w-sm-100">
                <button type="button" className="btn btn-dark w-sm-100" onClick={handleOpenCreateModal}><i className="icofont-plus-circle me-2 fs-6" />Create Task</button>
                <ul className="nav nav-tabs tab-body-header rounded ms-3 prtab-set w-sm-100" role="tablist">
                    <li className="nav-item" ><a href="" className={filter==="all" ? "nav-link active" : "nav-link"} onClick={(e)=>{e.preventDefault(); setFilter("all")}}>All</a></li>
                    <li className="nav-item" ><a href="" className={filter==="pending" ? "nav-link active" : "nav-link"} onClick={(e)=>{e.preventDefault(); setFilter("pending")}}>Pending</a></li>
                    <li className="nav-item" ><a href="" className={filter==="in progress" ? "nav-link active" : "nav-link"} onClick={(e)=>{e.preventDefault(); setFilter("in progress")}}>In Progress</a></li>
                    <li className="nav-item" ><a href="" className={filter==="completed" ? "nav-link active" : "nav-link"} onClick={(e)=>{e.preventDefault(); setFilter("completed")}}>Completed</a></li>
                </ul>
            </div>
        </div>
        <TasksList project={project} taskCreated={taskCreated} filter={filter}/>
    </>
}
export default Tasks