import { useState } from "react"
import { RotatingLines } from 'react-loader-spinner'
import { useFormik } from "formik"
import { ErrorAlert, SuccessAlert } from "../../../utils/alerts"
import { createProjectTaskAPI } from "../../../services/apiService"
import { changeDateToIsoFormat } from "../../../utils/formatDate"
import logout from "../../../utils/logout"

const SubTaskModal = ({ handleCloseSubTaskModal, handleTaskUpdated, project, task }) => {

    console.log(project)
    const [loading, setLoading] = useState(false)


    const validate = values => {
        const errors = {};


        if (!values.title) {
            errors.title = '*Task title required';
        } else if (values.title.length < 5) {
            errors.title = '*Task title must be greater than 5 characters'
        }

        if (!values.priority) {
            errors.priority = '*Task priority required';
        }

        if (!values.start_date) {
            errors.start_date = '*Task start date required';
        }


        if (!values.assign_to) {
            errors.assign_to = '*Assigne required';
        }

        return errors
    }

    const taskForm = useFormik({
        initialValues: {
            project: project ? JSON.stringify(project) : '{}',
            title: '',
            description: '',
            priority: '',
            start_date: '',
            end_date: '',
            assign_to: ''
        },
        validate,
        onSubmit: values => {
            let project = JSON.parse(values.project)
            let start_date = values.start_date
            let end_date = values.end_date
            let reqData = {
                ...values,
                project: project.id,
                parent: task.id,
                start_date: changeDateToIsoFormat(start_date),
                end_date: end_date ? changeDateToIsoFormat(end_date) : null
            }
            console.log(reqData)
            createProjectTask(reqData)
        },
    })

    const createProjectTask = (data) => {
        createProjectTaskAPI(data).then(res => {
            SuccessAlert(res.data.message)
            handleTaskUpdated()
            handleCloseSubTaskModal()
        }).catch(error => {
            if (error.response) {
                ErrorAlert(error.response.data.message)
                if (error.response.status === 401) {
                   logout();
                }
            } else {
                ErrorAlert(error.message)
            }
        })
    }

    return <>
        <div className="modal fade show" style={{ display: "block", zIndex: "1000!important;" }} aria-modal="true">
            <div className="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title  fw-bold" >Create Task</h5>
                        <button type="button" className="btn-close" onClick={handleCloseSubTaskModal} />
                    </div>

                    {
                        loading ? <div className="col-12 text-center mb-5  mt-5">

                            <RotatingLines
                                strokeColor="#484C7F"
                                strokeWidth="5"
                                animationDuration="0.75"
                                width="96"
                                visible={true}
                            />

                        </div> : <>
                            <div className="modal-body">
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">*Task Title</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Explain what the Task Title"
                                        name="title"
                                        onChange={taskForm.handleChange}
                                        value={taskForm.values.title}
                                    />
                                    {taskForm.errors.title ? <div className="text-secondary">{taskForm.errors.title}</div> : null}
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="description" className="form-label">Description</label>
                                    <textarea
                                        className="form-control"
                                        rows={3}
                                        placeholder="Add any extra details about the task"
                                        name="description"
                                        onChange={taskForm.handleChange}
                                        value={taskForm.values.description}
                                    />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">Task Priority</label>
                                    <select
                                        className="form-select text-capitalize"
                                        name="priority"
                                        onChange={taskForm.handleChange}

                                    >
                                        <option selected value="">Select Priority</option>
                                        <option value="low">Low</option>
                                        <option value="medium">Medium</option>
                                        <option value="high">High</option>
                                    </select>
                                    {taskForm.errors.priority ? <div className="text-secondary">{taskForm.errors.priority}</div> : null}
                                </div>

                                <div className="row g-3 mb-3">
                                    <div className="col">
                                        <label htmlFor="datepickerded" className="form-label">Task Start Date</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            id="datepickerded"
                                            name="start_date"
                                            onChange={taskForm.handleChange}
                                            value={taskForm.values.start_date}
                                        />
                                        {taskForm.errors.start_date ? <div className="text-secondary">{taskForm.errors.start_date}</div> : null}
                                    </div>
                                    <div className="col">
                                        <label htmlFor="datepickerdedone" className="form-label">Task End Date</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            name="end_date"
                                            onChange={taskForm.handleChange}
                                            value={taskForm.values.end_date}
                                        />
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <label className="form-label text-capitalize">Assign To</label>
                                    <select
                                        className="form-select text-capitalize"
                                        name="assign_to"
                                        onChange={taskForm.handleChange}
                                    >
                                        <option className="text-capitalize" selected value="">Select Assigne</option>
                                        {
                                            JSON.parse(taskForm.values.project)?.added_employees ? JSON.parse(taskForm.values.project)?.added_employees.map(emp => {
                                                return <option className="text-capitalize" value={emp.id}>{emp.first_name}  {emp.last_name}</option>
                                            }) : null
                                        }

                                    </select>
                                    {taskForm.errors.assign_to ? <div className="text-secondary">{taskForm.errors.assign_to}</div> : null}
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={handleCloseSubTaskModal}>Cancel</button>
                                <button type="button" className="btn btn-primary" onClick={taskForm.handleSubmit}>Create</button>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>

    </>
}
export default SubTaskModal