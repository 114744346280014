
export const changeDateToIsoFormat = (date) => {
    let isoDate = new Date(date).toISOString().split('T')[0]
    isoDate = new Date(isoDate + 'Z').toISOString()
    console.log(isoDate)
    return isoDate
}

export const changeIsoFormatToDate = (isoDateTime)=>{
    if(isoDateTime){
        return isoDateTime.split('T')[0]
    }else return null
}