import { useState } from "react"
import { useFormik } from 'formik'
import { createProjectAPI } from "../../services/apiService"
import { ErrorAlert, SuccessAlert } from "../../utils/alerts"
import logout from "../../utils/logout"

const CreateProject = ({ handleCloseCreateModal }) => {

    const validate = values => {
        const errors = {};

        if (!values.title) {
            errors.title = '*Title required';
        } else if (values.title.length < 5) {
            errors.title = '*Title must be greater than 5 characters';
        }

        return errors
    }


    const project = useFormik({
        initialValues: {
            title: '',
            description: '',
        },
        validate,
        onSubmit: values => {
            console.log(values)
            createProject(values)
        },
    })

    const createProject = (data) => {
        createProjectAPI(data).then(res => {
            SuccessAlert(res.data.message)
            handleCloseCreateModal()
        }).catch(error => {
            if (error.response) {
                ErrorAlert(error.response.data.message)
                if (error.response.status === 401) {
                    logout();
                }
            } else {
                ErrorAlert(error.message)
            }
        })
    }
    return <>
        {/* Create Project*/}

        <div className="modal-body">
            <div className="mb-3">
                <label htmlFor="exampleFormControlInput77" className="form-label">Project Title</label>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Explain what the Project Title"
                    name="title"
                    onChange={project.handleChange}
                    value={project.values.title}
                />
                {project.errors.title ? <div className="text-secondary">{project.errors.title}</div> : null}
            </div>

            <div className="mb-3">
                <label htmlFor="exampleFormControlTextarea78" className="form-label">Description (optional)</label>
                <textarea
                    className="form-control"
                    rows={3}
                    placeholder="Add any extra details about the project"
                    name="description"
                    onChange={project.handleChange}
                    defaultValue={project.values.description}
                />
            </div>
        </div>
        <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={handleCloseCreateModal}>Cancel</button>
            <button type="button" className="btn btn-primary" onClick={() => { project.handleSubmit() }}>Create</button>
        </div>


    </>
}
export default CreateProject