
import React from "react";
import TaskDocumentationHeader from "./TaskDocumentationHeader";
import TaskDocumentationTable from "./TaskDocumentationTable";
import { useState } from "react";


const TaskDocumentations = () => {
  const [reload, setReload] = useState(false);

  return <div>
    <TaskDocumentationHeader reload={reload} setReload={setReload}/>
    <TaskDocumentationTable reload={reload} setReload={setReload}/>
  </div>;
};

export default TaskDocumentations;
