import { Switch } from "react-router"
import Header from "../components/employee/Header/Header"
import Sidebar from "../components/employee/Sidebar/Sidebar"
import EmployeeRoutes from "../routes/employeeRoutes"


const EmployeeLayout = ({ children }) => {
    return <>
        <Sidebar />
        <div className="main px-lg-4 px-md-4">
            <Header />
            <Switch>
                <EmployeeRoutes />
            </Switch>
            {children}
        </div>
    </>
}

export default EmployeeLayout