
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { getAllProjectsAPI, getEmployeeAllProjectsAPI } from "../../../services/apiService"
import { ErrorAlert } from "../../../utils/alerts"
import logout from "../../../utils/logout"

const ProjectsSubMenu = ({showCreateModal}) => {
    const [allProject, setAllProjects] = useState([])

    useEffect(()=>{
        getEmployeeAllProjects()
    },[])

    const getEmployeeAllProjects = () => {
        getEmployeeAllProjectsAPI().then(res => {
            setAllProjects(res.data.data)
            console.log(res.data.data)
        }).catch(error => {
            if (error.response) {
                let err =  `Unable to fetch projects. error(${error.response.data.message})`
                ErrorAlert(err)
                if (error.response.status === 401) {
                    logout();
                }
            } else {
                ErrorAlert(error.message)
            }
        })
    }

    return <>
        <ul className="sub-menu collapse" id="project-Components">
            {
                allProject.length > 0 ? allProject.map(project=>{
                    return <li key={project.id}><Link to={"/employee/project/"+project.encoded_id}  className="ms-link" style={{cursor: 'pointer', textTransform: 'capitalize'}}><span>{project.title}</span></Link></li>
           
                }) : <span className="text-light">You haven't added to any project yet.</span>
            }
        </ul>
    </>
}
export default ProjectsSubMenu