import { Switch } from "react-router"
import AdminRoutes from "../routes/adminRoutes"
import Header from "../components/Header/Header"
import Sidebar from "../components/Sidebar/Sidebar"
import { useState } from "react"


const AdminLayout = ({ children }) => {

    const [reload, setReload] = useState(false)
   

    return <>
        <Sidebar reload={reload} />
        <div className="main px-lg-4 px-md-4">
            <Header />
            <Switch>
                <AdminRoutes setReload={setReload}/>
            </Switch>
            {children}
        </div>
    </>
}

export default AdminLayout