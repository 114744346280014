export function formatSecondsToHoursMinutes(seconds) {
  const hours = Math.floor(seconds / 3600); 
  const remainingSeconds = seconds % 3600;
  const minutes = Math.floor(remainingSeconds / 60);
  const remainingSecondsAfterMinutes = remainingSeconds % 60;

  let result = '';

  if (hours > 0) {
    result += hours + ' Hour' + (hours > 1 ? 's' : '') + ' ';
  }

  if (minutes > 0) {
    result += minutes + ' Minute' + (minutes > 1 ? 's' : '') + ' ';
  }

  if (remainingSecondsAfterMinutes > 0) {
    result += Math.ceil(remainingSecondsAfterMinutes) + ' Second' + (remainingSecondsAfterMinutes > 1 ? 's' : '') + ' ';
  }

  if (result === '') {
    result = '0 Seconds';
  }

  return result.trim(); 
  }