import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { API_URL_PREFIX } from '../../../constants/apiUrl';
import { useFormik } from 'formik';
import { ErrorAlert, SuccessAlert } from '../../../utils/alerts';
import { getUserProfileDataAPI } from '../../../services/apiService';
import countries from '../../../utils/countriesList';
import axios from 'axios';
import { getToken } from '../../../utils/getToken';
import { Link } from 'react-router-dom';

const ChangePassword = () => {
  const user = useSelector((state) => state.user)?.user;

  const [initialValues, setInitialValues] = useState({
    oldPassword: '',
    newPassword: '',
  });

  const [showLoading, setShowLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const validate = (values) => {
    const errors = {};
    //validation for old password
    if (!values.oldPassword || values.oldPassword.trim().length <= 0) {
      errors.oldPassword = '*Please fill the old password';
    }

    //validation for new password
    if (!values.newPassword || values.newPassword.trim().length <= 0) {
      errors.newPassword = '*Please fill the new password';
    }

    return errors;
  };

  const passForm = useFormik({
    initialValues,
    validate,
    onSubmit: (values) => {
      console.log(values);
      updatePassword(values);
    },
  });

  const updatePassword = async (data) => {
    setShowLoading(true);

    let form_data = new FormData();
    form_data.append('old_password', data.oldPassword);
    form_data.append('password', data.newPassword);
    form_data.append('password2', data.newPassword);

    const JWTToken = getToken();
    const url = `${API_URL_PREFIX}/api/user/change-password/${user.id}/`;

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + JWTToken,
      },
      onUploadProgress: (p) => {
        setLoaded(p.loaded);
      },
    };
    try {
      const response = await axios.put(url, form_data, config);

      if (response.status === 200) {
        SuccessAlert('Password updated successfully');
      } else if (response.status === 400) {
        ErrorAlert('Error occurs while updating password.');
      } else if (response.status === 401) {
        localStorage.clear();
        window.location.href = '/';
      }
      setShowLoading(false);
      setLoaded(0);
    } catch (err) {
      Object.values(err.response.data).forEach((value) => {
        console.log(value);
        ErrorAlert(JSON.stringify(value));
      });
      setShowLoading(false);
      setLoaded(0);
    }
  };

  return (
    <div className="body d-flex py-lg-3 py-md-2">
      <div className="container-xxl">
        <div className="row clearfix">
          <div className="col-md-12">
            <div className="card border-0 mb-4 no-bg">
              <div className="card-header py-3 px-0 d-flex align-items-center  justify-content-between border-bottom">
                <h3 className=" fw-bold flex-fill mb-0">Change Password</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-3">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <div className="card teacher-card  mb-3">
              <div className="card-body d-flex teacher-fulldeatil">
                <div className="profile-teacher pe-xl-4 pe-md-2 pe-sm-4 pe-4 text-center w220">
                  <img
                    alt="..."
                    className="avatar xl rounded-circle img-thumbnail shadow-sm"
                    src={API_URL_PREFIX + user.photo}
                  />
                  <div>
                    <p className="mt-2 small">
                      <b>{user.username} </b>
                    </p>
                    <p style={{ lineHeight: 0 }} className="small">
                      <span className="text-capitalize">({user.role})</span>{' '}
                    </p>
                  </div>
                </div>
                <div className="teacher-info border-start ps-xl-4 ps-md-4 ps-sm-4 ps-4 w-100">
                  <div className="row g-3 mb-3">
                    <div className="mb-3 col">
                      <label htmlFor="firstName" className="form-label">
                        *Old Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Old Password"
                        name="oldPassword"
                        autocomplete="new-password"
                        onChange={passForm.handleChange}
                        defaultValue={passForm.values.oldPassword}
                        value={passForm.values.oldPassword}
                      />
                      {passForm.errors.oldPassword ? (
                        <div className="text-secondary">
                          {passForm.errors.oldPassword}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-3 col">
                      <label htmlFor="lastName" className="form-label">
                        *New Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="New Password"
                        name="newPassword"
                        autocomplete="new-password"
                        onChange={passForm.handleChange}
                        defaultValue={passForm.values.newPassword}
                        value={passForm.values.newPassword}
                      />
                      {passForm.errors.newPassword ? (
                        <div className="text-secondary">
                          {passForm.errors.newPassword}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <button
                      className="btn btn-primary"
                      disabled={showLoading}
                      onClick={() => {
                        passForm.handleSubmit();
                      }}
                    >
                      Change Password{' '}
                      <i
                        style={{ cursor: 'pointer' }}
                        class="icofont-pencil-alt-5"
                      ></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
