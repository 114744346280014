import { useEffect, useState } from "react"
import Person from "../../assets/images/person.png"
import { sendEmployeeSignupInvitationAPI } from "../../services/apiService"
import { SuccessAlert, ErrorAlert, LoadingAlert, HideLoadingAlert } from "../../utils/alerts"
import { API_URL_PREFIX } from "../../constants/apiUrl"
import logout from "../../utils/logout"

const EmployeeInvitation = ({ handleCloseEmployeeInvitationModal, employees }) => {
    const [email, setEmail] = useState("")
    const [loading, setLoading] = useState(false)

    const sendEmployeeSignupInvitation = (e) => {
        e.preventDefault()
        const req = {
            "employee_email": email
        }
        setLoading(true)
        sendEmployeeSignupInvitationAPI(req).then(res => {
            console.log(res.data)
            handleCloseEmployeeInvitationModal()
            SuccessAlert(res.data.message)
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                // The request was made and the server responded with a status code that falls out of the range of 2xx
                ErrorAlert(error.response.data.message);
                console.error(error.response.status);
                console.error(error.response.headers);
                if (error.response.status === 401) {
                    logout();
                }
            } else if (error.request) {
                // The request was made but no response was received
                ErrorAlert("No response recieved from server.");
            } else if (error.message === "Network Error") {
                // Handle network error here
                ErrorAlert("Network Error: Please check your internet connection");
            } else {
                // Something happened in setting up the request that triggered an Error
                ErrorAlert(error.message);
            }
        }).finally(()=>{
            setLoading(false)
        })
    }

    useEffect(() => { }, [employees])

    return <>
        <div className="modal fade show" style={{ display: "block", zIndex: "1000!important;" }} aria-modal="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title  fw-bold" >Employee Invitation</h5>
                        <button type="button" className="btn-close" onClick={handleCloseEmployeeInvitationModal} />
                    </div>
                    <div className="modal-body">
                        <form onSubmit={(e) => sendEmployeeSignupInvitation(e)}>
                            <div className="inviteby_email">
                                <div className="input-group mb-3">

                                    <input type="email" className="form-control" placeholder="Email address" id="exampleeEmail"
                                        onChange={(e) => setEmail(e.target.value)} required />
                                    {
                                        loading ? <div className="spinner-border text-primary" role="status">
                                        
                                      </div> : <button disabled={loading} className="btn btn-dark" type="submit">Sent</button>
                                    }

                                </div>
                            </div>
                        </form>
                        <div className="members_list">
                            <h6 className="fw-bold ">Already Joined</h6>
                            <ul className="list-unstyled list-group list-group-custom list-group-flush mb-0">
                                {
                                    employees && employees.map(employee => {
                                        return <li className="list-group-item py-3 text-center text-md-start">
                                            <div className="d-flex align-items-center flex-column flex-sm-column flex-md-column flex-lg-row">
                                                <div className="no-thumbnail mb-2 mb-md-0">
                                                    <img className="avatar lg rounded-circle" src={`${API_URL_PREFIX}/media/${employee.image}`} alt="" />
                                                </div>
                                                <div className="flex-fill ms-3 text-truncate">
                                                    <h6 className="mb-0  fw-bold text-capitalize">{employee.first_name} {employee.last_name}</h6>
                                                    <span className="text-muted">{employee.email}</span>
                                                </div>
                                                <div className="members-action">
                                                    <span className="members-role ">{employee?.position === "Admin" ? "Admin" : "Employee"}</span>
                                                    {/* <div className="btn-group">
                                                        <button type="button" className="btn bg-transparent dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i className="icofont-ui-settings  fs-6" />
                                                        </button>
                                                        <ul className="dropdown-menu dropdown-menu-end">
                                                            <li><a className="dropdown-item" href="index.html#"><i className="icofont-ui-password fs-6 me-2" />ResetPassword</a></li>
                                                            <li><a className="dropdown-item" href="index.html#"><i className="icofont-chart-line fs-6 me-2" />ActivityReport</a></li>
                                                        </ul>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </li>

                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </>
}

export default EmployeeInvitation