import { useEffect, useState } from "react";
import CheckInHeader from "./CheckInHeader"
import CheckInTable from "./CheckInTable"
import { useSelector } from "react-redux";


const CheckIns = ()=>{
    const [reload, setReload] = useState(false);
    const [locationPermissionAllowed, setLocationPermissionAllowerd] = useState(false);
    const profileData = useSelector((state) => state.user)?.user

    useEffect(()=>{
        getUserLocation();
    },[]);

    const getUserLocation = () => {
        return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
            (position) => {
                setLocationPermissionAllowerd(true);
            },
            (error) => {
                setLocationPermissionAllowerd(false);
            }
            );
        } else {
            setLocationPermissionAllowerd(false);
        }
        });
  };

    return <>
        <div className="row align-items-center g-3 mb-3">
            <div className="border-0 mb-4">
            <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                <h3 className="fw-bold mb-0">Check Ins & Check Outs</h3>
            </div>
            </div>
        </div>
        { profileData.role === "employee" ? <CheckInHeader locationPermissionAllowed={locationPermissionAllowed} reload={reload} setReload={setReload}/> : null }
        <CheckInTable reload={reload} setReload={setReload}/>
    </>
}
export default CheckIns