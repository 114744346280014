import { Route } from 'react-router-dom';
import Hello from '../components/Hello/Hello';
import Project from '../views/employee/Project/Project';
import CheckIns from '../components/CheckIns/CheckIns';
import TaskDocumentations from '../components/TaskDocumentations/TaskDocumentations';
import Profile from '../views/Profile/Profile';
import Dashboard from '../views/employee/Dashboard/Dashboard';
import EditProfile from '../views/admin/EditProfile/EditProfile';
import ChangePassword from '../views/admin/ChangePassword/ChangePassword';

const EmployeeRoutes = () => {
  return (
    <>
      <Route path="/employee/" component={Dashboard} exact />
      <Route path="/employee/checkIns" component={CheckIns} exact />
      <Route
        path="/employee/taskDocumentation"
        component={TaskDocumentations}
        exact
      />
      <Route path="/employee/project/:projectId" component={Project} excat />
      <Route path="/employee/profile" component={EditProfile} excat />
      <Route path="/employee/changePassword/" component={ChangePassword} excat />
    </>
  );
};
export default EmployeeRoutes;
