import { API_URL_PREFIX } from "../../../constants/apiUrl"
import moment from "moment/moment"
import { createProjectTaskStatusAPI, updateProjectTaskAPI } from "../../../services/apiService"
import { ErrorAlert, SuccessAlert } from "../../../utils/alerts"
import { useState } from "react"
import SubTaskModal from "../../../components/admin/SubTaskModal/SubTaskModal"
import { changeDateToIsoFormat, changeIsoFormatToDate } from "../../../utils/formatDate"
import logout from "../../../utils/logout"



const Task = ({ task, handleOpenUpdateItemModal, setItemSelectedForUpdate, handleOpenDeleteItemModal, setItemSelectedForDelete, handleTaskUpdated, project }) => {

    const [showSubTaskModal, setShowSubTaskModal] = useState(false)
    const [subTasks, setSubTasks] = useState(false)
    const handleShowSubTasks = () => { setSubTasks(!subTasks) }
    const handleShowSubTaskModal = () => { setShowSubTaskModal(true) }
    const handleCloseSubTaskModal = () => { setShowSubTaskModal(false) }

    const createProjectTaskStatus = (taskId, crrStatus) => {
        let reqData = {
            project_task: taskId,
            status: crrStatus,
        }
        createProjectTaskStatusAPI(reqData).then(res => {
            SuccessAlert(res.data.message)
            handleTaskUpdated()
        }).catch(error => {
            if (error.response) {
                ErrorAlert(error.response.data.message)
                if (error.response.status === 401) {
                    logout();
                }
            } else {
                ErrorAlert(error.message)
            }
        })
    }

    const updateTask = (task, name, value) => {
        let reqData = {
            ...task,
            [name]: value
        }
        reqData["assign_to"] = reqData.assign_to?.first_name ? reqData.assign_to.id : reqData.assign_to
        console.log(reqData)


        updateProjectTaskAPI(reqData.id, reqData).then(res => {

        }).catch(error => {
            if (error.response) {
                ErrorAlert(error.response.data.message)
                if (error.response.status === 401) {
                    logout();
                }
            } else {
                ErrorAlert(error.message)
            }
        })
    }

    return <>
        {showSubTaskModal ? <SubTaskModal task={task} handleTaskUpdated={handleTaskUpdated} handleCloseSubTaskModal={handleCloseSubTaskModal} project={project} /> : null}
        <tr role="row" className="odd" key={task.id}>
            <td className="text-capitalize" onClick={handleShowSubTasks} style={{ cursor: "pointer" }}> {subTasks ? <i class="icofont-rounded-up"></i> : <i class="icofont-simple-down"></i>} </td>
            {/* <td className="text-capitalize" style={task?.status?.status === "completed" ? { textAlign: 'center', fontSize: '19px', color: 'green' } : { textAlign: 'center', fontSize: '19px', color: '#dee2e6' }}> <i onClick={() => createProjectTaskStatus(task.id, task?.status?.status)} style={{ cursor: "pointer" }} class="icofont-check-circled"></i> </td> */}

            <td>
                <select
                    style={{ width: '130px' }}
                    className="form-select text-capitalize"
                    name="task_status"
                    onChange={(e) => createProjectTaskStatus(task.id, e.target.value)}
                >
                    <option className="text-capitalize" selected value="">Select Task Status</option>
                    <option className="text-capitalize" value="pending" selected={task?.status?.status === "pending"} >Pending</option>
                    <option className="text-capitalize" value="in progress" selected={task?.status?.status === "in progress"}>In Progress</option>
                    <option className="text-capitalize" value="completed" selected={task?.status?.status === "completed"} >Completed</option>

                </select>


            </td>

            <td className="text-capitalize"> {task.title} </td>
            <td>
                <img className="avatar rounded-circle" src={API_URL_PREFIX + "/media/" + task.assign_to.image} alt="" />
                <span className="fw-bold ms-1 text-capitalize">{task.assign_to.first_name} {task.assign_to.last_name}</span>
            </td>
            <td className=" dt-body-right" style={{ width: "20%" }}> {moment(task.start_date).format('MMMM Do YYYY')} </td>
            <td className=" dt-body-right" style={{ width: "20%" }}> {moment(task.end_date).format('MMMM Do YYYY')} </td>

        </tr>

        {
            subTasks ? <>
                {
                    task?.sub_tasks ? task.sub_tasks.map(subTask => {
                        return <tr role="row" className="odd parent" key={subTask.id}>
                            <td className="text-capitalize"></td>

                            {/* <td className="text-capitalize" style={subTask?.status?.status === "completed" ? { textAlign: 'center', fontSize: '19px', color: 'green' } : { textAlign: 'center', fontSize: '19px', color: '#dee2e6' }}> <i onClick={() => createProjectTaskStatus(subTask.id, subTask?.status?.status)} style={{ cursor: "pointer" }} class="icofont-check-circled"></i> </td> */}

                            <td>
                                <select
                                    style={{ width: '130px' }}
                                    className="form-select text-capitalize"
                                    name="task_status"
                                    onChange={(e) => createProjectTaskStatus(subTask.id, e.target.value)}
                                >
                                    <option className="text-capitalize" selected value="">Select Task Status</option>
                                    <option className="text-capitalize" value="pending" selected={subTask?.status?.status === "pending"} >Pending</option>
                                    <option className="text-capitalize" value="in progress" selected={subTask?.status?.status === "in progress"}>In Progress</option>
                                    <option className="text-capitalize" value="completed" selected={subTask?.status?.status === "completed"} >Completed</option>

                                </select>


                            </td>
                            <td className="text-capitalize"> {subTask.title} </td>
                        <td>
                            <img className="avatar rounded-circle" src={API_URL_PREFIX + "/media/" + subTask.assign_to.image} alt="" />
                            <span className="fw-bold ms-1 text-capitalize">{subTask.assign_to.first_name} {subTask.assign_to.last_name}</span>
                        </td>
                        <td className=" dt-body-right" style={{ width: "20%" }}> {moment(subTask.start_date).format('MMMM Do YYYY')} </td>
                        <td className=" dt-body-right" style={{ width: "20%" }}> {moment(subTask.end_date).format('MMMM Do YYYY')} </td>

                        </tr>

                    }) : null
                }
                <tr role="row" className="odd parent text-center" >
                    <td className="dt-body-right" colspan="7">
                        <p>No sub task.</p>
                    </td>
                </tr>

            </> : null
        }

    </>
}

export default Task