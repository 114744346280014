import ProjectResources from "../../../components/ProjectResources/ProjectResources"
import ProjectRoles from "../../../components/ProjectRoles/ProjectRoles"


const Overview = ({ project }) => {
    return <>
        <div className="body d-flex py-3">
            <div className="container-xxl">
                <div className="row g-3 mb-3 row-deck">
                    <ProjectRoles project={project}/>
                    <ProjectResources project={project}/>
                </div>

            </div>
        </div>
    </>
}

export default Overview