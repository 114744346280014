import { useEffect, useState } from "react"
import { addRemoveProjectMemberAPI, getProjectEmployeeRolesAPI } from "../../services/apiService"
import { ErrorAlert, SuccessAlert } from "../../utils/alerts"
import AddEmployeeToProjectModal from "../AddEmployeeToProjectModal/AddEmployeeToProjectModal"
import { RotatingLines } from 'react-loader-spinner'
import { API_URL_PREFIX } from "../../constants/apiUrl"
import AssignRoleModal from "../AssignRoleModal/AssignRoleModal"
import { useSelector } from "react-redux"
import logout from "../../utils/logout"

const ProjectRoles = ({ project }) => {

    const profileData = useSelector((state) => state.user)?.user
    const [roles, setRoles] = useState([])
    const [showAddEmpToProjectModal, setShowAddEmpToProjectModal] = useState(false)
    const [showAssignRoleModal, setShowAssignRoleModal] = useState(false)
    const [selectedEmployee, setSelectedEmployee] = useState({})
    const [loading, setLoading] = useState(false)
    const [reload, setReload] = useState(false)
    const handleOpenEmpToProjectModal = () => setShowAddEmpToProjectModal(true)
    const handleCloseEmpToProjectModal = () => setShowAddEmpToProjectModal(false)
    const handleOpenAssignRoleModal = () => setShowAssignRoleModal(true)
    const handleCloseAssignRoleModal = () => setShowAssignRoleModal(false)

    const handleReload = () => setReload(!reload)

    useEffect(() => {
        if (project?.id) {
            getProjectEmployeeRoles()
        }
    }, [project, reload])

    const getProjectEmployeeRoles = () => {
        setLoading(true)
        getProjectEmployeeRolesAPI(project.id).then(res => {
            console.log(res.data.data)
            setRoles(res.data.data)
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            if (error.response) {
                ErrorAlert(error.response.data.message)
                if (error.response.status === 401) {
                    logout();
                }
            } else {
                ErrorAlert(error.message)
            }
        })
    }


    const removeProjectMember = (employeeId) => {
        let reqData = {
            "project_id": project.id,
            "employee_id": employeeId,
            "action_type": "remove_member"
        }
        console.log(reqData)
        addRemoveProjectMemberAPI(reqData).then(res => {
            console.log(res.data)
            SuccessAlert(res.data.message)
            handleReload()
        }).catch(error => {
            if (error.response) {
                let err = `Unable to fetch projects. error(${error.response.data.message})`
                ErrorAlert(err)
                if (error.response.status === 401) {
                    logout();
                }
            } else {
                ErrorAlert(error.message)
            }
        })
    }

    const openSelectRoleOnAddEmployee = (emp)=>{
        setSelectedEmployee({
            "employee": {
            'email' : emp.employee__email, 
            'first_name' : emp.employee__first_name,
            'last_name' : emp.employee__last_name,
            'id' : emp.employee__id,
            'image' : emp.employee__image,
            },
            "role" : {
                "role" : ""
            }
        });
        setShowAssignRoleModal(true);
    }

    return <>

        {showAddEmpToProjectModal ? <AddEmployeeToProjectModal openSelectRoleOnAddEmployee={openSelectRoleOnAddEmployee} handleCloseEmpToProjectModal={handleCloseEmpToProjectModal} handleReload={handleReload} employees={project?.all_employees} alreadyAddedEmployees={roles.map(role => role.employee)} project={project} /> : <></>}
        {showAssignRoleModal && selectedEmployee ? <AssignRoleModal project={project} handleCloseAssignRoleModal={handleCloseAssignRoleModal} selectedEmployee={selectedEmployee} handleReload={handleReload} /> : null}

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h4>Project Roles</h4>
            {
                profileData.role === "admin" ? <div className="col-md-12 col-lg-1 col-xl-1 col-xxl-1">
                    <div className="" style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="">
                            <div className="d-flex align-items-center">
                                <div onClick={handleOpenEmpToProjectModal} style={{ borderStyle: 'dotted', cursor: 'pointer' }} className="avatar lg  rounded-1 no-thumbnail color-defult">
                                    <i class="icofont-ui-add"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : null
            }
        </div>

        {
            !loading ?
                roles.map(role => {
                    return <div key={role.employee.id} className="col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                        <div className="card ">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div className="avatar lg  rounded-1 no-thumbnail color-defult">
                                        <img className="avatar lg rounded-circle img-thumbnail" src={API_URL_PREFIX + "/media/" + role.employee.image} alt="profile" />
                                    </div>
                                    <div className="flex-fill ms-4">

                                        <h6 className="mb-0 text-capitalize">{role.employee.first_name} {role.employee.last_name}</h6>
                                        {role.role ? <div className="text-capitalize">{role.role.role}</div> : <div>No role assign yet</div>}


                                    </div>
                                    {
                                        profileData.role === "admin" ? <>
                                            <a href="chat.html#" className="nav-link py-2 px-3 text-muted" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v" style={{ color: 'black', fontSize: '20px' }} /></a>

                                            <ul className="dropdown-menu border-0 shadow dropdown-menu-end" style={{}}>
                                                <li><a className="dropdown-item py-2 rounded" href="#" onClick={(e) => { e.preventDefault(); handleOpenAssignRoleModal(); setSelectedEmployee(role); }}>{role?.role ? "Change Role" : "Assign Role"}</a></li>
                                                <li><a className="dropdown-item py-2 rounded text-warning" href="#" onClick={() => { removeProjectMember(role.employee.id) }}>Remove From Project</a></li>
                                            </ul>
                                        </> : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                })
                :
                <>
                    <div className="mb-3" style={{ display: "flex", justifyContent: "center" }}>
                        <RotatingLines
                            strokeColor="#484C7F"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="96"
                            visible={true}
                        />
                    </div>
                </>
        }

    </>
}
export default ProjectRoles