import { useEffect } from "react"
import Person from "../../assets/images/person.png"
import { API_URL_PREFIX } from "../../constants/apiUrl"
import { addRemoveProjectMemberAPI } from "../../services/apiService"
import { ErrorAlert, SuccessAlert } from "../../utils/alerts"
import logout from "../../utils/logout"
const AddEmployeeToProjectModal = ({ openSelectRoleOnAddEmployee, handleCloseEmpToProjectModal, handleReload, employees, alreadyAddedEmployees, project }) => {
    useEffect(() => { }, [employees])


    const addRemoveProjectMember = (employeeId, actionType, emp) => {
       
        console.log(emp);
        let reqData = {
            "project_id": project.id,
            "employee_id": employeeId,
            "action_type": actionType
        }
        console.log(reqData)
        addRemoveProjectMemberAPI(reqData).then(res => {
            console.log(res.data)
            SuccessAlert(res.data.message)
            handleCloseEmpToProjectModal()
            handleReload()
            if(actionType==='add_member'){
                try{
                    openSelectRoleOnAddEmployee(emp);
                }catch(error){
                    console.log('error===============', error);
                }
            }
        }).catch(error => {
            if (error.response) {
                let err = `Unable to fetch projects. error(${error.response.data.message})`
                ErrorAlert(err)
                if (error.response.status === 401) {
                    logout();
                }
            } else {
                ErrorAlert(error.message)
            }
        })
    }
    return <>
        <div className="modal fade show" style={{ display: "block", zIndex: "1000!important;" }} aria-modal="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title  fw-bold text-capitalize" >Add Members To {project.title}</h5>
                        <button type="button" className="btn-close" onClick={handleCloseEmpToProjectModal} />
                    </div>
                    <div className="modal-body">
                        <div className="members_list" style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                            <h6 className="fw-bold ">Employee </h6>
                            <ul className="list-unstyled list-group list-group-custom list-group-flush mb-0">

                                {
                                    employees && employees.map(emp => {
                                        return <li className="list-group-item py-3 text-center text-md-start">
                                            <div className="d-flex align-items-center flex-column flex-sm-column flex-md-column flex-lg-row">
                                                <div className="no-thumbnail mb-2 mb-md-0">
                                                    <img className="avatar lg rounded-circle" src={API_URL_PREFIX + "/media/" + emp.employee__image} alt="" />
                                                </div>
                                                <div className="flex-fill ms-3 text-truncate">
                                                    <h6 className="mb-0  fw-bold text-capitalize">{emp.employee__first_name} {emp.employee__last_name}</h6>
                                                    <span className="text-muted">{emp.employee__email}</span>
                                                </div>
                                                <div className="members-action">
                                                    <span className="members-role text-capitalize">{emp.employee__position}</span>

                                                    {
                                                        alreadyAddedEmployees.filter(employee => employee.id === emp.employee__id).length > 0 ? <span className="small light-success-bg  p-1 rounded" style={{ margin: "20px" }}><i className="icofont-check-circled" /> Already Added</span> : <span className="small light-warning-bg  p-1 rounded" style={{ margin: "20px" }}><i className="icofont-ui-clock" /> Not Added Yet</span>
                                                    }
                                                    <div className="btn-group">
                                                        <button type="button" className="btn bg-transparent dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">

                                                        </button>
                                                        <ul className="dropdown-menu dropdown-menu-end">
                                                            {
                                                                alreadyAddedEmployees.filter(employee => employee.id === emp.employee__id).length > 0 ? <li><a className="dropdown-item" href="" onClick={(e) => { e.preventDefault(); addRemoveProjectMember(emp.employee__id, "remove_member", emp); }}><i className="icofont-ui-delete fs-6 me-2" />Remove Member</a></li> : <li><a className="dropdown-item" href="" onClick={(e) => { e.preventDefault(); addRemoveProjectMember(emp.employee__id, "add_member", emp); }}><i className="icofont-ui-add fs-6 me-2" />Add Member</a></li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                    })
                                }

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>
}
export default AddEmployeeToProjectModal