
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import MessageModal from "../../../components/admin/MessageModal/MessageModal"
import { API_URL_PREFIX } from "../../../constants/apiUrl"
import { RotatingLines } from 'react-loader-spinner'
import { deleteProjectMessageAPI, deleteProjectMessagesAPI, getProjectMessagesAPI } from "../../../services/apiService"
import { ErrorAlert, SuccessAlert } from "../../../utils/alerts"
import moment from 'moment';
import FilePreview from "../../../components/admin/FilePreview/FilePreview"
import EditMessageModal from "../../../components/admin/EditMessageModal/EditMessageModal"
import DeleteItem from "../../../components/DeleteItem/DeleteItem"
import logout from "../../../utils/logout"

const Messages = ({ project }) => {

    const profileData = useSelector((state) => state.user)?.user
    const [messages, setMessages] = useState([])
    const [reload, setReload] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [showUpdateModal, setShowUpdateModal] = useState(false)
    const [showDeleteProjectMessagesConfirmation, setDeleteProjectMessagesConfirmation] = useState(false)
    const [itemSelectedForUpdate, setItemSelectedForUpdate] = useState(null)

    const handleOpenCreateModal = () => setShowCreateModal(true)
    const handleCloseCreateModal = () => setShowCreateModal(false)
    const handleOpenUpdateModal = () => setShowUpdateModal(true)
    const handleCloseUpdateModal = () => setShowUpdateModal(false)
    const handleOpenDeleteProjectMessagesConfirmationModal = () => setDeleteProjectMessagesConfirmation(true)
    const handleCloseDeleteProjectMessagesConfirmationModal = () => setDeleteProjectMessagesConfirmation(false)
    const handleReload = () => setReload(!reload)

    useEffect(() => {
        getProjectMessages()
    }, [project, reload])

    const getProjectMessages = () => {
        setLoading(true)
        getProjectMessagesAPI(project.id).then(res => {
            console.log(res.data.data)
            setMessages(res.data.data)
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            if (error.response) {
                ErrorAlert(error.response.data.message)
                if (error.response.status === 401) {
                    logout();
                }
            } else {
                ErrorAlert(error.message)
            }
        })
    }

    const deleteProjectMessage = (projectId) => {
        console.log(projectId)
        deleteProjectMessageAPI(projectId).then(res => {
            console.log(res.data)
            handleReload()
            SuccessAlert(res.data.message)
        }).catch(error => {
            if (error.response) {
                ErrorAlert(error.response.data.message)
                if (error.response.status === 401) {
                    logout();
                }
            } else {
                ErrorAlert(error.message)
            }
        })
    }
    console.log(profileData)


    const deleteProjectMessages = () => {
        deleteProjectMessagesAPI(project.id).then(res => {
            console.log(res.data)
            handleCloseDeleteProjectMessagesConfirmationModal()
            handleReload()
            SuccessAlert(res.data.message)
        }).catch(error => {
            if (error.response) {
                ErrorAlert(error.response.data.message)
                if (error.response.status === 401) {
                    logout();
                }
            } else {
                ErrorAlert(error.message)
            }
        })
    }

    return <>
        {showCreateModal ? <MessageModal project={project} handleCloseCreateModal={handleCloseCreateModal} handleReload={handleReload} /> : null}
        {showUpdateModal ? <EditMessageModal project={project} message={itemSelectedForUpdate} handleCloseUpdateModal={handleCloseUpdateModal} handleReload={handleReload} /> : null}
        {showDeleteProjectMessagesConfirmation ? <DeleteItem cancelDeleteItem={handleCloseDeleteProjectMessagesConfirmationModal} deleteItem={deleteProjectMessages} item={""} /> : null}
        <div className="body d-flex py-lg-3 py-md-2">
            <div className="container-xxl row justify-content-center">
                <div className="col-8">
                    <div className="card">
                        <div className="card-header py-3 d-flex justify-content-between align-items-center">
                            <div className="info-header">
                                <h6 className="mb-0 fw-bold text-capitalize">{project.title}</h6>
                            </div>
                            <a href="chat.html#" className="nav-link py-2 px-3 text-muted" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v" /></a>

                            <ul className="dropdown-menu border-0 shadow dropdown-menu-end" style={{}}>
                                <li><a className="dropdown-item py-2 rounded" href="project-dashboard.html#">Download All Messages</a></li>
                                <li><a className="dropdown-item py-2 rounded text-warning" href="#" onClick={(e) => { e.preventDefault(); handleOpenDeleteProjectMessagesConfirmationModal(); }}>Delete All Messages</a></li>
                            </ul>
                        </div>
                        <div className="card-body" >
                            {
                                loading ? <>
                                    <div style={{ height: '60vh', overflow: 'auto', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>

                                        <RotatingLines
                                            strokeColor="#484C7F"
                                            strokeWidth="5"
                                            animationDuration="0.75"
                                            width="96"
                                            visible={true}
                                        />
                                    </div>
                                </> :
                                    messages.length ?
                                        <div style={{ height: '60vh', overflow: 'auto' }}>
                                            {
                                                messages.map(message => {
                                                    return <li className="mb-3 d-flex flex-row align-items-end">
                                                        <div>
                                                            <div className="user-info mb-1">
                                                                <img className="avatar sm rounded-circle me-1" src={API_URL_PREFIX + "/media/" + message.send_by.image} alt="avatar" />
                                                                <span className="text-muted small text-capitalize">{message.send_by.first_name} {message.send_by.last_name} {moment(message.created_on).format('h:mm A, MMMM Do')}</span>
                                                                {
                                                                    message.can_delete_or_update ? <div className="btn-group">
                                                                        <a href="chat.html#" className="nav-link py-2 px-3 text-muted" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v" /></a>
                                                                        <ul className="dropdown-menu border-0 shadow" style={{}}>
                                                                            <li><a className="dropdown-item" href="#" onClick={(e) => {
                                                                                setItemSelectedForUpdate(message)
                                                                                handleOpenUpdateModal()
                                                                            }}>Edit</a></li>
                                                                            <li><a className="dropdown-item" href="#" onClick={(e) => {
                                                                                e.preventDefault()
                                                                                deleteProjectMessage(message.id)
                                                                            }}>Delete</a></li>
                                                                        </ul>
                                                                    </div> : null
                                                                }

                                                            </div>

                                                            <div className="card border-0 p-3">
                                                                <div className="message">
                                                                    <p><b>{message.subject}</b></p>
                                                                    <p>{message.body}</p>
                                                                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                                                                        {
                                                                            message.attachments.map(attachment => {
                                                                                return <FilePreview attachment={attachment.attachment} />

                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* More option */}

                                                    </li>

                                                })
                                            }
                                        </div>
                                        :
                                        <div style={{ height: '60vh', overflow: 'auto', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                                            <div>
                                                <h1 className="text-center" style={{ color: "#484C7F", fontSize: "4rem" }}> <i class="icofont-ui-video-message text-center"></i></h1>
                                                <h5 className="text-center">Connect your words to your work.</h5>
                                                <p className="text-center">Send a message to kick off projects. Or discuss tasks. Or brainstorm ideas. You can also send messages from your email.</p>
                                            </div>
                                        </div>
                            }

                            <div>
                                <div className="d-flex align-items-center flex-column flex-sm-column flex-md-column flex-lg-row">
                                    <div className="no-thumbnail mb-2 mb-md-0">
                                        <img className="avatar rounded-circle" src={API_URL_PREFIX + profileData.photo} alt="" />
                                    </div>
                                    <div className="flex-fill ms-3 text-truncate" onClick={()=>{
                                        handleOpenCreateModal()
                                        console.log('hello world')
                                    }}>
                                        <input style={{ cursor: "pointer" }} type={"text"} className="form-control" placeholder="Send message to members..." readOnly={true} />
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </>
}

export default Messages