import { useEffect, useRef, useState } from "react"
import SVG from "../../../assets/images/login-img.svg"
import { RotatingLines } from 'react-loader-spinner'
import { useHistory } from "react-router-dom"
import { sendOtpForTwoFactorAuthenticationAPI } from "../../../services/apiService"
import { ErrorAlert, SuccessAlert } from "../../../utils/alerts"
import { useSelector } from "react-redux"


const TwoFactorAuthentication = () => {
    const userData = useSelector(state => state.user)
    console.log(userData.user.email)
    const twoFacotorAuth = JSON.parse(localStorage.getItem("two_factor"))
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const [otp, setOtp] = useState()
    const char1 = useRef(null)
    const char2 = useRef(null)
    const char3 = useRef(null)
    const char4 = useRef(null)

    const [values, setValues] = useState({
        val1: null,
        val2: null,
        val3: null,
        val4: null
    })

    useEffect(() => {
        if (twoFacotorAuth) {
            sendMailForTwoFactorAuthentication()
        } else {
            history.push('/')
        }
    }, [])

    const updateValues = (e) => {
        let v = {
            ...values,
            [e.target.name]: e.target.value === "" ? null : e.target.value
        }

        setValues(v)
        if (v.val1 === null && char1.current) { char1.current.focus() }
        if (v.val1 !== null && char2.current) { char2.current.focus() }
        if (v.val1 !== null && v.val2 !== null && char3.current) { char3.current.focus() }
        if (v.val1 !== null && v.val2 !== null && v.val3 !== null && char4.current) { char4.current.focus() }

    }

    const sendMailForTwoFactorAuthentication = () => {

        sendOtpForTwoFactorAuthenticationAPI().then(res => {
            setOtp(res.data.otp)
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                ErrorAlert(error.response.data.message);
                if (error.response.status === 401) {
                    ErrorAlert("Please login first!")
                }
            } else if (error.request) {
                ErrorAlert("No response recieved from server.");
            } else if (error.message === "Network Error") {
                ErrorAlert("Network Error: Please check your internet connection");
            } else {
                ErrorAlert(error.message);
            }
        })
    }

    const veriefyOTP = () => {
        let enterOTP = values.val1 + values.val2 + values.val3 + values.val4
        if (enterOTP == otp) {
            localStorage.setItem("two_factor", JSON.stringify(false))
            SuccessAlert("Login successfully!")
            history.push("/employee/")
        } else {
            ErrorAlert("OTP not matched!")
        }
    }

    return <>
        <div className="main p-2 py-3">
            {/* Body: Body */}
            <div className="body d-flex p-0">
                <div className="container-xxl">
                    <div className="row g-0" >
                        <div className="col-lg-6 d-none d-lg-flex justify-content-center align-items-center rounded-lg auth-h100">
                            <div style={{ maxWidth: '25rem' }}>
                                <div className="text-center mb-5">
                                    <svg width="4rem" fill="currentColor" className="bi bi-clipboard-check" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                    </svg>
                                </div>
                                <div className="mb-5">
                                    <h2 className="color-900 text-center">Let's Simplified Project Management.</h2>
                                </div>
                                {/* Image block */}
                                <div className>
                                    <img src={SVG} alt="login-img" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 d-flex justify-content-center align-items-center border-0 rounded-lg auth-h100">
                            <div className="w-100 card border-0 bg-dark text-light" style={{ maxWidth: '32rem' }}>
                                <form className="row g-1 p-3 p-md-4" onSubmit={(e) => { e.preventDefault(); veriefyOTP(); }}>

                                    {
                                        loading ? <div>
                                            <div className="col-12 text-center mb-5  mt-5">

                                                <RotatingLines
                                                    strokeColor="white"
                                                    strokeWidth="5"
                                                    animationDuration="0.75"
                                                    width="96"
                                                    visible={true}
                                                />

                                            </div>
                                        </div> : <div>
                                            <div className="col-12 text-center mb-1 mb-lg-5">
                                                <h1>Two Factor Authentication</h1>
                                                <span>An email with OTP send to your email {userData.user.email} for two factor authentication. Enter OTP bellow for login.</span>
                                            </div>
                                            <div className="col-12">
                                                <div className="mb-2" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

                                                    <input
                                                        style={{ height: "80px", width: "80px", fontSize: "33px", textAlign: "center", margin: "5px" }}
                                                        maxLength="1"
                                                        type="text"
                                                        className="form-control form-control-lg"
                                                        name="val1"
                                                        onChange={(e) => { updateValues(e) }}
                                                        ref={char1}
                                                    />
                                                    <input
                                                        style={{ height: "80px", width: "80px", fontSize: "33px", textAlign: "center", margin: "5px" }}
                                                        maxLength="1"
                                                        type="text"
                                                        className="form-control form-control-lg"
                                                        name="val2"
                                                        onChange={(e) => { updateValues(e) }}
                                                        ref={char2}
                                                    />

                                                    <input
                                                        style={{ height: "80px", width: "80px", fontSize: "33px", textAlign: "center", margin: "5px" }}
                                                        maxLength="1"
                                                        type="text"
                                                        className="form-control form-control-lg"
                                                        name="val3"
                                                        onChange={(e) => { updateValues(e) }}
                                                        ref={char3}
                                                    />

                                                    <input
                                                        style={{ height: "80px", width: "80px", fontSize: "33px", textAlign: "center", margin: "5px" }}
                                                        maxLength="1"
                                                        type="text"
                                                        className="form-control form-control-lg"
                                                        name="val4"
                                                        onChange={(e) => { updateValues(e) }}
                                                        ref={char4}
                                                    />



                                                </div>
                                            </div>

                                            <div className="col-12 text-center mt-4">
                                                <button className="btn btn-lg btn-block btn-light lift text-uppercase" type="submit" >Verify OTP</button>
                                            </div>
                                        </div>
                                    }



                                </form>

                            </div>
                        </div>
                    </div> {/* End Row */}
                </div>
            </div>
        </div>

    </>
}
export default TwoFactorAuthentication