import React from "react";
import moment from "moment";
import { formatSecondsToHoursMinutes } from "../../utils/secondsToHoursMinutes";

const CheckIn = ({checkin}) => {
  const getPinLocation = (check)=>{
    if(check.longitude && check.latitude){
      return <a style={{fontSize:"20px"}} href={`https://maps.google.com/?q=${check.latitude},${check.longitude}`} target={'_blank'}><i className="icofont-google-map"></i></a>
    }
    return "..."
  }
  return <tr>
  <td className=" dt-body-right" > {checkin?.checkin_time && moment(checkin.checkin_time).format('MMMM Do YYYY')} </td>
  <td className=" dt-body-right" > {checkin?.employee && checkin.employee} </td>
  <td className=" dt-body-right" > {checkin?.checkin_time && moment(checkin.checkin_time).format('h:mm:s A')} </td>
  <td className=" dt-body-right" > {checkin?.check_out?.checkout_time && moment(checkin.check_out.checkout_time).format('h:mm:s A')} </td>
  <td className=" dt-body-right" > {checkin?.check_out?.total_time_spend && formatSecondsToHoursMinutes(checkin.check_out.total_time_spend)} </td>
  <td className=" dt-body-right" > {getPinLocation(checkin)} </td>
  <td className=" dt-body-right" > {getPinLocation(checkin.check_out)} </td>
</tr>;
};

export default CheckIn;
