import axios from "axios";
import { getToken } from "../utils/getToken";
import { API_URL_PREFIX } from "../constants/apiUrl"


export const getUserProfileDataAPI = () => {
    return axios.get(`${API_URL_PREFIX}/api/user/profile/`,
        {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        })
}

export const sendEmployeeSignupInvitationAPI = (data) => {
    return axios.post(`${API_URL_PREFIX}/api/project-management/send-employee-signup-invitation/`, data, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    })
}

export const getEmployeeSignupInvitationAPI = (code) => {
    return axios.get(`${API_URL_PREFIX}/api/project-management/get-employee-signup-invitation/invitation-code=${code}`,)
}

export const employeeAccountSignupAPI = (data) => {
    return axios.post(`${API_URL_PREFIX}/api/project-management/employee-account-signup/`, data,)
}


export const employeeAccountActivationAPI = (parameters) => {
    return axios.get(`${API_URL_PREFIX}/api/project-management/employee-account-activate${parameters}`,)
}

export const employeeLoginAPI = (data) => {
    return axios.post(`${API_URL_PREFIX}/api/token/`, data,)
}

export const refreshLoginTokenAPI = (data) => {
    return axios.post(`${API_URL_PREFIX}/api/token/refresh/`, data,)
}


export const sendOtpForTwoFactorAuthenticationAPI = () => {
    return axios.get(`${API_URL_PREFIX}/api/user/send-login-otp/`, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    })
}

export const createProjectAPI = (data) => {
    return axios.post(`${API_URL_PREFIX}/api/project-management/project/`, data, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    })
}


export const updateProjectAPI = (id, data) => {
    return axios.put(`${API_URL_PREFIX}/api/project-management/update-project/${id}`, data, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    })
}


export const deleteProjectAPI = (id) => {
    return axios.delete(`${API_URL_PREFIX}/api/project-management/project/${id}`, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    })
}


export const deleteEmployeeAPI = (data) => {
    return axios.post(`${API_URL_PREFIX}/api/project-management/delete-employee-permanently/`, data, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    })
}


export const getAllProjectsAPI = () => {
    return axios.get(`${API_URL_PREFIX}/api/project-management/all-projects/`, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    })
}


export const getProjectAPI = (projectId) => {
    return axios.get(`${API_URL_PREFIX}/api/project-management/get-project/${projectId}`, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    })
}




export const addRemoveProjectMemberAPI = (data) => {
    return axios.post(`${API_URL_PREFIX}/api/project-management/add-remove-project-member/`, data, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    })
}


export const createProjectTaskAPI = (data) => {
    return axios.post(`${API_URL_PREFIX}/api/project-management/project-task/`, data, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    })
}


export const getProjectAllTasksAPI = (projectId) => {
    return axios.get(`${API_URL_PREFIX}/api/project-management/project-all-tasks/${projectId}`, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    })
}


export const deleteProjectTaskAPI = (taskId) => {
    return axios.delete(`${API_URL_PREFIX}/api/project-management/delete-project-task/${taskId}`, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    })
}



export const updateProjectTaskAPI = (id, data) => {
    return axios.put(`${API_URL_PREFIX}/api/project-management/update-project-task/${id}`, data, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    })
}


export const createProjectTaskStatusAPI = (data) => {
    return axios.post(`${API_URL_PREFIX}/api/project-management/project-task-status/`, data, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    })
}




export const createProjectMessageAPI = (data) => {
    return axios.post(`${API_URL_PREFIX}/api/project-management/project-message/`, data, {
        headers: {
            Authorization: `Bearer ${getToken()}`,
            'Content-Type': 'multipart/form-data'
        }
    })
}


export const getProjectMessagesAPI = (projectId) => {
    return axios.get(`${API_URL_PREFIX}/api/project-management/project-messages/${projectId}`, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    })
}

export const getMessagesAPI = () => {
    return axios.get(`${API_URL_PREFIX}/api/project-management/messages/`, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    })
}


export const downloadAttachmentAPI = (attachment) => {
    console.log(`${API_URL_PREFIX}/media/${attachment}`)
    return axios.get(`${API_URL_PREFIX}/media/${attachment}`, { responseType: 'blob' }, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    })
}



export const deleteProjectMessageAPI = (messageId) => {
    return axios.delete(`${API_URL_PREFIX}/api/project-management/project-message/${messageId}`, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    })
}


export const updateProjectMessageAPI = (id, data) => {
    return axios.put(`${API_URL_PREFIX}/api/project-management/project-message/${id}`, data, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    })
}


export const deleteProjectMessagesAPI = (projectId) => {
    return axios.delete(`${API_URL_PREFIX}/api/project-management/delete-project-messages/${projectId}`, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    })
}


export const uploadProjectResourceAPI = (data) => {
    return axios.post(`${API_URL_PREFIX}/api/project-management/project-resource/`, data, {
        headers: {
            Authorization: `Bearer ${getToken()}`,
            'Content-Type': 'multipart/form-data'
        }
    })
}


export const getProjectResourcesAPI = (projectId) => {
    return axios.get(`${API_URL_PREFIX}/api/project-management/project-resources/${projectId}`, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    })
}


export const deleteProjectResourceAPI = (resourceId) => {
    return axios.delete(`${API_URL_PREFIX}/api/project-management/project-resource/${resourceId}`, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    })
}


export const getProjectEmployeeRolesAPI = (projectId) => {
    return axios.get(`${API_URL_PREFIX}/api/project-management/project-employee-roles/${projectId}`, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    })
}


export const assignProjectEmployeeRoleAPI = (data) => {
    return axios.post(`${API_URL_PREFIX}/api/project-management/assign-project-employee-role/`, data, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    })
}



// EMPLOYEE API'S


export const getEmployeeAllProjectsAPI = () => {
    return axios.get(`${API_URL_PREFIX}/api/project-management/employee-all-projects/`, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    })
}


export const getEmployeeProjectAPI = (projectId) => {
    return axios.get(`${API_URL_PREFIX}/api/project-management/get-employee-project/${projectId}`, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    })
}

export const getProjectEmployeeTasksAPI = (projectId) => {
    return axios.get(`${API_URL_PREFIX}/api/project-management/project-employee-tasks/${projectId}`, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    })
}


export const getAllEmployeesAPI = (projectId) => {
    return axios.get(`${API_URL_PREFIX}/api/project-management/all-employees/`, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    })
}


export const todayCheckInCheckOutAPI = () => {
    return axios.get(`${API_URL_PREFIX}/api/project-management/get-today-checkin-checkout-details/`, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    })
}

export const todayCheckInAPI = (data) => {
    return axios.post(`${API_URL_PREFIX}/api/project-management/today-checkin/`, data, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    })
}

export const todayCheckOutAPI = (data) => {
    return axios.post(`${API_URL_PREFIX}/api/project-management/today-checkout/`, data, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    })
}

export const getCheckInCheckOutsAPI = () => {
    return axios.get(`${API_URL_PREFIX}/api/project-management/checkins-checkouts/`, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    })
}


export const getEmployeeAllTasksAPI = () => {
    return axios.get(`${API_URL_PREFIX}/api/project-management/employee-all-tasks/`, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    })
}


export const createTaskDocumentationAPI = (data) => {
    return axios.post(`${API_URL_PREFIX}/api/project-management/project-task-documentation/`, data, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    })
}

export const getTasksDocumentationsAPI = () => {
    return axios.get(`${API_URL_PREFIX}/api/project-management/all-project-task-documentations/`, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    })
}

export const updateTaskDocumentationAPI = (data, docId) => {
    return axios.put(`${API_URL_PREFIX}/api/project-management/project-task-documentation/${docId}/`, data, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    })
}


export const deleteTaskDocumentationAPI = (docId) => {
    return axios.delete(`${API_URL_PREFIX}/api/project-management/project-task-documentation/${docId}/`, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    })
}



export const employeeDashboardStatsAPI = () => {
    return axios.get(`${API_URL_PREFIX}/api/project-management/dashboard-stats/`, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    })
}