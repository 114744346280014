import { useEffect, useState } from "react"
import { deleteProjectResourceAPI, getProjectResourcesAPI } from "../../services/apiService"
import { ErrorAlert, SuccessAlert } from "../../utils/alerts"
import FilePreview from "../admin/FilePreview/FilePreview"
import UploadProjectResourcesModal from "../UploadProjectResourcesModal/UploadProjectResourcesModal"
import { RotatingLines } from 'react-loader-spinner'
import { useSelector } from "react-redux"
import logout from "../../utils/logout"

const ProjectResources = ({ project }) => {

    const profileData = useSelector((state) => state.user)?.user
    console.log(profileData)
    const [resources, setResources] = useState([])
    const [loading, setLoading] = useState(false)
    const [reload, setReload] = useState(false)
    const [showUploadResourcesModal, setShowUploadResourcesModal] = useState(false)
    const handleShowUploadResourcesModal = () => setShowUploadResourcesModal(true)
    const handleCloseUploadResourcesModal = () => setShowUploadResourcesModal(false)
    const handleReload = () => setReload(!reload)

    useEffect(() => {
        if (project?.id) {
            getProjectResources()
        }
    }, [project, reload])

    const getProjectResources = () => {
        setLoading(true)
        getProjectResourcesAPI(project.id).then(res => {
            console.log(res.data)
            setResources(res.data.data)
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            if (error.response) {
                ErrorAlert(error.response.data.message)
                if (error.response.status === 401) {
                    logout();
                }
            } else {
                ErrorAlert(error.message)
            }
        })
    }


    const deleteProjectResource = (resourceId) => {
        setLoading(true)
        deleteProjectResourceAPI(resourceId).then(res => {

            SuccessAlert(res.data.message)
            handleReload()
            setLoading(false)

        }).catch(error => {
            setLoading(false)
            if (error.response) {
                ErrorAlert(error.response.data.message)
                if (error.response.status === 401) {
                    logout();
                }
            } else {
                ErrorAlert(error.message)
            }
        })
    }


    return <>
        {showUploadResourcesModal ? <UploadProjectResourcesModal handleCloseUploadResourcesModal={handleCloseUploadResourcesModal} handleReload={handleReload} project={project} /> : null}
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h4>Project Resources</h4>
            {
                profileData.role === "admin" ? <div className="col-md-12 col-lg-1 col-xl-1 col-xxl-1">
                    <div className="" style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="">
                            <div className="d-flex align-items-center">
                                <div onClick={handleShowUploadResourcesModal} style={{ borderStyle: 'dotted', cursor: 'pointer' }} className="avatar lg  rounded-1 no-thumbnail color-defult">
                                    <i class="icofont-ui-add"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : null
            }
        </div>
        {
            !loading ?
                resources.map(resource => {
                    return <div key={resource.id} className="col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                        <div className="card ">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div className="avatar lg  rounded-1 no-thumbnail color-defult">
                                        <FilePreview attachment={resource.resource} />
                                    </div>
                                    <div className="flex-fill ms-4">


                                        <div className>{resource.resource.split('/').pop().split('.').shift().slice(0, 20)}</div>
                                        <h6 className="mb-0 text-uppercase">{resource.resource.split('.').pop()}</h6>

                                    </div>
                                    {
                                        profileData.role === "admin" ? <>
                                            <a href="chat.html#" className="nav-link py-2 px-3 text-muted" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v" style={{ color: 'black', fontSize: '20px' }} /></a>

                                            <ul className="dropdown-menu border-0 shadow dropdown-menu-end" style={{}}>
                                                <li><a className="dropdown-item py-2 rounded text-warning" href="#" onClick={() => { deleteProjectResource(resource.id) }}>Delete Resource</a></li>
                                            </ul>
                                        </> : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                })
                : <>
                    <div className="mb-3" style={{ display: "flex", justifyContent: "center" }}>
                        <RotatingLines
                            strokeColor="#484C7F"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="96"
                            visible={true}
                        />
                    </div>
                </>
        }

    </>
}

export default ProjectResources