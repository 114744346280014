import React from "react";
import moment from "moment";

const TaskDocumentation = ({doc, setSelectedDoc, handleDeleteDoc}) => {
  return <tr>
  <td className=" dt-body-right"> {moment(doc.created_at).format('MMMM Do YYYY')} </td>
  <td className=" dt-body-right"> {doc.employee} </td>
  <td className=" dt-body-right"> {doc.task} </td>
  <td className=" dt-body-right"> {moment(doc.created_at).format('h:mm:s A')} </td>
  <td className=" dt-body-right"> <a href="#" onClick={(e)=>{e.preventDefault(); setSelectedDoc(doc);}}><i className="icofont-edit"></i></a> <a className="pl-3" href="#" onClick={(e)=>{e.preventDefault(); handleDeleteDoc(doc);}}><i className="icofont-bin"></i></a>  </td>
</tr>
};

export default TaskDocumentation;
