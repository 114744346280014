import React, { useEffect, useState } from "react"
import CreateModal from "../CreateModal/CreateModal";
import ProjectsSubMenu from "../ProjectsSubMenu/ProjectsSubMenu";
import { useHistory } from "react-router";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Sidebar = ({reload}) => {

    const history = useHistory()
    const [showCreateModal, setShowCreateModal] = useState(false)
    const handleOpenCreateModal = () => setShowCreateModal(true)
    const handleCloseCreateModal = () => setShowCreateModal(false)



    return (
      <>
        {/* sidebar */}
        {showCreateModal ? (
          <CreateModal handleCloseCreateModal={handleCloseCreateModal} />
        ) : (
          <></>
        )}

        <div
          className="sidebar px-4 py-4 py-md-5 me-0"
          style={{ zIndex: '999!important;' }}
        >
          <div className="d-flex flex-column h-100">
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                history.push('/admin/dashboard/');
              }}
              className="mb-0 brand-icon"
            >
              <span className="logo-icon">
                <svg
                  width={35}
                  height={35}
                  fill="currentColor"
                  className="bi bi-clipboard-check"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                  />
                  <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                  <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                </svg>
              </span>
              <span
                onClick={(e) => {
                  e.preventDefault();
                  history.push('/admin/dashboard/');
                }}
                className="logo-text"
              >
                Officehassle PM
              </span>
            </a>
            {/* Menu: main ul */}

            <ul className="menu-list flex-grow-1 mt-3">
              <li className="text-center">
                <button
                  class="avatar rounded-circle text-center pointer"
                  style={{ borderStyle: 'none' }}
                  onClick={handleOpenCreateModal}
                >
                  <i class="icofont-ui-add" style={{ color: '#484C7F' }}></i>
                </button>
              </li>
              <li>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    history.push('/admin/dashboard/');
                  }}
                  className="m-link"
                  href="index.html#"
                >
                  <i className="icofont-home" />
                  <span>Home</span>
                </a>
              </li>
              <li>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    history.push('/admin/inbox/');
                  }}
                  className="m-link"
                  href="index.html#"
                >
                  <i className="icofont-alarm" />
                  <span>Inbox</span>
                </a>
              </li>

              <li className="collapsed">
                <a
                  className="m-link"
                  data-bs-toggle="collapse"
                  data-bs-target="#project-Components"
                  href="index.html#"
                >
                  <i className="icofont-briefcase" />
                  <span>Projects</span>{' '}
                  <span className="arrow icofont-dotted-down ms-auto text-end fs-5" />
                </a>
                {/* Menu: Sub menu ul */}
                <ProjectsSubMenu
                  showCreateModal={showCreateModal}
                  reload={reload}
                />
              </li>

              <li className="collapsed">
                <Link className="m-link" to="/admin/checkins/">
                  <i className="icofont-foot-print" /> <span>Check Ins</span>{' '}
                </Link>
              </li>
              <li className="collapsed">
                <Link className="m-link" to="/admin/taskDocumentation/">
                  <i className="icofont-ticket" />{' '}
                  <span>Task Documentation</span>{' '}
                </Link>
              </li>
            </ul>
            {/* Theme: Switch Theme */}
            <ul className="list-unstyled mb-0">
              <li className="d-flex align-items-center justify-content-center">
                <div className="form-check form-switch theme-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="theme-switch"
                  />
                  <label className="form-check-label" htmlFor="theme-switch">
                    Enable Dark Mode!
                  </label>
                </div>
              </li>
              <li className="d-flex align-items-center justify-content-center">
                <div className="form-check form-switch theme-rtl">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="theme-rtl"
                  />
                  <label className="form-check-label" htmlFor="theme-rtl">
                    Enable RTL Mode!
                  </label>
                </div>
              </li>
            </ul>
            {/* Menu: menu collepce btn */}
            <button
              type="button"
              className="btn btn-link sidebar-mini-btn text-light"
            >
              <span className="ms-2">
                <i className="icofont-bubble-right" />
              </span>
            </button>
          </div>
        </div>
      </>
    );
}
export default Sidebar