import { useEffect, useState } from "react"
import { deleteProjectTaskAPI, getProjectAllTasksAPI, getProjectEmployeeTasksAPI } from "../../../services/apiService"
import { ErrorAlert, SuccessAlert } from "../../../utils/alerts"
import { RotatingLines } from 'react-loader-spinner'
import Task from "../Task/Task"
import logout from "../../../utils/logout"

const TasksList = ({ project, taskCreated, filter }) => {
    const PAGE_SIZE = 2

    const [projectAllTasks, setProjectAllTasks] = useState([])
    const [projectTasks, setProjectTasks] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [loading, setLoading] = useState(false)
    const [taskUpdated, setTaskUpdated] = useState(false)
    const handleTaskUpdated = () => { setTaskUpdated(!taskUpdated) }



    useEffect(() => {
        if (project?.id) {
            getProjectEmployeeTasks(project.id)
        }
    }, [project, taskUpdated, filter])

    const getProjectEmployeeTasks = (projectId) => {
        setLoading(true)
        getProjectEmployeeTasksAPI(projectId).then(res => {
            console.log(res.data.data)
            if (filter === "pending" || filter === "completed" || filter === "in progress") {
                let tasks = res.data.data.filter(t => t.status.status === filter)
                handleRenderTasks(tasks)
            } else {
                let tasks = res.data.data
                handleRenderTasks(tasks)
            }
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                let err = `Unable to fetch project tasks. error(${error.response.data.message})`
                ErrorAlert(err)
                if (error.response.status === 401) {
                    logout();
                }
            } else {
                ErrorAlert(error.message)
            }
        })
    }


    const handleRenderTasks = (tasks) => {
        setProjectAllTasks(tasks)
        setProjectTasks(tasks.slice(0, PAGE_SIZE))
        setCurrentPage(1)
        setTotalPages(Math.ceil(tasks.length / PAGE_SIZE))
    }


    const handleNextPage = () => {
        if (currentPage !== totalPages) {
            let lastIdx = (currentPage + 1) * PAGE_SIZE
            let startIdx = lastIdx - PAGE_SIZE
            let visibleTasks = projectAllTasks.slice(startIdx, lastIdx)
            setProjectTasks(visibleTasks)
            setCurrentPage(currentPage + 1)
        }
    }

    const handlePrevPage = () => {
        if (currentPage !== 1) {
            let lastIdx = (currentPage - 1) * PAGE_SIZE
            let startIdx = lastIdx - PAGE_SIZE
            let visibleTasks = projectAllTasks.slice(startIdx, lastIdx)
            setProjectTasks(visibleTasks)
            setCurrentPage(currentPage - 1)
        }
    }


    return <>

        {
            loading ? (
                <div className="col-12 text-center mb-5  mt-5">

                    <RotatingLines
                        strokeColor="#484C7F"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="96"
                        visible={true}
                    />

                </div>
            ) : null
        }
        {
            !loading && projectTasks.length > 0 ? <div className="row clearfix g-3">


                <div className="col-sm-12">
                    <div className="card mb-3">
                        <div className="card-body">
                            <div id="myProjectTable_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <table id="myProjectTable" className="table table-hover align-middle mb-0 nowrap dataTable no-footer dtr-inline" style={{ width: '100%' }} role="grid" aria-describedby="myProjectTable_info">
                                            <thead>
                                                <tr role="row">
                                                    <th className="sorting" tabIndex={0} aria-controls="myProjectTable" rowSpan={1} colSpan={1} style={{ width: '5%' }} ></th>
                                                    <th className="sorting" tabIndex={0} aria-controls="myProjectTable" rowSpan={1} colSpan={1} style={{ width: '5%' }} ></th>
                                                    <th className="sorting" tabIndex={0} aria-controls="myProjectTable" rowSpan={1} colSpan={1} style={{ width: '30%' }} aria-label="Subject: activate to sort column ascending">Title</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="myProjectTable" rowSpan={1} colSpan={1} style={{ width: '20%' }} aria-label="Assigned: activate to sort column ascending">Assigned To</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="myProjectTable" rowSpan={1} colSpan={1} style={{ width: '20%' }} aria-label="Status: activate to sort column ascending">Start Date</th>
                                                    <th className="dt-body-right sorting" tabIndex={0} aria-controls="myProjectTable" rowSpan={1} colSpan={1} style={{ width: '20%' }} aria-label="Created Date: activate to sort column ascending">Due Date</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    projectTasks.map(task => {
                                                        return <Task key={task.id} task={task} handleTaskUpdated={handleTaskUpdated} />

                                                    })
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="row" style={{ marginTop: "20px" }}>
                                    <div className="col-sm-12 col-md-5">
                                        <div className="dataTables_info" id="myProjectTable_info" role="status" aria-live="polite">Showing {currentPage} of {totalPages} pages</div>
                                    </div>
                                    <div className="col-sm-12 col-md-7">
                                        <div className="dataTables_paginate paging_simple_numbers" id="myProjectTable_paginate">
                                            <ul className="pagination" style={{ float: "right" }}>
                                                <li className="paginate_button page-item previous " onClick={(e) => { e.preventDefault(); handlePrevPage(); }} >
                                                    <a href="#" className="page-link">Previous</a>
                                                </li>
                                                <li className="paginate_button page-item active">
                                                    <a href="" className="page-link text-light" onClick={(e) => { e.preventDefault(); }}>{currentPage}</a>
                                                </li>
                                                <li className="paginate_button page-item next " onClick={(e) => { e.preventDefault(); handleNextPage(); }}>
                                                    <a href="" className="page-link">Next</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : null
        }

    </>
}
export default TasksList