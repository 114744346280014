
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { getEmployeeProjectAPI, getProjectAPI, updateProjectAPI } from "../../../services/apiService"
import { ErrorAlert, SuccessAlert } from "../../../utils/alerts"
import { API_URL_PREFIX } from "../../../constants/apiUrl"
import Tasks from "../../employee/Tasks/Tasks"
import Messages from "../../admin/Messages/Messages"
import Overview from "../Overview/Overview"
import Calendar from "../Calendar/Calendar"
import Board from "../../admin/Board/Board"
import logout from "../../../utils/logout"
const Project = () => {

    let { projectId } = useParams()
    const [project, setProject] = useState({})
    const [currentTab, setCurrentTab] = useState("OverviewTab")


    useEffect(() => {
        getEmployeeProject()
        return () => {
            setCurrentTab("OverviewTab")
        }
    }, [projectId])

    const getEmployeeProject = () => {
        getEmployeeProjectAPI(projectId).then(res => {
            setProject(res.data.data)
            console.log(res.data.data)
        }).catch(error => {
            if (error.response) {
                let err = `Unable to fetch projects. error(${error.response.data.message})`
                ErrorAlert(err)
                if (error.response.status === 401) {
                    logout();
                }
            } else {
                ErrorAlert(error.message)
            }
        })
    }

    
    return <>

        <div>
            <div className="header">
                <nav className="navbar py-4">
                    <div className="container-xxl">

                        <div className="h-right d-flex align-items-center mr-5 mr-lg-0 order-1">
                            <div className="d-flex">

                                <div className="avatar-list avatar-list-stacked px-3">
                                    {
                                        project?.added_employees && project.added_employees.map(employee => {
                                            return <img key={employee.id} className="avatar rounded-circle" src={API_URL_PREFIX + "/media/" + employee.image} alt="" />
                                        })
                                    }
                                </div>
                            </div>

                        </div>

                        <div className="order-0 col-lg-8 col-md-8 col-sm-12 col-12 mb-3 mb-md-0 ">
                            <div className="input-group flex-nowrap input-group-lg">
                                <h3 className="text-capitalize"><i class="icofont-briefcase "></i> {project?.title}</h3>
                                <div >
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
            <ul className="nav nav-tabs tab-card" role="tablist">
                <li className="nav-item"><a className={currentTab === "OverviewTab" ? "nav-link active" : "nav-link"} style={{ cursor: "pointer" }} onClick={() => { setCurrentTab("OverviewTab") }}>Overview</a></li>
                <li className="nav-item"><a className={currentTab === "ListTab" ? "nav-link active" : "nav-link"} style={{ cursor: "pointer" }} onClick={() => { setCurrentTab("ListTab") }}>List</a></li>
                <li className="nav-item"><a className={currentTab === "BoardTab" ? "nav-link active" : "nav-link"} style={{ cursor: "pointer" }} onClick={() => { setCurrentTab("BoardTab") }}>Board</a></li>
                <li className="nav-item"><a className={currentTab === "TimelineTab" ? "nav-link active" : "nav-link"} style={{ cursor: "pointer" }} onClick={() => { setCurrentTab("TimelineTab") }}>Timeline</a></li>
                <li className="nav-item"><a className={currentTab === "CalendarTab" ? "nav-link active" : "nav-link"} style={{ cursor: "pointer" }} onClick={() => { setCurrentTab("CalendarTab") }}>Calendar</a></li>
                <li className="nav-item"><a className={currentTab === "MessagesTab" ? "nav-link active" : "nav-link"} style={{ cursor: "pointer" }} onClick={() => { setCurrentTab("MessagesTab") }}>Messages</a></li>
                <li className="nav-item"><a className={currentTab === "AttachmentsTab" ? "nav-link active" : "nav-link"} style={{ cursor: "pointer" }} onClick={() => { setCurrentTab("AttachmentsTab") }}>Attachments</a></li>
            </ul>
            <div className="tab-content mt-2">
                <div className="tab-pane fade show active" id="nav-Preview6" role="tabpanel">

                </div>
                <div className="tab-pane fade" id="nav-HTML6" role="tabpanel">

                </div>
            </div>
        </div>

        {currentTab === "OverviewTab" ?  <Overview project={project} /> : null}
        {currentTab === "ListTab" ? <Tasks project={project} /> : <></>}
        {currentTab === "BoardTab" ? <Board project={project} /> : null}
        {currentTab === "CalendarTab" ? <Calendar project={project} /> : <></>}
        {currentTab === "MessagesTab" ?  < Messages project={project} /> : null}

    </>
}
export default Project