import SVG from "../../../assets/images/login-img.svg"
import { useFormik } from 'formik'
import { employeeLoginAPI, refreshLoginTokenAPI, getUserProfileDataAPI } from "../../../services/apiService"
import { ErrorAlert, SuccessAlert } from "../../../utils/alerts"
import { useHistory } from 'react-router-dom'
import { useDispatch } from "react-redux"
import { setUser } from "../../../redux/actions/appActions"
import { useEffect } from "react"
import logout from "../../../utils/logout"

const Login = () => {

    const history = useHistory()
    let dispatch = useDispatch()

    const validate = values => {
        const errors = {};

        if (!values.email) {
            errors.email = '*Email required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = '*Invalid email address'
        }

        if (!values.password) {
            errors.password = '*Password required';
        }

        return errors
    }

    const loginForm = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validate,
        onSubmit: values => {
            console.log(values)
            employeeLogin(values)
        },
    })

    const employeeLogin = (data) => {
        employeeLoginAPI(data).then(res => {
            console.log(res.data)
            refreshLoginToken({ "refresh": res.data.refresh })
        }).catch(error => {
            if (error.response) {
                ErrorAlert(error.response.data.message)
                if (error.response.status === 401) {
                   //
                }
            } else {
                ErrorAlert(error.message)
            }
        })
    }

    const refreshLoginToken = (data) => {
        refreshLoginTokenAPI(data).then(res => {
            console.log(res.data)
            localStorage.setItem("token", res.data.access)
            getUserProfileData(res.data.access)
        }).catch(error => {
            if (error.response) {
                ErrorAlert(error.response.data.message)
                if (error.response.status === 401) {
                   logout();
                }
            } else {
                ErrorAlert(error.message)
            }
        })
    }


    const getUserProfileData = () => {
        getUserProfileDataAPI().then(res => {
            console.log(res.data)
            const resp = res.data
            let twoFactor = resp.two_factor_auth
            localStorage.setItem("two_factor", JSON.stringify(twoFactor))
            dispatch(setUser(resp))
            if (!twoFactor) {
                SuccessAlert("Login successfully!")
            }
            history.push("/employee/")

        }).catch(error => {
            if (error.response) {
                ErrorAlert(error.response.data.message)
                if (error.response.status === 401) {
                    logout();
                }
            } else {
                ErrorAlert(error.message)
            }
        })
    }

    return <>
        <div className="main p-2 py-3">
            {/* Body: Body */}
            <div className="body d-flex p-0">
                <div className="container-xxl">
                    <div className="row g-0">
                        <div className="col-lg-6 d-none d-lg-flex justify-content-center align-items-center rounded-lg auth-h100">
                            <div style={{ maxWidth: '25rem' }}>
                                <div className="text-center mb-5">
                                    <svg width="4rem" fill="currentColor" className="bi bi-clipboard-check" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                    </svg>
                                </div>
                                <div className="mb-5">
                                    <h2 className="color-900 text-center">Let's Simplified Project Management.</h2>
                                </div>
                                {/* Image block */}
                                <div className>
                                    <img src={SVG} alt="login-img" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 d-flex justify-content-center align-items-center border-0 rounded-lg auth-h100">
                            <div className="w-100 card border-0 bg-dark text-light" style={{ maxWidth: '32rem' }}>
                                <form className="row g-1 p-3 p-md-4" onSubmit={(e) => { e.preventDefault(); loginForm.handleSubmit(); }}>
                                    <div className="col-12 text-center mb-1 mb-lg-5">
                                        <h1>Employee Login</h1>
                                        <span>Keep track of your projects and tasks with ease.</span>
                                    </div>
                                    <div className="col-12">
                                        <div className="mb-2">
                                            <label className="form-label">Email address</label>
                                            <input
                                                type="email"
                                                className="form-control form-control-lg"
                                                placeholder="johndoe@example.com"
                                                name="email"
                                                onChange={loginForm.handleChange}
                                                value={loginForm.values.email}
                                                autoComplete="off"
                                            />
                                            {loginForm.errors.email ? <div className="text-secondary">{loginForm.errors.email}</div> : null}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="mb-2">
                                            <div className="form-label">
                                                <span className="d-flex justify-content-between align-items-center">
                                                    Password
                                                    {/* <a className="text-secondary" href="auth-password-reset.html">Forgot Password?</a> */}
                                                </span>
                                            </div>
                                            <input type="password" name="password" hidden />
                                            <input
                                                type="password"
                                                className="form-control form-control-lg"
                                                placeholder="***************"
                                                name="password"
                                                onChange={loginForm.handleChange}
                                                value={loginForm.values.password}
                                                autoComplete="new-password"
                                            />
                                            {loginForm.errors.password ? <div className="text-secondary">{loginForm.errors.password}</div> : null}
                                        </div>
                                    </div>
                                    <div className="col-12 text-center mt-4">
                                        <button className="btn btn-lg btn-block btn-light lift text-uppercase" type="submit">Login</button>
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div> {/* End Row */}
                </div>
            </div>
        </div>


    </>
}
export default Login