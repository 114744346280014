
import { useEffect, useState } from "react"
import { API_URL_PREFIX } from "../../../constants/apiUrl"
import { useFormik } from "formik"
import { createProjectMessageAPI, getAllEmployeesAPI } from "../../../services/apiService"
import { ErrorAlert, SuccessAlert } from "../../../utils/alerts"
import { RotatingLines } from 'react-loader-spinner'
import { useSelector } from "react-redux"
import logout from "../../../utils/logout"


const MessageModal = ({ project, handleCloseCreateModal, handleReload }) => {

    const profileData = useSelector((state) => state.user)?.user
    const [allEmployees, setAllEmployees] = useState([])
    const [recipients, setRecipients] = useState([])
    const [attachments, setAttachments] = useState([])
    const [loading, setLoading] = useState(false)

    console.log(profileData, project)
    useEffect(() => {
        if (!project) {
            getAllEmployees()
        } else {
            setAllEmployees(project?.added_employees ? project.added_employees : [])
        }
    }, [project])


    const getAllEmployees = () => {
        setLoading(true)
        getAllEmployeesAPI().then(res => {
            console.log(res.data.data, 'here is my employees data')
            setAllEmployees(res.data.data)
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            if (error.response) {
                ErrorAlert(error.response.data.message)
                if (error.response.status === 401) {
                    logout();
                }
            } else {
                ErrorAlert(error.message)
            }
        })
    }


    const validate = values => {
        const errors = {}


        if (!values.subject) {
            errors.subject = '*Message subject required'
        } else if (values.subject.length < 5) {
            errors.subject = '*Message subject must be greater than 5 characters'
        }

        if (values.recipients.length <= 0) {
            errors.recipients = '*Recipients required'
        }

        if (!values.body) {
            errors.body = '*Message required';
        } else if (values.body.trim().length <= 0) {
            errors.body = '*Message required'
        }

        return errors
    }

    const messageForm = useFormik({
        initialValues: {
            project: project ? project.id : null,
            type: project ? 'project message' : 'personal message',
            subject: '',
            recipients: [],
            body: '',
            send_email: false,
            attachments: [],
        },
        validate,
        onSubmit: values => {
            let reqData = {
                ...values,
                recipients: values.recipients.map(rec => rec.id)
            }
            console.log(reqData)
            createProjectMessage(reqData)
        },
    })


    const objectToFormData = (obj) => {
        const formData = new FormData();
        Object.keys(obj).forEach(key => {
            if (key === 'attachments') {
                obj[key].forEach(attachment => {
                    formData.append('attachments', attachment);
                });
            } else {
                formData.append(key, obj[key]);
            }
        });
        return formData;
    }


    const createProjectMessage = (data) => {
        setLoading(true)
        let formData = objectToFormData(data)
        createProjectMessageAPI(formData).then(res => {
            console.log(res)
            SuccessAlert(res.data.message)
            setLoading(false)
            handleReload()
            handleCloseCreateModal()
        }).catch(error => {
            setLoading(false)
            if (error.response) {
                ErrorAlert(error.response.data.message)
                if (error.response.status === 401) {
                    logout();
                }
            } else {
                ErrorAlert(error.message)
            }
        })
    }

    const deleteRecipient = (recId)=>{
        setRecipients(recipients.filter(rec => rec.id !== recId));
    }

    return <>
        <div className="modal fade show" style={{ display: "block", zIndex: "1000!important;" }} aria-modal="true">
            <div className="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">


                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title  fw-bold" >Create New Message</h5>
                        <button type="button" className="btn-close" onClick={handleCloseCreateModal} />
                    </div>
                    {
                        loading ? <>
                            <div className="modal-body">
                                <div className="mb-3" style={{ display: "flex", justifyContent: "center" }}>
                                    <RotatingLines
                                        strokeColor="#484C7F"
                                        strokeWidth="5"
                                        animationDuration="0.75"
                                        width="96"
                                        visible={true}
                                    />
                                </div>
                            </div>

                        </> : <>

                            <div className="modal-body">
                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlInput77" className="form-label">Message Subject</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Write message subject"
                                        name="subject"
                                        onChange={messageForm.handleChange}
                                        value={messageForm.values.subject}
                                    />
                                    {messageForm.errors.subject ? <div className="text-secondary">{messageForm.errors.subject}</div> : null}
                                </div>


                                <div className="deadline-form">
                                    <form>
                                        <div className="row g-3 mb-3">
                                            <div className="col">
                                                <label htmlFor="datepickerded" className="form-label">Recipients</label>
                                                <select className="form-select text-capitalize"
                                                    onChange={(e) => {
                                                        if (e.target.value !== "") {
                                                            let selected = JSON.parse(e.target.value)
                                                            console.log(selected)
                                                            setRecipients([...recipients, selected])
                                                            messageForm.setFieldValue('recipients', [...recipients, selected])
                                                        }

                                                    }}>
                                                    <option value="" selected> {recipients.length ? recipients.map(rec => `${rec.first_name} ${rec.last_name}, `) : "Select Recipient"}</option>

                                                    {
                                                        allEmployees.map(emp => {
                                                            if (emp.email !== profileData.email) {
                                                                return recipients.find(rec => rec.id === emp.id) ? null : <option className="text-capitalize" key={emp.id} value={JSON.stringify(emp)}>{emp.first_name} {emp.last_name}</option>
                                                            }
                                                        })
                                                    }


                                                </select>
                                                {messageForm.errors.recipients ? <div className="text-secondary">{messageForm.errors.recipients}</div> : null}
                                            </div>
                                            <div className="col">
                                                <label htmlFor="datepickerded" className="form-label">&nbsp;</label>
                                                <div className=" px-3">
                                                    {
                                                        recipients.map(rec => <div style={{display:'flex'}}> <p>{rec.first_name} {rec.last_name}</p> <i onClick={()=> deleteRecipient(rec.id) } class="icofont-bin" style={{ cursor: 'pointer' }}></i></div>)
                                                    }

                                                </div>
                                            </div>
                                        </div>

                                    </form>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlTextarea78" className="form-label">Message</label>
                                    <textarea
                                        className="form-control"
                                        rows={3}
                                        placeholder="Write your message here..."
                                        defaultValue={""}
                                        name="body"
                                        onChange={messageForm.handleChange}
                                        value={messageForm.values.body}
                                    />
                                    {messageForm.errors.body ? <div className="text-secondary">{messageForm.errors.body}</div> : null}
                                </div>


                                <div className="mb-3">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="send_email"
                                        onChange={messageForm.handleChange}
                                        value={messageForm.values.send_email}
                                    />
                                    <label style={{ marginLeft: "5px" }} className="pl-3 form-check-label" for="flexCheckDefault">
                                        Send email notification
                                    </label>

                                </div>

                                {
                                    project ? <>
                                        <div className="mb-3">
                                            <label htmlFor="formFileMultipleone" className="form-label">Message Attachments (Optional)</label>
                                            <input
                                                className="form-control"
                                                type="file"
                                                multiple={true}
                                                onChange={(e) => {
                                                    console.log(attachments)
                                                    messageForm.setFieldValue('attachments', [...attachments, e.target.files[0]])
                                                    setAttachments([...attachments, e.target.files[0]])
                                                }}
                                            />
                                        </div>

                                        <div className="mb-3">
                                            {
                                                attachments.map(att => {
                                                    return <div key={att.name} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <p>{att.name}</p>
                                                        <p
                                                            style={{ cursor: 'pointer', }}
                                                            onClick={() => {
                                                                messageForm.setFieldValue('attachments', attachments.filter(attachment => attachment.name !== att.name))
                                                                setAttachments(attachments.filter(attachment => attachment.name !== att.name))
                                                            }}
                                                        ><i class="icofont-ui-delete text-warning"></i></p>
                                                    </div>
                                                })
                                            }
                                            <p style={{ color: "#484C7F" }}>{attachments.length} Files <i class="icofont-ui-file"></i> Selected</p>
                                        </div>
                                    </> : null
                                }
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={handleCloseCreateModal}>Cancel</button>
                                <button type="button" className="btn btn-primary" onClick={messageForm.handleSubmit}>Create</button>
                            </div>
                        </>
                    }


                </div>
            </div>
        </div>

    </>
}
export default MessageModal