import { Route } from "react-router-dom"
import Projects from "../views/admin/Projects/Projects"
import Project from "../views/admin/Project/Project"
import Dashboard from "../views/admin/Dashboard/Dashboard"
import Inbox from "../views/admin/Inbox/Inbox"
import CheckIns from "../components/CheckIns/CheckIns"
import TaskDocumentations from "../components/TaskDocumentations/TaskDocumentations"
import Profile from "../views/Profile/Profile"
import EditProfile from "../views/admin/EditProfile/EditProfile"
import ChangePassword from "../views/admin/ChangePassword/ChangePassword"

const AdminRoutes = ({setReload}) => {
    return (
      <>
        <Route path="/admin/checkIns" component={CheckIns} exact />
        <Route
          path="/admin/taskDocumentation"
          component={TaskDocumentations}
          exact
        />
        <Route path="/admin/projects" component={Projects} exact />
        <Route path="/admin/inbox/" component={Inbox} exact />
        <Route
          path="/admin/project/:projectId"
          exact
          render={(props) => <Project {...props} setReload={setReload} />}
        />
        <Route
          path="/admin/dashboard/"
          render={(props) => <Dashboard {...props} setReload={setReload} />}
          excat
        />
        {/* <Route path="/admin/editProfile/" component={EditProfile} excat /> */}
        <Route path="/admin/profile/" component={EditProfile} excat />
        <Route path="/admin/changePassword/" component={ChangePassword} excat />
      </>
    );
}
export default AdminRoutes