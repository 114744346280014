import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction"
import { getProjectEmployeeTasksAPI } from '../../../services/apiService'
import { ErrorAlert } from '../../../utils/alerts'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { RotatingLines } from 'react-loader-spinner'
import logout from '../../../utils/logout'


const Calendar = ({ project }) => {

    const [allEvents, setAllEvents] = useState([])
    const [loading, setLoading] = useState(false)
   
    useEffect(() => {
        if (project?.id) {
            getProjectEmployeeTasks(project.id)
        }
    }, [project])


    const getProjectEmployeeTasks = (projectId) => {
        setLoading(true)
        getProjectEmployeeTasksAPI(projectId).then(res => {
            let allProjects = res.data.data
            setAllEvents(
                allProjects.length > 0
                    ? allProjects.map(task => { return { ...task, taskId: task.id, date: moment.utc(task.start_date).local().format('YYYY-MM-DD') } })
                    : [])

            setLoading(false)
        }).catch(error => {
            if (error.response) {
                let err = `Unable to fetch project tasks. error(${error.response.data.message})`
                ErrorAlert(err)
                if (error.response.status === 401) {
                    logout();
                }
            } else {
                ErrorAlert(error.message)
            }
        })
    }


    const handleDateClick = (arg) => {
        console.log(arg.dateStr)
    }

    function renderEventContent(eventInfo) {
        console.log(eventInfo.event.extendedProps)
        return (
            <>
                <b style={{ color: "white", }}>{eventInfo.event._def.title}</b>
            </>
        )
    }
    return <>

        
        {
            loading ?
                <div className="col-12 text-center mb-5  mt-5">

                    <RotatingLines
                        strokeColor="#484C7F"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="96"
                        visible={true}
                    />

                </div>
                : <FullCalendar
                    plugins={[dayGridPlugin, interactionPlugin]}
                    initialView="dayGridMonth"
                    weekends={true}
                    events={allEvents}
                    dateClick={handleDateClick}
                    eventContent={renderEventContent}
                />
        }


    </>
}

export default Calendar