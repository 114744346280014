import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ErrorAlert, SuccessAlert } from '../../../utils/alerts';
import {
  deleteEmployeeAPI,
  deleteProjectAPI,
  employeeDashboardStatsAPI,
  getAllEmployeesAPI,
  getAllProjectsAPI,
} from '../../../services/apiService';
import { API_URL_PREFIX } from '../../../constants/apiUrl';
import EmployeeInvitation from '../../../components/employeeInvitation/employeeInvitation';
import { Link } from 'react-router-dom';
import CreateModal from '../../../components/CreateModal/CreateModal';
import LatestProjects from '../../admin/Dashboard/LatestProjects';
import LatestTasks from '../../admin/Dashboard/LatestTasks';
import documentImage from '../../../assets/images/documents.svg';
import { RotatingLines } from 'react-loader-spinner';
import DeleteItem from '../../../components/DeleteItem/DeleteItem';
import logout from '../../../utils/logout';

function Dashboard({ setReload }) {
  const [dateTime, setDateTime] = useState(new Date());
  const [greeting, setGreeting] = useState('');
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState({});

  const [showEmployeeInvitationModal, setShowEmployeeInvitationModal] =
    useState(false);
  const handleOpenEmployeeInvitationModal = () =>
    setShowEmployeeInvitationModal(true);
  const handleCloseEmployeeInvitationModal = () =>
    setShowEmployeeInvitationModal(false);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const handleOpenCreateModal = () => setShowCreateModal(true);
  const handleCloseCreateModal = () => setShowCreateModal(false);

  const [employees, setEmployees] = useState([]);
  const [allProjects, setAllProjects] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);
  const [showDeleteProjectModal, setShowDeleteProjectModal] = useState(false);
  const profileData = useSelector((state) => state.user)?.user;

  const handleOpenDeleteItemModal = () => setShowDeleteItemModal(true);
  const handleCloseDeleteItemModal = () => setShowDeleteItemModal(false);

  const handleOpenDeleteProjectModal = () => setShowDeleteProjectModal(true);
  const handleCloseDeleteProjectModal = () => setShowDeleteProjectModal(false);

  const options = {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
  };

  useEffect(() => {
    getAllEmployees();
    getAllProjects();
    getEmployeeDashboardStats();
    const date = new Date();
    setDateTime(date);
    const hour = date.getHours();
    if (hour >= 5 && hour < 12) {
      setGreeting('Good morning');
    } else if (hour >= 12 && hour < 17) {
      setGreeting('Good afternoon');
    } else {
      setGreeting('Good evening');
    }
  }, []);

  const getAllEmployees = () => {
    setLoading(true);
    getAllEmployeesAPI()
      .then((res) => {
        setEmployees(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          ErrorAlert(error.response.data.message);
          if (error.response.status === 401) {
            logout();
          }
        } else {
          ErrorAlert(error.message);
        }
      });
  };

  const getAllProjects = () => {
    getAllProjectsAPI()
      .then((res) => {
        setAllProjects(res.data.data);
        console.log(res.data.data);
      })
      .catch((error) => {
        if (error.response) {
          let err = `Unable to fetch projects. error(${error.response.data.message})`;
          ErrorAlert(err);
          if (error.response.status === 401) {
            logout();
          }
        } else {
          ErrorAlert(error.message);
        }
      });
  };

  const getEmployeeDashboardStats = () => {
    setLoading(true);
    employeeDashboardStatsAPI()
      .then((res) => {
        console.log(res.data.data);
        setData(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          ErrorAlert(error.response.data.message);
          if (error.response.status === 401) {
            logout();
          }
        } else {
          ErrorAlert(error.message);
        }
      });
  };

  const removeEmployeeFromProjectManagement = () => {
    console.log(selectedEmployee);
    deleteEmployeeAPI({ employee_id: selectedEmployee })
      .then((res) => {
        console.log(res.data);
        SuccessAlert('Employee deleted successfully!');
        handleCloseDeleteItemModal();
        getAllEmployees();
      })
      .catch((error) => {
        if (error.response) {
          let err = `Unable to delete employee. error(${error.response.data.message})`;
          ErrorAlert(err);
          if (error.response.status === 401) {
            logout();
          }
        } else {
          ErrorAlert(error.message);
        }
      });
  };

  const deleteProject = () => {
    deleteProjectAPI(selectedProject)
      .then((res) => {
        console.log(res.data);
        SuccessAlert('Project deleted successfully!');
        setReload(Math.random());
        handleCloseDeleteProjectModal();
        getAllProjects();
      })
      .catch((error) => {
        if (error.response) {
          let err = `Unable to delete project. error(${error.response.data.message})`;
          ErrorAlert(err);
          if (error.response.status === 401) {
            logout();
          }
        } else {
          ErrorAlert(error.message);
        }
      });
  };

  return (
    <div className="container-xxl">
      {showDeleteItemModal ? (
        <DeleteItem
          item={'User from Project Management'}
          deleteItem={() => removeEmployeeFromProjectManagement()}
          cancelDeleteItem={handleCloseDeleteItemModal}
        />
      ) : null}
      {showDeleteProjectModal ? (
        <DeleteItem
          item={'Project'}
          deleteItem={() => deleteProject()}
          cancelDeleteItem={handleCloseDeleteProjectModal}
        />
      ) : null}

      <div className="row clearfix g-3">
        <div className="container-xxl">
          {showEmployeeInvitationModal ? (
            <EmployeeInvitation
              handleCloseEmployeeInvitationModal={
                handleCloseEmployeeInvitationModal
              }
              employees={employees}
            />
          ) : (
            <></>
          )}
          {showCreateModal ? (
            <CreateModal
              handleCloseCreateModal={handleCloseCreateModal}
              intialCreate="Project"
            />
          ) : (
            <></>
          )}

          <div className="row clearfix g-3">
            <div className="col-md-12 text-center m-4">
              <h5>{dateTime.toLocaleDateString(undefined, options)}</h5>
              <h2>
                {greeting}, {profileData.username}
              </h2>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                  <h6 className="mb-0 fw-bold ">Projects</h6>
                </div>
                <div className="card-body">
                  <div className="row g-2 row-deck">
                    <div className="col-md-12 ">
                      <div
                        className=""
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <div className="">
                          <div className="d-flex align-items-center">
                            <div
                              onClick={handleOpenCreateModal}
                              style={{
                                borderStyle: 'dotted',
                                cursor: 'pointer',
                              }}
                              className="avatar lg  rounded-1 no-thumbnail color-defult"
                            >
                              <i class="icofont-ui-add"></i>
                            </div>
                            <h6 className="m-3">Create project</h6>
                          </div>
                        </div>
                      </div>
                    </div>

                    {allProjects &&
                      allProjects.map((project) => {
                        return (
                          <div className="col-md-12" key={project.id}>
                            <Link
                              to={'/admin/project/' + project.encoded_id}
                              className="ms-link"
                              style={{
                                cursor: 'pointer',
                                textTransform: 'capitalize',
                                display: 'flex',
                              }}
                            >
                              <i
                                class="icofont-briefcase mt-3"
                                style={{ fontSize: '22px' }}
                              ></i>
                              <p className="m-3">{project.title}</p>
                            </Link>
                            <i
                              style={{
                                marginTop: '18px',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                setSelectedProject(project.id);
                                handleOpenDeleteProjectModal();
                              }}
                              class="icofont-bin"
                            ></i>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card">
                <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                  <h6 className="mb-0 fw-bold ">People</h6>
                </div>
                <div className="card-body">
                  <div className="row g-2 row-deck">
                    <div className="col-md-12 ">
                      <div
                        className=""
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <div className="">
                          <div className="d-flex align-items-center">
                            <div
                              onClick={handleOpenEmployeeInvitationModal}
                              style={{
                                borderStyle: 'dotted',
                                cursor: 'pointer',
                              }}
                              className="avatar lg  rounded-1 no-thumbnail color-defult"
                            >
                              <i class="icofont-ui-add"></i>
                            </div>
                            <h6 className="m-3">Invite</h6>
                          </div>
                        </div>
                      </div>
                    </div>

                    {employees &&
                      employees.map((employee) => {
                        return (
                          <div className="col-md-12" key={employee.id}>
                            <img
                              key={employee.id}
                              className="mt-3 avatar rounded-circle"
                              src={`${API_URL_PREFIX}/media/${employee.image}`}
                              alt=""
                            />
                            <p className="m-3">{employee.email}</p>
                            <i
                              style={{ marginTop: '18px', cursor: 'pointer' }}
                              onClick={() => {
                                setSelectedEmployee(employee.id);
                                handleOpenDeleteItemModal();
                              }}
                              class="icofont-bin"
                            ></i>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {!loading ? (
          <>
            <div className="col-md-12">
              <div className="row g-3 mb-3 row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 row-cols-xl-3 row-cols-xxl-3">
                <div className="col">
                  <div className="card bg-primary">
                    <div className="card-body text-white d-flex align-items-center">
                      <i className="icofont-data fs-3" />
                      <div className="d-flex flex-column ms-3">
                        <h6 className="mb-0">Total Projects</h6>
                        <span className="text-white">
                          {data?.projects?.total_projects}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card bg-primary">
                    <div className="card-body text-white d-flex align-items-center">
                      <i className="fs-3 bi bi-journal-check " />
                      <div className="d-flex flex-column ms-3">
                        <h6 className="mb-0">Active Projects</h6>
                        <span className="text-white">
                          {data?.projects?.active_projects}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="card bg-primary">
                    <div className="card-body text-white d-flex align-items-center">
                      <i className="icofont-tasks fs-3" />
                      <div className="d-flex flex-column ms-3">
                        <h6 className="mb-0">Completed Projects</h6>
                        <span className="text-white">
                          {data?.projects?.completed_projects}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <LatestProjects projects={data?.projects?.latest_projects} />

            <div className="col-md-12">
              <div className="row g-3 mb-3 row-deck">
                <div className="col-md-12 col-lg-3 col-xl-3 col-xxl-3">
                  <div className="card ">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div
                          style={{ backgroundColor: '#ffe28c !important' }}
                          className=" lg  rounded-1 no-thumbnail bg-lightyellow color-defult"
                        >
                          <i className="bi bi-journal-check fs-4" />
                        </div>
                        <div className="flex-fill ms-4">
                          <div className>Total Task</div>
                          <h5 className="mb-0 ">{data?.tasks?.total_tasks}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-3 col-xl-3 col-xxl-3">
                  <div className="card ">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className=" lg  rounded-1 no-thumbnail bg-lightblue color-defult">
                          <i className="bi bi-list-check fs-4" />
                        </div>
                        <div className="flex-fill ms-4">
                          <div className>Completed Task</div>
                          <h5 className="mb-0 ">
                            {data?.tasks?.completed_tasks}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-3 col-xl-3 col-xxl-3">
                  <div className="card ">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className=" lg  rounded-1 no-thumbnail bg-lightgreen color-defult">
                          <i className="bi bi-clipboard-data fs-4" />
                        </div>
                        <div className="flex-fill ms-4">
                          <div className>In-Progress Task</div>
                          <h5 className="mb-0 ">
                            {data?.tasks?.in_progress_tasks}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-3 col-xl-3 col-xxl-3">
                  <div className="card ">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className=" lg  rounded-1 no-thumbnail bg-lightgreen color-defult">
                          <i className="bi bi-clipboard-data fs-4" />
                        </div>
                        <div className="flex-fill ms-4">
                          <div className>Pending Task</div>
                          <h5 className="mb-0 ">
                            {data?.tasks?.pending_tasks}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <LatestTasks tasks={data?.tasks?.latest_tasks} />
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12 col-lg-12 col-xl-12">
                  <div className="card bg-primary">
                    <div className="card-body row">
                      <div className="col">
                        <span className="avatar lg bg-white rounded-circle text-center d-flex align-items-center justify-content-center">
                          <i className="icofont-file-text fs-5" />
                        </span>
                        <h1 className="mt-3 mb-0 fw-bold text-white">
                          {data?.documentations?.total_documentations}
                        </h1>
                        <span className="text-white">Documentations</span>
                      </div>
                      <div className="col">
                        <img
                          style={{ height: '130px' }}
                          className="img-fluid"
                          src={documentImage}
                          alt="documents"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="col-12 text-center mb-5  mt-5">
            <RotatingLines
              strokeColor="#484C7F"
              strokeWidth="5"
              animationDuration="0.75"
              width="96"
              visible={true}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
