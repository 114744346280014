import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import userReducer from "./reducers/userReducer.js";

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user',]
}

const appReducer = combineReducers({
    user: userReducer,
});



const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let persistedReducer = persistReducer(persistConfig, appReducer)

export const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)));
export const persistedStore = persistStore(store)




