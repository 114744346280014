import React, { useEffect } from "react";
import { todayCheckInAPI, todayCheckInCheckOutAPI, todayCheckOutAPI } from "../../services/apiService";
import { ErrorAlert, SuccessAlert } from "../../utils/alerts";
import { useState } from "react";
import moment from "moment";
import logout from "../../utils/logout";

const CheckInHeader = ({reload, setReload, locationPermissionAllowed}) => {
  
  const [todayCheckIn, setTodayCheckIn] = useState(null);
  const [todayCheckOut, setTodayCheckOut] = useState(null);
  const [loading, setLoading] = useState(false);
  

  useEffect(()=>{
    setLoading(true);
    getTodayCheckInCheckOutDetails();
  },[reload])

  const getTodayCheckInCheckOutDetails = () => {
    todayCheckInCheckOutAPI().then(res => {
        console.log(res.data.data);
        setTodayCheckIn(res.data.data["today_check_in"]);
        setTodayCheckOut(res.data.data["today_check_out"]);
    }).catch(error => {
        if (error.response) {
            ErrorAlert(error.response.data.message)
            if (error.response.status === 401) {
                logout();
            }
        } else {
            ErrorAlert(error.message)
        }
    }).finally(()=>{
      setLoading(false);
    })
  }

  const getUserLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({ longitude: position.coords.longitude, latitude: position.coords.latitude });
          },
          (error) => {
            reject("Error getting user's location.");
          }
        );
      } else {
        reject('Geolocation is not supported by your browser.');
      }
    });
  };

  
  const handleCheckIn = async ()=>{
    
    let {longitude, latitude} = await getUserLocation();
    if(!longitude && !latitude)
      return

    todayCheckInAPI({longitude:longitude, latitude:latitude}).then(res => {
      console.log(res.data.data);
      SuccessAlert("Check In Successfully!");
      setReload(!reload);
  }).catch(error => {
      if (error.response) {
          ErrorAlert(error.response.data.message)
          if (error.response.status === 401) {
              logout();
          }
      } else {
          ErrorAlert(error.message)
      }
  }).finally(()=>{
  })
  }


  const handleCheckOut = async ()=>{
    
    let {longitude, latitude} = await getUserLocation();
    if(!longitude && !latitude)
      return
    todayCheckOutAPI({longitude:longitude, latitude:latitude}).then(res => {
      console.log(res.data.data);
      SuccessAlert("Check Out Successfully!");
      setReload(!reload);
  }).catch(error => {
      if (error.response) {
          ErrorAlert(error.response.data.message)
          if (error.response.status === 401) {
              logout();
          }
      } else {
          ErrorAlert(error.message)
      }
  }).finally(()=>{
  })
  }

  return <div>
    
     { loading ? <p>Loading...</p> 
     : 
     <>
     <div className="row align-item-center row-deck g-3 mb-3">
     <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
       <div className="card">
         <div className="card-header py-3">
           <h6 className="mb-0 fw-bold ">Check In & Check Out Time</h6>
         </div>
         <div className="card-body">
           <div className="timesheet-info d-flex align-items-center justify-content-between flex-wrap">
             
             <div className="intime d-flex align-items-center m-2">
               <i className="icofont-finger-print fs-4 color-light-success" />
               <span className="fw-bold ms-1">{todayCheckIn ? `Check In Time : ${moment(todayCheckIn).format('h:mm:s A')}` : "Check in to continue work."}</span>
               <div className="outtime m-2 w-sm-100">
                  
                  {
                    !todayCheckIn && !locationPermissionAllowed 
                      ? 
                      <div>
                        <span style={{fontStyle:"italic"}} className="text-danger font-italic">Allow Location Permission</span>
                      </div>
                      :
                      <button type="button" className="btn btn-dark w-sm-100" onClick={handleCheckIn} disabled={todayCheckIn}><i className="icofont-foot-print me-2" />Check In</button> 
                  }
                   
               </div>
             </div>
            
           </div>
           <div className="timesheet-info d-flex align-items-center justify-content-between flex-wrap">
             
             <div className="intime d-flex align-items-center m-2">
               <i className="icofont-finger-print fs-4 color-light-success" />
               <span className="fw-bold ms-1">{todayCheckOut ? `Check Out Time : ${moment(todayCheckOut).format('h:mm:s A')}` : "Check out to close work."}</span>
               <div className="outtime m-2 w-sm-100">
                  {
                    !todayCheckOut && !locationPermissionAllowed
                    ? 
                    <div>
                        <span style={{fontStyle:"italic"}} className="text-danger font-italic">Allow Location Permission</span>
                    </div>
                    :
                    <button type="button" className="btn btn-dark w-sm-100" onClick={handleCheckOut} disabled={(todayCheckIn && todayCheckOut) || !todayCheckIn}><i className="icofont-foot-print me-2" />Check Out</button>
                    
                  }
                </div>
             </div>
            
           </div>
         </div>
         
       </div> 
     </div>
   
   </div>
   </>
   }
  </div>;
};

export default CheckInHeader;
