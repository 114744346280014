
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';

export const SuccessAlert = (message) => {
    toast.success(message);
}


export const ErrorAlert = (message) => {
    toast.error(message);
}

export const LoadingAlert = () => {
    Swal.showLoading({html: 'data uploading'});
}

export const HideLoadingAlert = () =>{
    Swal.close();
}