import { useEffect, useState } from "react"
import SVG from "../../../assets/images/login-img.svg"
import { employeeAccountActivationAPI } from "../../../services/apiService"
import { ErrorAlert, SuccessAlert } from "../../../utils/alerts"
import { RotatingLines } from 'react-loader-spinner'
import { useHistory } from "react-router-dom"
import logout from "../../../utils/logout"


const EmailVerification = () => {
    const parameters = window.location.search ? window.location.search : null
    const [verificationSuccess, setVerificationSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    console.log(parameters)
    useEffect(() => {
        parameters ? employeeAccountActivation() : ErrorAlert("Invalid attempt.")
    }, [])

    const employeeAccountActivation = () => {
        setLoading(true)
        employeeAccountActivationAPI(parameters).then(res => {
            console.log(res.data)
            SuccessAlert(res.data.message)
            setVerificationSuccess(true)
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            setVerificationSuccess(false)
            if (error.response) {
                // The request was made and the server responded with a status code that falls out of the range of 2xx
                ErrorAlert(error.response.data.message);
                console.error(error.response.status);
                console.error(error.response.headers);
                if (error.response.status === 401) {
                   logout();
                }
            } else if (error.request) {
                // The request was made but no response was received
                ErrorAlert("No response recieved from server.");
            } else if (error.message === "Network Error") {
                // Handle network error here
                ErrorAlert("Network Error: Please check your internet connection");
            } else {
                // Something happened in setting up the request that triggered an Error
                ErrorAlert(error.message);
            }
        })
    }
    return <>
        {/* main body area */}
        <div className="main p-2 py-3">
            {/* Body: Body */}
            <div className="body d-flex p-0">
                <div className="container-xxl">
                    <div className="row g-0" style={{ marginTop: "-15px", marginBottom: "-10px" }}>
                        <div className="col-lg-6 d-none d-lg-flex justify-content-center align-items-center rounded-lg auth-h100">
                            <div style={{ maxWidth: '25rem' }}>
                                <div className="text-center mb-5" style={{ marginTop: '25px' }}>
                                    <svg width="4rem" fill="currentColor" className="bi bi-clipboard-check" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                    </svg>
                                </div>
                                <div className="mb-5">
                                    <h2 className="color-900 text-center">Let's Simplified Project Management.</h2>
                                </div>
                                {/* Image block */}
                                <div className>
                                    <img src={SVG} alt="login-img" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 d-flex justify-content-center align-items-center border-0 rounded-lg auth-h100">
                            <div className="w-100 card border-0 bg-dark text-light" style={{ maxWidth: '32rem' }}>
                                {/* Form */}

                                <form className="row g-1 p-3 p-md-4">
                                    {
                                        loading ? <div>
                                            <div className="col-12 text-center mb-5  mt-5">

                                                <RotatingLines
                                                    strokeColor="white"
                                                    strokeWidth="5"
                                                    animationDuration="0.75"
                                                    width="96"
                                                    visible={true}
                                                />

                                            </div>
                                        </div> : verificationSuccess ? <div>
                                            <div className="col-12 text-center mb-1  mt-5">

                                                <i className="icofont-tick-mark" style={{ fontSize: "60px" }}></i>
                                                <p>Account veriefication successfully completed.</p>

                                            </div>
                                            <div className="col-12 text-center mb-5">
                                                <button onClick={() => { history.push("/login/") }} className="btn btn-lg btn-block btn-light lift text-uppercase" >Login</button>
                                            </div>
                                        </div> : <div>
                                            <div className="col-12 text-center mb-5  mt-5">

                                                <i className="icofont-error" style={{ fontSize: "60px" }}></i>
                                                <p>Unable to veriefy your account.</p>

                                            </div>

                                        </div>


                                    }




                                </form>
                                {/* End Form */}
                            </div>
                        </div>
                    </div> {/* End Row */}
                </div>
            </div>
        </div>


    </>
}
export default EmailVerification