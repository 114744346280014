

const DeleteItem = ({ item, deleteItem, cancelDeleteItem }) => {
    return <>
        <div className="modal fade show" style={{ display: "block", zIndex: "1000!important;" }} aria-modal="true">
            <div className="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title  fw-bold" id="deleteprojectLabel"> Delete {item} Permanently?</h5>
                        <button type="button" className="btn-close" onClick={cancelDeleteItem} />
                    </div>
                    <div className="modal-body justify-content-center flex-column d-flex">
                        <i className="icofont-ui-delete text-danger display-2 text-center mt-2" />
                        <p className="mt-4 fs-5 text-center">You are about to permanently delete this <span className="text-lowercase">{item}</span>. This action can not be undone. </p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={cancelDeleteItem}>Cancel</button>
                        <button type="button" className="btn btn-danger text-light" onClick={deleteItem}>Delete</button>
                    </div>
                </div>
            </div>

        </div>

    </>
}
export default DeleteItem