import { API_URL_PREFIX } from "../../../constants/apiUrl"
import moment from "moment/moment"
import { createProjectTaskStatusAPI, updateProjectTaskAPI } from "../../../services/apiService"
import { ErrorAlert, SuccessAlert } from "../../../utils/alerts"
import { useState } from "react"
import SubTaskModal from "../SubTaskModal/SubTaskModal"
import { changeDateToIsoFormat, changeIsoFormatToDate } from "../../../utils/formatDate"
import logout from "../../../utils/logout"



const Task = ({ task, handleOpenUpdateItemModal, setItemSelectedForUpdate, handleOpenDeleteItemModal, setItemSelectedForDelete, handleTaskUpdated, project }) => {

    const [showSubTaskModal, setShowSubTaskModal] = useState(false)
    const [subTasks, setSubTasks] = useState(false)
    const handleShowSubTasks = () => { setSubTasks(!subTasks) }
    const handleShowSubTaskModal = () => { setShowSubTaskModal(true) }
    const handleCloseSubTaskModal = () => { setShowSubTaskModal(false) }

    const createProjectTaskStatus = (taskId, crrStatus) => {
        let reqData = {
            project_task: taskId,
            status: crrStatus,
        }
        createProjectTaskStatusAPI(reqData).then(res => {
            SuccessAlert(res.data.message)
            handleTaskUpdated()
        }).catch(error => {
            if (error.response) {
                ErrorAlert(error.response.data.message)
                if (error.response.status === 401) {
                    logout();
                }
            } else {
                ErrorAlert(error.message)
            }
        })
    }

    const updateTask = (task, name, value) => {
        let reqData = {
            ...task,
            [name]: value
        }
        reqData["assign_to"] = reqData.assign_to?.first_name ? reqData.assign_to.id : reqData.assign_to
        console.log(reqData)


        updateProjectTaskAPI(reqData.id, reqData).then(res => {

        }).catch(error => {
            if (error.response) {
                ErrorAlert(error.response.data.message)
                if (error.response.status === 401) {
                   logout();
                }
            } else {
                ErrorAlert(error.message)
            }
        })
    }

    return <>
        {showSubTaskModal ? <SubTaskModal task={task} handleTaskUpdated={handleTaskUpdated} handleCloseSubTaskModal={handleCloseSubTaskModal} project={project} /> : null}
        <tr role="row" className="odd" key={task.id}>
            <td className="text-capitalize" onClick={handleShowSubTasks} style={{ cursor: "pointer" }}> {subTasks ? <i class="icofont-rounded-up"></i> : <i class="icofont-simple-down"></i>} </td>
            {/* <td className="text-capitalize" style={task?.status?.status === "completed" ? { textAlign: 'center', fontSize: '19px', color: 'green' } : { textAlign: 'center', fontSize: '19px', color: '#dee2e6' }}> <i onClick={() => createProjectTaskStatus(task.id, task?.status?.status)} style={{ cursor: "pointer" }} class="icofont-check-circled"></i> </td> */}

            <td>
                <select
                    style={{ width: '130px' }}
                    className="form-select text-capitalize"
                    name="task_status"
                    onChange={(e) => createProjectTaskStatus(task.id, e.target.value)}
                >
                    <option className="text-capitalize" selected value="">Select Task Status</option>
                    <option className="text-capitalize" value="pending" selected={task?.status?.status === "pending"} >Pending</option>
                    <option className="text-capitalize" value="in progress" selected={task?.status?.status === "in progress"}>In Progress</option>
                    <option className="text-capitalize" value="completed" selected={task?.status?.status === "completed"} >Completed</option>

                </select>


            </td>

            <td className="text-capitalize">
                <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder=""
                    name="title"
                    defaultValue={task.title}
                    onBlur={(e) => { updateTask(task, 'title', e.target.value) }}
                />
            </td>
            <td>
                <select
                    className="form-select text-capitalize"
                    name="assign_to"
                    onChange={(e) => { updateTask(task, 'assign_to', e.target.value) }}
                >
                    <option className="text-capitalize" selected value="">Select Assigne</option>
                    {
                        task?.project_added_employees ? task?.project_added_employees.map(emp => {
                            return <option className="text-capitalize" value={emp.id} selected={emp.id === task?.assign_to?.id}>{emp.first_name}  {emp.last_name}</option>
                        }) : null
                    }

                </select>


            </td>
            <td className=" dt-body-right" style={{ width: "10%" }}>

                <input
                    type="date"
                    className="form-control"
                    name="start_date"
                    defaultValue={changeIsoFormatToDate(task.start_date)}
                    onChange={(e) => { updateTask(task, 'start_date', changeDateToIsoFormat(e.target.value)) }}
                />

            </td>
            <td className=" dt-body-right" style={{ width: "10%" }}>

                <input
                    type="date"
                    className="form-control"
                    name="end_date"
                    defaultValue={changeIsoFormatToDate(task.end_date)}
                    onChange={(e) => { updateTask(task, 'end_date', changeDateToIsoFormat(e.target.value)) }}
                />

            </td>

            <td className=" dt-body-right">
                <div className="btn-group" role="group" aria-label="Basic outlined example">
                    <button type="button" className="btn btn-outline-secondary" onClick={() => {
                        handleOpenUpdateItemModal()
                        setItemSelectedForUpdate(task)
                    }}>
                        <i className="icofont-edit text-success" />
                    </button>
                    <button onClick={() => {
                        handleOpenDeleteItemModal()
                        setItemSelectedForDelete(task)
                    }} type="button" className="btn btn-outline-secondary deleterow">
                        <i className="icofont-ui-delete text-danger" />
                    </button>
                </div>
            </td>
        </tr>

        {
            subTasks ? <>
                {
                    task?.sub_tasks ? task.sub_tasks.map(subTask => {
                        return <tr role="row" className="odd parent" key={subTask.id}>
                            <td className="text-capitalize"></td>

                            {/* <td className="text-capitalize" style={subTask?.status?.status === "completed" ? { textAlign: 'center', fontSize: '19px', color: 'green' } : { textAlign: 'center', fontSize: '19px', color: '#dee2e6' }}> <i onClick={() => createProjectTaskStatus(subTask.id, subTask?.status?.status)} style={{ cursor: "pointer" }} class="icofont-check-circled"></i> </td> */}

                            <td>
                                <select
                                    style={{ width: '130px' }}
                                    className="form-select text-capitalize"
                                    name="task_status"
                                    onChange={(e) => createProjectTaskStatus(subTask.id, e.target.value)}
                                >
                                    <option className="text-capitalize" selected value="">Select Task Status</option>
                                    <option className="text-capitalize" value="pending" selected={subTask?.status?.status === "pending"} >Pending</option>
                                    <option className="text-capitalize" value="in progress" selected={subTask?.status?.status === "in progress"}>In Progress</option>
                                    <option className="text-capitalize" value="completed" selected={subTask?.status?.status === "completed"} >Completed</option>

                                </select>


                            </td>
                            <td className="text-capitalize">
                                <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    placeholder=""
                                    name="title"
                                    defaultValue={subTask.title}
                                    onBlur={(e) => { updateTask(subTask, 'title', e.target.value) }}
                                />
                            </td>
                            <td>
                                <select
                                    className="form-select text-capitalize"
                                    name="assign_to"
                                    onChange={(e) => { updateTask(subTask, 'assign_to', e.target.value) }}
                                >
                                    <option className="text-capitalize" selected value="">Select Assigne</option>
                                    {
                                        subTask?.project_added_employees ? subTask?.project_added_employees.map(emp => {
                                            return <option className="text-capitalize" value={emp.id} selected={emp.id === subTask?.assign_to?.id}>{emp.first_name}  {emp.last_name}</option>
                                        }) : null
                                    }

                                </select>


                            </td>
                            <td className=" dt-body-right" style={{ width: "10%" }}>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="start_date"
                                    defaultValue={changeIsoFormatToDate(subTask.start_date)}
                                    onChange={(e) => { updateTask(subTask, 'start_date', changeDateToIsoFormat(e.target.value)) }}
                                />
                            </td>
                            <td className=" dt-body-right" style={{ width: "10%" }}>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="end_date"
                                    defaultValue={changeIsoFormatToDate(subTask.end_date)}
                                    onChange={(e) => { updateTask(subTask, 'end_date', changeDateToIsoFormat(e.target.value)) }}
                                />
                            </td>


                            <td className=" dt-body-right">
                                <div className="btn-group" role="group" aria-label="Basic outlined example">
                                    <button type="button" className="btn btn-outline-secondary" onClick={() => {
                                        handleOpenUpdateItemModal()
                                        setItemSelectedForUpdate(subTask)
                                    }}>
                                        <i className="icofont-edit text-success" />
                                    </button>
                                    <button onClick={() => {
                                        handleOpenDeleteItemModal()
                                        setItemSelectedForDelete(subTask)
                                    }} type="button" className="btn btn-outline-secondary deleterow">
                                        <i className="icofont-ui-delete text-danger" />
                                    </button>
                                </div>
                            </td>
                        </tr>

                    }) : null
                }
                <tr role="row" className="odd parent text-center" >
                    <td className="dt-body-right" colspan="7">
                        <div onClick={handleShowSubTaskModal} style={{ cursor: "pointer" }}><i className="icofont-ui-add text-center" /></div>
                    </td>
                </tr>

            </> : null
        }

    </>
}

export default Task