import React from 'react';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { downloadAttachmentAPI } from '../../../services/apiService';
import { ErrorAlert } from '../../../utils/alerts';
import logout from '../../../utils/logout';

const FilePreview = (props) => {
    const { attachment } = props;
    const extension = attachment.split('.').pop();
    const notSupported = ['webp']

    const iconProps = {
        extension,
        color: ! notSupported.includes(extension) ? defaultStyles[extension]?.color : "white",
        glyph: ! notSupported.includes(extension) ? defaultStyles[extension]?.glyph : "", 
    };

    const downloadAttachment = (link) => {
        downloadAttachmentAPI(link).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', attachment);
            document.body.appendChild(link);
            link.click();
        }).catch(error => {
            if (error.response) {
                ErrorAlert(error.response.data.message)
                if (error.response.status === 401) {
                    logout();
                }
            } else {
                ErrorAlert(error.message)
            }
        })
    }
    return (

        <div onClick={()=>{downloadAttachment(attachment)}} style={{ width: "80px", cursor: "pointer" }}>
            <FileIcon  {...iconProps} />
        </div>

    );
}

export default FilePreview;
