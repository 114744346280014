import React, { useState } from "react";
import CreateDocumentationModal from "./CreateDocumentationModal";
import { useSelector } from "react-redux";

const TaskDocumentationHeader = ({reload, setReload}) => {
    const [showCreateModal, setShowCreateModal] = useState(false);
    const toggleCreateModal = ()=> setShowCreateModal(!showCreateModal);

    const profileData = useSelector((state) => state.user)?.user

  return <div>
    {
        showCreateModal ? <CreateDocumentationModal toggleCreateModal={toggleCreateModal} reload={reload} setReload={setReload}/> : <></> 
    }
    <div className="row align-items-center">
  <div className="border-0 mb-4">
    <div className="card-header p-0 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
      <h3 className="fw-bold py-3 mb-0">Task Documentation</h3>
      <div className="d-flex py-2 project-tab flex-wrap w-sm-100">
        { profileData.role === "employee" ? <button onClick={toggleCreateModal} type="button" className="btn btn-dark w-sm-100" data-bs-toggle="modal" data-bs-target="#createproject"><i className="icofont-plus-circle me-2 fs-6" />Create Task Documentation</button> : null}
      </div>
    </div>
  </div>
</div>

  </div>;
};

export default TaskDocumentationHeader;
