import { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { createProjectTaskStatusAPI, getProjectAllTasksAPI, updateProjectTaskAPI } from '../../../services/apiService';
import { ErrorAlert } from '../../../utils/alerts';
import { API_URL_PREFIX } from '../../../constants/apiUrl';
import moment from "moment";
import logout from '../../../utils/logout';

function Board({ project }) {
    const [tasks, setTasks] = useState([])
    const [loading, setLoading] = useState(true)

    const [data, setData] = useState({
        pending: [],
        inProgress: [],
        completed: [],
    });

    function handleDragEnd(result) {
        if (!result.destination) {
            return;
        }
        const droppableId =  result.source.droppableId === 'in progress' ? 'inProgress' : result.source.droppableId;
        const items = Array.from(data[droppableId]);
        const [reorderedItem] = items.splice(result.source.index, 1);

        console.log('result', result.source.droppableId, result)
        
        if (result.destination.droppableId === "completed"  ) {
            reorderedItem["status"]["status"] = "completed"

            let destData = data["completed"];
            if(result.destination.droppableId !== result.source.droppableId){
                destData.splice(result.destination.index, 0, reorderedItem);
            }else{
                destData = items;
                destData.splice(result.destination.index, 0, reorderedItem);
            }
            

            if(result.source.droppableId === "pending"){
                 setData({
                    "pending": items, 
                    "inProgress": [...data["inProgress"]],
                    "completed": destData,
                });
            }else if(result.source.droppableId === "in progress"){
                 setData({
                    "pending": [...data["pending"]], 
                    "inProgress": items, 
                    "completed": destData,
                });
            }else if(result.source.droppableId === "completed"){
                setData({
                    "pending": [...data["pending"]], 
                    "inProgress": [...data["inProgress"]], 
                    "completed": destData,
                });
            }
           
        } else if (result.destination.droppableId === "pending"  ) {
            reorderedItem["status"]["status"] = "pending"
            
            let destData = data["pending"];
            if(result.destination.droppableId !== result.source.droppableId){
                destData.splice(result.destination.index, 0, reorderedItem);
            }else{
                destData = items;
                destData.splice(result.destination.index, 0, reorderedItem);
            }

            if(result.source.droppableId === "completed"){
                 setData({
                    "completed": items, 
                    "inProgress": [...data["inProgress"]], 
                    "pending": destData, 
                });
            }else if(result.source.droppableId === "in progress"){
                 setData({
                    "completed": [...data["completed"]], 
                    "inProgress": items,
                    "pending": destData,
                });
            }else if(result.source.droppableId === "pending"){
                 setData({
                    "completed": [...data["completed"]], 
                    "inProgress": [...data["inProgress"]],
                    "pending": destData,
                });
            }
           
        } else if (result.destination.droppableId === "in progress" ) {
            reorderedItem["status"]["status"] = "in progress"
            
            let destData = data["inProgress"];
            if(result.destination.droppableId !== result.source.droppableId){
                destData.splice(result.destination.index, 0, reorderedItem);
            }else{
                destData = items;
                destData.splice(result.destination.index, 0, reorderedItem);
            }
            
            if(result.source.droppableId === "completed"){
                 setData({
                    "completed": items,
                    "inProgress": destData, 
                    "pending": [...data["pending"]] 
                });
            }else if(result.source.droppableId === "pending"){
                 setData({
                    "completed": [...data["completed"]], 
                    "inProgress": destData,
                    "pending": items, 
                });
            }else if(result.source.droppableId === "in progress"){
                 setData({
                    "completed": [...data["completed"]], 
                    "inProgress": destData,
                    "pending":  [...data["pending"]], 
                });
            }
           
        }

        createProjectTaskStatus(reorderedItem.id, reorderedItem.status.status)

    }


    useEffect(() => {
        if (project?.id) {
            getProjectAllTasks(project.id)
        }
    }, [project,])

    const getProjectAllTasks = (projectId) => {
        setLoading(true)
        getProjectAllTasksAPI(projectId).then(res => {
            let allTasks = res.data.data
            console.log(allTasks)
            setTasks(allTasks)
            setLoading(false)
            let completedTasks = allTasks.filter(task => task.status.status === "completed")
            let pendingTasks = allTasks.filter(task => task.status.status === "pending")
            let inProgressTasks = allTasks.filter(task => task.status.status === "in progress")

            setData({
                pending: pendingTasks,
                inProgress: inProgressTasks,
                completed: completedTasks,
            })
            console.log(completedTasks, pendingTasks)

        }).catch(error => {
            setLoading(false)
            if (error.response) {
                let err = `Unable to fetch project tasks. error(${error.response.data.message})`
                ErrorAlert(err)
                if (error.response.status === 401) {
                    logout();
                }
            } else {
                ErrorAlert(error.message)
            }
        })
    }

    const createProjectTaskStatus = (taskId, crrStatus) => {
        setLoading(true)
        let reqData = {
            project_task: taskId,
            status: crrStatus,
        }
        createProjectTaskStatusAPI(reqData).then(res => {
            //getProjectAllTasks(project.id)
        }).catch(error => {
            setLoading(false)
            if (error.response) {
                ErrorAlert(error.response.data.message)
                if (error.response.status === 401) {
                    logout();
                }
            } else {
                ErrorAlert(error.message)
            }
        })
    }



    return <>
        <div className="row taskboard g-3 py-xxl-4">
            <div className='row'>
                <div className="progress_task">
                    <div className="dd" data-plugin="nestable">
                        <div className="dd-list">
                            <DragDropContext onDragEnd={handleDragEnd}>
                                <div className="table row">

                                    <Droppable droppableId="pending" style={{ width: "33%!important" }}>
                                        {(provided, snapshot) => (


                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                className="column col-4"
                                            >
                                                <h4 className='mt-5 mb-5 text-center'>Pending</h4>
                                                {data.pending.map((item, index) => (
                                                    <Draggable key={item.id} draggableId={`${item.id}`} index={index}>
                                                        {(provided, snapshot) => (
                                                            <div
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                ref={provided.innerRef}
                                                                style={provided.draggableProps.style}
                                                                className="dd-item task"
                                                            >
                                                                <div className="dd-handle">
                                                                    <div className="task-info d-flex align-items-center justify-content-between">
                                                                        <h6 className="light-danger-bg py-1 px-2 rounded-1 d-inline-block fw-bold small-14 mb-0 text-title"> {item.title}
                                                                        </h6>
                                                                        <div className="task-priority d-flex flex-column align-items-center justify-content-center">
                                                                            <div className="avatar-list avatar-list-stacked m-0">
                                                                                {
                                                                                    item?.assign_to && <img className="avatar rounded-circle small-avt" src={`${API_URL_PREFIX}/media/${item.assign_to.image}`} alt="" />
                                                                                }
                                                                                {
                                                                                    item?.sub_tasks && item.sub_tasks.map(subTask => {
                                                                                        return subTask?.assign_to && <img className="avatar rounded-circle small-avt" src={`${API_URL_PREFIX}/media/${subTask.assign_to.image}`} alt="" />
                                                                                    })
                                                                                }
                                                                            </div>
                                                                            <span className="badge light-primary-bg text-primary text-end mt-2 text-title text-capitalize">{item.priority}</span>
                                                                        </div>
                                                                    </div>
                                                                    <p className="py-2 mb-0 text-title">{item.description}</p>
                                                                    <div className="tikit-info row g-3 align-items-center">
                                                                        <div className="col-sm">
                                                                            <ul className="d-flex list-unstyled align-items-center flex-wrap">
                                                                                <li className="me-2">
                                                                                    <div className="d-flex align-items-center">
                                                                                        <i className="icofont-flag" />
                                                                                        <span className="ms-1">{moment(item.start_date).format("D MMMM")} {item.end_date && '-'}  {item.end_date && moment(item.end_date).format("D MMMM")}</span>
                                                                                    </div>
                                                                                </li>

                                                                                <li>
                                                                                    <div className="d-flex align-items-center">
                                                                                        <i className="icofont-paper-clip" />
                                                                                        <span className="ms-1">{item.sub_tasks.length} Sub Task{item.sub_tasks.length > 1 && 's'}</span>
                                                                                    </div>
                                                                                </li>
                                                                            </ul>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>


                                                        )}
                                                    </Draggable>
                                                ))}

                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>


                                    <Droppable droppableId="in progress">
                                        {(provided, snapshot) => (


                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                className="column col-4"
                                            >
                                                <h4 className='mt-5 mb-5 text-center'>In Progress</h4>
                                                {data.inProgress.map((item, index) => (
                                                    <Draggable key={item.id} draggableId={`${item.id}`} index={index}>
                                                        {(provided, snapshot) => (
                                                            <div
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                ref={provided.innerRef}
                                                                style={provided.draggableProps.style}
                                                                className="dd-item task"
                                                            >
                                                                <div className="dd-handle">
                                                                    <div className="task-info d-flex align-items-center justify-content-between">
                                                                        <h6 className="light-warning-bg py-1 px-2 rounded-1 d-inline-block fw-bold small-14 mb-0 text-title"> {item.title}
                                                                        </h6>
                                                                        <div className="task-priority d-flex flex-column align-items-center justify-content-center">
                                                                            <div className="avatar-list avatar-list-stacked m-0">
                                                                                {
                                                                                    item?.assign_to && <img className="avatar rounded-circle small-avt" src={`${API_URL_PREFIX}/media/${item.assign_to.image}`} alt="" />
                                                                                }
                                                                                {
                                                                                    item?.sub_tasks && item.sub_tasks.map(subTask => {
                                                                                        return subTask?.assign_to && <img className="avatar rounded-circle small-avt" src={`${API_URL_PREFIX}/media/${subTask.assign_to.image}`} alt="" />
                                                                                    })
                                                                                }
                                                                            </div>
                                                                            <span className="badge light-primary-bg text-primary text-end mt-2 text-title text-capitalize">{item.priority}</span>
                                                                        </div>
                                                                    </div>
                                                                    <p className="py-2 mb-0 text-title">{item.description}</p>
                                                                    <div className="tikit-info row g-3 align-items-center">
                                                                        <div className="col-sm">
                                                                            <ul className="d-flex list-unstyled align-items-center flex-wrap">
                                                                                <li className="me-2">
                                                                                    <div className="d-flex align-items-center">
                                                                                        <i className="icofont-flag" />
                                                                                        <span className="ms-1">{moment(item.start_date).format("D MMMM")} {item.end_date && '-'}  {item.end_date && moment(item.end_date).format("D MMMM")}</span>
                                                                                    </div>
                                                                                </li>

                                                                                <li>
                                                                                    <div className="d-flex align-items-center">
                                                                                        <i className="icofont-paper-clip" />
                                                                                        <span className="ms-1">{item.sub_tasks.length} Sub Task{item.sub_tasks.length > 1 && 's'}</span>
                                                                                    </div>
                                                                                </li>
                                                                            </ul>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>


                                                        )}
                                                    </Draggable>
                                                ))}

                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>

                                    <Droppable droppableId="completed">
                                        {(provided, snapshot) => (


                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                className="column col-4"
                                            >
                                                <h4 className='mt-5 mb-5 text-center'>Completed</h4>
                                                {data.completed.map((item, index) => (
                                                    <Draggable key={item.id} draggableId={`${item.id}`} index={index}>
                                                        {(provided, snapshot) => (
                                                            <div
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                ref={provided.innerRef}
                                                                style={provided.draggableProps.style}
                                                                className="dd-item task"
                                                            >
                                                                <div className="dd-handle">
                                                                    <div className="task-info d-flex align-items-center justify-content-between">
                                                                        <h6 className="light-success-bg py-1 px-2 rounded-1 d-inline-block fw-bold small-14 mb-0 text-title"> {item.title}
                                                                        </h6>
                                                                        <div className="task-priority d-flex flex-column align-items-center justify-content-center">
                                                                            <div className="avatar-list avatar-list-stacked m-0">
                                                                                {
                                                                                    item?.assign_to && <img className="avatar rounded-circle small-avt" src={`${API_URL_PREFIX}/media/${item.assign_to.image}`} alt="" />
                                                                                }

                                                                                {
                                                                                    item?.sub_tasks && item.sub_tasks.map(subTask => {
                                                                                        return subTask?.assign_to && <img className="avatar rounded-circle small-avt" src={`${API_URL_PREFIX}/media/${subTask.assign_to.image}`} alt="" />
                                                                                    })
                                                                                }
                                                                            </div>
                                                                            <span className="badge light-primary-bg text-primary text-end mt-2 text-title text-capitalize">{item.priority}</span>
                                                                        </div>
                                                                    </div>
                                                                    <p className="py-2 mb-0 text-title">{item.description}</p>
                                                                    <div className="tikit-info row g-3 align-items-center">
                                                                        <div className="col-sm">
                                                                            <ul className="d-flex list-unstyled align-items-center flex-wrap">
                                                                                <li className="me-2">
                                                                                    <div className="d-flex align-items-center">
                                                                                        <i className="icofont-flag" />
                                                                                        <span className="ms-1">{moment(item.start_date).format("D MMMM")} {item.end_date && '-'} {item.end_date && moment(item.end_date).format("D MMMM")}</span>
                                                                                    </div>
                                                                                </li>

                                                                                <li>
                                                                                    <div className="d-flex align-items-center">
                                                                                        <i className="icofont-paper-clip" />
                                                                                        <span className="ms-1">{item.sub_tasks.length} Sub Task{item.sub_tasks.length > 1 && 's'}</span>
                                                                                    </div>
                                                                                </li>
                                                                            </ul>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>


                                                        )}
                                                    </Draggable>
                                                ))}

                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>

                                </div>
                            </DragDropContext>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    </>
}

export default Board