import React from "react";
import { BrowserRouter, Route, Switch, Redirect, Router } from "react-router-dom";
import AdminLayout from "../layouts/AdminLayout";
import { getToken } from "../utils/getToken";
import Login from "../views/employee/Login/Login";
import Signup from "../views/employee/Signup/Signup";
import EmailVerification from "../views/employee/EmailVerification/EmailVerification";
import EmployeeLayout from "../layouts/EmployeeLayout";
import TwoFactorAuthentication from "../views/employee/TwoFactorAuthentication/TwoFactorAuthentication";
import { useSelector } from "react-redux"
import LoginWithOfficehassle from "../views/admin/LoginWithOfficehassle/LoginWithOfficehassle";

const AuthConsumer = ({ children }) => {
    const  userData  = useSelector(state => state.user)?.user
    const user = userData
    return children({ user: user, isAuthenticated: getToken() });
}

const canAccessRoute = (user, role) => {
    if (user.role === role) {
        return true
    }
    return false
}


const ProtctedRoute = ({ children, role, ...rest }) => {
    
    return (<AuthConsumer>
        {({ user, isAuthenticated }) => {
            if (isAuthenticated) {

                if (canAccessRoute(user, role)) {
                    if (JSON.parse(localStorage.getItem('two_factor'))) {
                        return (<Redirect to="/twoFactorAuthentication" />)
                    } else {

                        return (<Route {...rest} >{children}</Route>)
                    }

                }

                else {
                    if (user.role === "admin") {
                        if (JSON.parse(localStorage.getItem('two_factor'))) {
                            return (<Redirect to="/twoFactorAuthentication" />)
                        }
                        // else if (window.location.pathname === "" || window.location.pathname === "/") {
                        //     return <Redirect to="/admin/" noThrow />
                        // }
                        else return <Redirect to={"/admin/"} noThrow />
                    }

                    else {
                        if (JSON.parse(localStorage.getItem('two_factor'))) {
                            return (<Redirect to="/twoFactorAuthentication" />)
                        }
                        else return <Redirect to={"/employee/"} noThrow />
                    }
                }

            } else {
                return (<Redirect to="/login" noThrow/>);
            }
        }}
    </AuthConsumer>
) }

const LoginRoute = ({ children, ...rest }) => (
    <AuthConsumer>
        {({ user, isAuthenticated }) => {
            if (isAuthenticated) {
                if (user.role === "admin")
                    return <Redirect to="/admin/" noThrow />
                else return <Redirect to="/employee/" noThrow />
            }
            else {
                return <Route {...rest} >{children}</Route>;
            }
        }}
    </AuthConsumer>
);

const HomeRoute = ({ children, ...rest }) => (
    <AuthConsumer>
        {({ user, isAuthenticated }) => {
            if (isAuthenticated) {
                if (user.role === "admin")
                    return <Redirect to="/admin/" noThrow />
                else return <Redirect to="/employee/" noThrow />
            }
            else {
                return <Redirect to="/login/" noThrow />
            }
        }}
    </AuthConsumer>
);

const AppRoutes = () => {
    return <>
        <BrowserRouter>
            <Switch>
                <LoginRoute path="/login" component={Login} exact />
                <Route path="/signup" component={Signup} exact />
                <Route path="/twoFactorAuthentication" component={TwoFactorAuthentication} exact/>
                <Route path="/emailVeriefication" component={EmailVerification} exact />
                <Route path="/loginWithOfficehassle" component={LoginWithOfficehassle} exact/>
                <HomeRoute path="/" component={HomeRoute} exact/>
                <ProtctedRoute path="/admin/*" role={"admin"} render={(props) => <AdminLayout {...props} />} />
                <ProtctedRoute path="/employee/*" role={"employee"} render={(props) => <EmployeeLayout {...props} />} />
            </Switch>
        </BrowserRouter>
    </>
}

export default AppRoutes;