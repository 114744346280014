import { useState } from "react"
import { RotatingLines } from 'react-loader-spinner'
import { assignProjectEmployeeRoleAPI } from "../../services/apiService"
import { ErrorAlert, SuccessAlert } from "../../utils/alerts"
import logout from "../../utils/logout"



const AssignRoleModal = ({ project, handleCloseAssignRoleModal, selectedEmployee, handleReload }) => {

    const [loading, setLoading] = useState(false)
    const [role, setRole] = useState(selectedEmployee.role ? selectedEmployee.role.role : "")

    const assignProjectEmployeeRole = () => {

        if (role.length <= 2) {
            ErrorAlert("Role must be greater than or equal to 3 characters.")
            return false
        }
        setLoading(true)
        let reqData = {
            project_id: project.id,
            employee_id: selectedEmployee.employee.id,
            role: role
        }
        assignProjectEmployeeRoleAPI(reqData).then(res => {

            SuccessAlert(res.data.message)
            handleReload()
            handleCloseAssignRoleModal()
            setLoading(false)

        }).catch(error => {
            setLoading(false)
            if (error.response) {
                ErrorAlert(error.response.data.message)
                if (error.response.status === 401) {
                    logout();
                }
            } else {
                ErrorAlert(error.message)
            }
        })
    }

    return <>

        <div className="modal fade show" style={{ display: "block", zIndex: "1000!important;" }} aria-modal="true">
            <div className="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <div>
                            <h5 className="modal-title  fw-bold" >{selectedEmployee?.role?.role ? "Change Role" : "Assign Role"}</h5>

                            <p>What is {selectedEmployee.employee.first_name} {selectedEmployee.employee.last_name} role on this project?</p>
                        </div>

                        <button type="button" className="btn-close" onClick={handleCloseAssignRoleModal} />
                    </div>
                    {
                        loading ? <>
                            <div className="modal-body">
                                <div className="mb-3" style={{ display: "flex", justifyContent: "center" }}>
                                    <RotatingLines
                                        strokeColor="#484C7F"
                                        strokeWidth="5"
                                        animationDuration="0.75"
                                        width="96"
                                        visible={true}
                                    />
                                </div>
                            </div>

                        </> : <>
                            <div className="modal-body">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="e.g. Project Owner, Contributor"
                                    name="role"
                                    onChange={(e) => setRole(e.target.value)}
                                    value={role}
                                />
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={handleCloseAssignRoleModal}>Cancel</button>
                                <button type="button" className="btn btn-primary" onClick={assignProjectEmployeeRole}>{selectedEmployee?.role?.role ? "Change" : "Assign"}</button>
                            </div>
                        </>

                    }

                </div>
            </div>
        </div>
    </>
}
export default AssignRoleModal