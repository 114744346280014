import React from "react";
import moment from "moment";

const LatestTasks = ({tasks}) => {
  return (
    <div className="col-md-12">
      <div className="card mb-3">
        <div className="card-header py-3 d-flex justify-content-between align-items-center">
          <div className="info-header">
            <h6 className="mb-0 fw-bold ">Latest Tasks</h6>
          </div>
        </div>
        <div className="card-body">
          <div
            id="myProjectTable_wrapper"
            className="dataTables_wrapper dt-bootstrap5 no-footer"
          >
      
            <div className="row">
              <div className="col-sm-12">
                <table
                  id="myProjectTable"
                  className="table table-hover align-middle mb-0 nowrap dataTable no-footer dtr-inline"
                  style={{ width: "100%" }}
                  role="grid"
                  aria-describedby="myProjectTable_info"
                >
                  <thead>
                    <tr role="row">
                      <th
                        className="sorting_asc"
                        tabIndex={0}
                        aria-controls="myProjectTable"
                        rowSpan={1}
                        colSpan={1}
                        style={{ width: "274.2px" }}
                        aria-sort="ascending"
                        aria-label="Title: activate to sort column descending"
                      >
                        Title
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="myProjectTable"
                        rowSpan={1}
                        colSpan={1}
                        style={{ width: "206.2px" }}
                        aria-label="Date Start: activate to sort column ascending"
                      >
                        Description
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="myProjectTable"
                        rowSpan={1}
                        colSpan={1}
                        style={{ width: "183.2px" }}
                        aria-label="Deadline: activate to sort column ascending"
                      >
                        Priority
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="myProjectTable"
                        rowSpan={1}
                        colSpan={1}
                        style={{ width: "183.2px" }}
                        aria-label="Deadline: activate to sort column ascending"
                      >
                        Created On 
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    
                    {
                      tasks ? tasks.map((task)=>{
                        return <tr role="row" className="even">
                        <td tabIndex={0} className="sorting_1">
                          <a href="#">{task?.title || '...'}</a>
                        </td>
                        <td>{task?.description || '...'}</td>
                        <td>{task.priority ? (
                            <span
                            className={`badge ${
                                task.priority === 'low'
                                ? 'bg-success'
                                : task.priority === 'medium'
                                ? 'bg-warning'
                                : task.priority === 'high'
                                ? 'bg-danger'
                                : ''
                            }`}
                            >
                            {task.priority}
                            </span>
                            ) : (
                                '...'
                            )}
                        </td>
                        <td>{task?.created_on && moment(task.created_on).format('MMMM Do YYYY')}</td>
                      </tr>
                      })  : "No tasks."
                    }

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LatestTasks;
